'use client'

import { FC } from 'react'
import { ArrowRightIcon, CheckIcon, CoinIcon, CoinOutlineIcon } from '@/assets/icons'
import cx from 'classnames'

interface Props {
  isDone?: boolean
  children: React.ReactNode
  tokens?: number | string
  onClick?: () => void
  isNotEnoughTokens?: boolean
}

export const BoostCardLayout: FC<Props> = ({
  isDone,
  tokens,
  children,
  onClick,
  isNotEnoughTokens,
}) => {
  return (
    <div
      className={cx(
        'w-full flex justify-between items-center px-4 py-3 rounded-medium bg-purple-900 text-white',
        {
          '!text-gray-300 !bg-purple-800': isDone,
        }
      )}
      onClick={() => {
        if (isDone) return

        onClick && onClick()
      }}
    >
      <div className="flex gap-2 items-center">{children}</div>
      <div
        className={cx('flex items-center gap-1 text-yellow-500', {
          '!text-gray-300': isDone,
        })}
      >
        <p className="text-lg font-bold">{tokens ? `${tokens}` : 0}</p>
        {isNotEnoughTokens && !isDone ? (
          <CoinOutlineIcon className="w-[30px] h-[30px] min-w-[30px]" />
        ) : (
          <CoinIcon className="w-[30px] h-[30px] min-w-[30px]" />
        )}
      </div>
    </div>
  )
}
