import { CouponList } from './components/CouponList'
import { LastRaces } from './components/LastRaces'
import { StartFreeRaceButton } from './components/StartFreeRaceButton'

export default function RaceStepSelectPage() {
  return (
    <div className="layer-gradient pb-40">
      <CouponList />
      <LastRaces />
      <StartFreeRaceButton />
    </div>
  )
}
