'use client'

import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  selectRacePreparationRaceType,
  setRacePreparationEntryPrice,
} from '@/redux/features/racePreparationSlice'
import { TonRaceEntryListComponent } from './TonRaceEntryListComponent'
import { TonEntryPrice } from '@/types/game'
import { TonRaceType } from '@/types/race'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'

interface Props {}

export const TonRaceEntryListContainer: FC<Props> = ({}) => {
  const dispatch = useAppDispatch()

  const { webApp } = useTelegram()

  const raceType = useAppSelector(selectRacePreparationRaceType) as TonRaceType

  const handleNextBtnClick = (entryPrice: TonEntryPrice) => {
    dispatch(setRacePreparationEntryPrice(entryPrice))
    dispatch(setRoute(RoutePaths.tonRaceStepChooseNitro))
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  return <TonRaceEntryListComponent raceType={raceType} onNextBtnClick={handleNextBtnClick} />
}
