'use client'

import { FC } from 'react'
import { Transition } from '@/components/Transitions'
import { formatRacePosition } from '@/utils/labels'
import { RaceType } from '@/types/race'

interface Props {
  position: number
  raceType: RaceType
}

export const TonPodiumTitle: FC<Props> = ({ position, raceType }) => {
  return (
    <div className="flex flex-col items-center justify-center p-2 pt-10">
      <div className="flex flex-col items-center justify-center">
        <Transition animationType="leftToRight">
          <p className="text-white text-lg font-bold mb-4">
            {position === 1 && raceType === RaceType.OneVsOne && 'Congratulations'}
            {position === 1 && raceType === RaceType.OneVsThree && 'You WON!'}
            {position === 2 && raceType === RaceType.OneVsOne && 'Almost there…'}
            {position === 2 && raceType === RaceType.OneVsThree && 'Congratulations'}
            {position === 3 && 'Almost there…'}
            {position === 4 && 'Ooops…'}
          </p>
        </Transition>
        <Transition animationType="rightToLeft">
          <p className="text-xs font-medium text-gray-300 text-center mb-2">You are</p>
        </Transition>
        <Transition animationType="scaleFromZero">
          <p className="text-[40px] font-extrabold text-white text-center">
            {formatRacePosition(position - 1)}
          </p>
        </Transition>
      </div>
    </div>
  )
}
