import { useEffect, useState } from 'react'

interface IUseViewportHeight {
  isViewportNarrow: boolean
}

export const useViewportHeight = (): IUseViewportHeight => {
  const [viewportHeight, setViewportHeight] = useState(0)
  const [isViewportNarrow, setIsViewportNarrow] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsViewportNarrow(viewportHeight < 680)
  }, [viewportHeight])

  return {
    isViewportNarrow,
  }
}
