'use client'

import { FC } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { useIsValidChainId } from '@/hooks/useIsValidChainId'
import { useTonWallet } from '@tonconnect/ui-react'
import { TonLastRacesItem } from './TonLastRacesItem'

interface Props {}

export const TonLastRaces: FC<Props> = () => {
  const user = useAppSelector(selectUser)
  const { isValidChainId } = useIsValidChainId()
  const wallet = useTonWallet()

  if (!isValidChainId || !wallet?.account) return null

  return (
    <div className="flex flex-col justify-center items-center px-4 pb-24">
      {!!user.userTonRaceData.lastRaces && (
        <>
          <p className="text-gray-300 font-normal text-sm w-full mb-2">My last races:</p>
          <div className="flex flex-col gap-2 w-full">
            {user.userTonRaceData.lastRaces.map((item) => (
              <TonLastRacesItem data={item} key={item.createdAt} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
