'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { RaceType, TonRaceType } from '@/types/race'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { TonEntryPrice } from '@/types/game'
import { ENTRY_PRICE_VARIANTS } from '@/constants/race'
import { TonRaceStepWaiting1vs1 } from './TonRaceStepWaiting1vs1'
import { AnimatedDots } from '@/components/ui/AnimatedDots'
import { TonRaceStepWaiting1vs3 } from './TonRaceStepWaiting1vs3'
import { useTimer } from 'react-timer-hook'
import { TopRaceStepWaitingCancelModal } from '@/app/ton-race-step-waiting/components/TonRaceStepWaiting/TopRaceStepWaitingCancelModal'

interface Props {
  raceType: RaceType
  entryPrice?: TonEntryPrice
  onCancelBtnClick: () => void
  isLoading: boolean
  userName?: string
  secondPlayer?: string
  thirdPlayer?: string
  fourthPlayer?: string
  startTime?: string | null
  timeDifference: number
}

export const TonRaceStepWaitingComponent: FC<Props> = ({
  raceType,
  entryPrice,
  onCancelBtnClick,
  isLoading,
  userName,
  secondPlayer,
  thirdPlayer,
  fourthPlayer,
  startTime,
  timeDifference,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const entryPriceVariant = ENTRY_PRICE_VARIANTS[raceType as TonRaceType]

  const opponents = [userName, secondPlayer, thirdPlayer, fourthPlayer].filter(Boolean)

  const {
    seconds,
    minutes,
    isRunning = true,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(),
  })

  useEffect(() => {
    if (timeDifference !== 0 && startTime) {
      restart(new Date(new Date(startTime).getTime() - timeDifference))
    }
  }, [startTime, timeDifference])

  const isExpiredTime = useMemo(() => {
    if (!startTime || !timeDifference) return false

    return new Date(Date.now() + timeDifference) > new Date(startTime)
  }, [startTime, timeDifference])

  return (
    <>
      <div className="flex flex-col items-center p-4 text-center">
        <div className="w-full rounded-2xl overflow-hidden p-2 mb-6 bg-blue-800 flex flex-col gap-2 justify-center items-center bg-[url('/backgrounds/race-header-bg.webp')] bg-no-repeat bg-center bg-contain">
          <p className="text-white text-lg font-medium">{raceType}</p>
          {entryPrice && raceType !== RaceType.PlayWithPlatform && (
            <p className="text-blue-500 text-xs font-semibold">
              <span className="text-gray-300 mr-3">Entry: {entryPrice} TON</span> {'  '} Win:{' '}
              {entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].win}
              {!!entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second &&
                ` / ${entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second}`}{' '}
              TON
            </p>
          )}
        </div>
        <p className="text-lg font-medium text-white text-center mb-4">
          Awaiting opponents
          <AnimatedDots /> {opponents.length}/{raceType === RaceType.OneVsOne ? 2 : 4}
        </p>
        {raceType === RaceType.OneVsOne ? (
          <TonRaceStepWaiting1vs1 userName={userName} secondPlayer={secondPlayer} />
        ) : (
          <TonRaceStepWaiting1vs3
            userName={userName}
            secondPlayer={secondPlayer}
            thirdPlayer={thirdPlayer}
            fourthPlayer={fourthPlayer}
          />
        )}
      </div>
      <div className="w-full px-4 fixed left-0 bottom-4">
        <CustomButton
          variant="secondaryBordered"
          isLoading={isLoading}
          isDisabledDark={isRunning && !isExpiredTime}
          onClick={() => {
            setIsOpenModal(true)
          }}
        >
          {isRunning && !isExpiredTime ? (
            <>
              <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
              <span>:</span>
              <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            </>
          ) : (
            'cancel entry'
          )}
        </CustomButton>
      </div>
      <TopRaceStepWaitingCancelModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false)
        }}
        isLoading={isLoading}
        onConfirm={() => {
          onCancelBtnClick()
        }}
      />
    </>
  )
}
