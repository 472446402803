'use client'

import { FC } from 'react'
import { CoinIcon, StarIcon, UserIcon } from '@/assets/icons'
import { UserRefData } from '@/types/user'
import { toFixedSafe } from '@/utils/formatValue'

interface Props {
  refData: UserRefData
}

export const FriendsListItem: FC<Props> = ({ refData }) => {
  const { nickname, level, tokens, isPremium, refData: innerRefData, rewards } = refData

  const totalRewards = innerRefData.reduce((acc, item) => acc + item.rewards, 0) + rewards

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        {isPremium ? (
          <StarIcon className="min-w-[30px] w-[30px] h-[30px] text-white" />
        ) : (
          <UserIcon className="min-w-[30px] w-[30px] h-[30px] text-gray-300" />
        )}
        <div className="flex flex-col">
          <p className="text-sm font-normal text-white">{nickname}</p>
          <div className="flex items-center gap-2">
            <p className="text-gray-300 text-xs font-normal">
              <span>{level}</span> <span>level</span>
            </p>
            <div className="flex items-center gap-0.5 text-yellow-500">
              <CoinIcon className="min-w-[12px] w-[12px] h-[12px]" />
              <p className="text-xs font-normal">{toFixedSafe(tokens, 2)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-1 text-yellow-500">
        <p className="text-lg font-bold">{totalRewards ? `+${toFixedSafe(totalRewards, 2)}` : 0}</p>
        <CoinIcon className="min-w-[30px] w-[30px] h-[30px]" />
      </div>
    </div>
  )
}
