'use client'

import { FC } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { LastRacesItem } from '@/app/race-step-select/components/LastRaces/LastRacesItem'
import { selectUser } from '@/redux/features/user'

interface Props {}

export const LastRaces: FC<Props> = () => {
  const user = useAppSelector(selectUser)

  return (
    <div className="flex flex-col justify-center items-center pt-7 px-4">
      {!!user.raceData.length && (
        <>
          <p className="text-gray-300 font-normal text-sm w-full mb-2">My last races:</p>
          <div className="flex flex-col gap-2 w-full">
            {user.raceData.map((item) => (
              <LastRacesItem raceData={item} key={item.id} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
