import { TonRaceRaceType } from './components/TonRaceRaceType'
import { TonRaceWrongNetwork } from './components/TonRaceWrongNetwork'
import { TonLastRaces } from './components/TonLastRaces'

export default function TonRaceStepSelectPage() {
  return (
    <div className="layer-gradient layer-gradient-blue">
      <TonRaceRaceType />
      <TonRaceWrongNetwork />
      <TonLastRaces />
    </div>
  )
}
