import { GameDirection } from '@/types/game'

export interface Player {
  name: string
  id: string
  pair: PairType
  acceleration: string
  maxSpeed: string
}

export interface PlayerInGame {
  name: string
  acceleration: string
  id: string
  currentPrice: string
  currentSpeed: string
  gameID: string
  lastPrice: string
  maxSpeed: string
  pair: PairType
  timeTaken: string
  totalDistance: string
  vote?: PlayerVote
}

export interface PlayerVote {
  openedAtPrice: string
  predictionLevel: GameDirection
  speedPercentageChange: string
  state: VoteState
  adjustmentEndAt: string
  closePrice: string
  closePriceLevel: GameDirection
}

export enum VoteState {
  Opened = 'opened',
  ApplyingConfirmed = 'applying-confirmed',
  ApplyingDisconfirmed = 'applying-disconfirmed',
}

export interface PlayerResult {
  name: string
  id: string
  pair: PairType
  gapTime: string
  timeTaken: string
  totalDistance: string
  votes?: null | Array<PlayerVotesResult>
}

export interface PlayerVotesResult {
  direction: GameDirection
  endAt: string
  speedPercentageChange: string
  startAt: string
}

export enum PairType {
  BTC_USDT = 'btcusdt',
  ETH_USDT = 'ethusdt',
  MATIC_USDT = 'maticusdt',
  DOGE_USDT = 'dogeusdt',
  XRP_USDT = 'xrpusdt',
  SOL_USDT = 'solusdt',
  BNB_USDT = 'bnbusdt',
  TON_USDT = 'tonusdt',
  SHIB_USDT = 'shibusdt',
  PEPE_USDT = 'pepeusdt',
  POL_USDT = 'polusdt',
}
