'use client'

import { FC, memo, ReactNode } from 'react'
import cx from 'classnames'

interface Props {
  color: 'default' | 'green' | 'red' | 'blue' | 'turquoise' | 'yellow'
  className?: string
  children: ReactNode
}

const LabelLightTop: FC<Props> = ({ color = 'default', className, children }) => {
  return (
    <div className={`flex flex-col items-center justify-center box-border relative ${className}`}>
      <div
        className={cx(
          'w-full box-border min-w-[100px] h-[28px] flex items-end justify-center relative text-gray-race-200 text-base font-bold',
          {
            'text-green-race-500': color === 'green',
            'text-red-race-500': color === 'red',
            'text-turquoise-race-500': color === 'turquoise',
            'text-turquoise-race-600': color === 'blue',
            'text-yellow-race-100': color === 'yellow',
          }
        )}
      >
        <div className={'relative z-10'}>{children}</div>
        <div
          className={cx(
            'absolute -top-[15px] w-[30px] h-[30px] left-1/2 transform -translate-x-1/2 blur-[5px] opacity-50 rounded-full z-1',
            {
              'bg-midnight-race-500': color === 'default',
              'bg-green-race-500': color === 'green',
              'bg-red-race-500': color === 'red',
              'bg-turquoise-race-500': color === 'turquoise',
              'bg-turquoise-race-600': color === 'blue',
              'bg-yellow-race-100': color === 'yellow',
            }
          )}
        />
        <div
          className={cx(
            'absolute -top-[4px] w-[8px] h-[8px] left-1/2 transform -translate-x-1/2 rounded-full z-2',
            {
              'bg-midnight-race-500': color === 'default',
              'bg-green-race-500': color === 'green',
              'bg-red-race-500': color === 'red',
              'bg-turquoise-race-500': color === 'turquoise',
              'bg-turquoise-race-600': color === 'blue',
              'bg-yellow-race-100': color === 'yellow',
            }
          )}
        />
      </div>
    </div>
  )
}

export const LabelLightTopComponent = memo(LabelLightTop)
