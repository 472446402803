'use client'

import { FC } from 'react'
import { NitroLog } from './types'
import cx from 'classnames'
import { toFixedSafe } from '@/utils/formatValue'
import { GameDirection } from '@/types/game'
import { VoteState } from '@/types/player'

interface Props {
  data: NitroLog
  isViewportNarrow?: boolean
  isPvpMode?: boolean
}

export const NitroLogItemComponent: FC<Props> = ({ data, isViewportNarrow, isPvpMode }) => {
  const {
    name,
    pair,
    predictionLevel,
    speedPercentageChange,
    state,
    openedAtPrice,
    closePrice,
    isUser,
    username,
  } = data

  return (
    <p
      className={cx(`text-xs text-gray-race-200 flex items-center gap-1 bg-midnight-race-700`, {
        '!text-[10px]': isViewportNarrow,
      })}
    >
      <span
        className={cx('text-gray-race-600', {
          '!text-yellow-race-200': isUser && !isPvpMode,
          '!text-blue-600': isUser && isPvpMode,
        })}
      >
        {isUser ? username : name}
      </span>
      <span className="text-gray-race-800">|</span>
      <span
        className={cx('text-green-race-500', {
          'text-red-race-500': predictionLevel === GameDirection.DOWN,
        })}
      >
        <span
          className={cx('uppercase', {
            'text-gray-race-200': state === VoteState.Opened,
          })}
        >
          {pair.replace('usdt', '')}
        </span>{' '}
        {state === VoteState.Opened ? (
          <>{predictionLevel === GameDirection.UP ? 'LONG' : 'SHORT'}</>
        ) : (
          <>{closePrice}</>
        )}
      </span>
      <span className="text-gray-race-800">|</span>
      <span
        className={cx('text-gray-race-300', {
          'text-red-race-500': state === VoteState.ApplyingDisconfirmed,
          'text-green-race-500': state === VoteState.ApplyingConfirmed,
        })}
      >
        {state === VoteState.Opened ? (
          <>{openedAtPrice}</>
        ) : (
          <>{toFixedSafe(Math.abs(Number(speedPercentageChange)), 2)}</>
        )}
      </span>
    </p>
  )
}
