import { useMemo } from 'react'
import { CHAIN, useTonWallet } from '@tonconnect/ui-react'

export const useIsValidChainId = () => {
  const wallet = useTonWallet()

  const validChainId = (process.env.NEXT_PUBLIC_VALID_CHAIN_ID as CHAIN) || CHAIN.MAINNET

  const isValidChainId = useMemo(() => {
    return !!wallet?.account && wallet?.account?.chain === validChainId
  }, [wallet, validChainId])

  return { isValidChainId }
}
