'use client'

import { FC } from 'react'
import cx from 'classnames'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import {
  selectRacePreparationEntryPrice,
  selectRacePreparationPair,
} from '@/redux/features/racePreparationSlice'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { useMutation_CreateNewGame_Game } from '@/services/useApi/game/useMutation'

interface Props {
  className?: string
}

export const StartRaceButton: FC<Props> = ({ className }) => {
  const { coupons } = useAppSelector(selectUser)
  const entryPrice = useAppSelector(selectRacePreparationEntryPrice)
  const pair = useAppSelector(selectRacePreparationPair)

  const isInsufficientFunds = !!entryPrice && Number(entryPrice) > coupons

  const { mutateAsync, isPending } = useMutation_CreateNewGame_Game()

  const handleNextStep = async () => {
    await mutateAsync()
  }

  return (
    <div className={cx('w-full px-4', className)}>
      <CustomButton
        withCheckeredFlag
        isDisabledDark={isInsufficientFunds || !pair}
        onClick={handleNextStep}
        isLoading={isPending}
      >
        Start race
      </CustomButton>
    </div>
  )
}
