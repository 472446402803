'use client'

import { FC, useMemo } from 'react'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { useAppSelector } from '@/redux/hooks'
import { selectWsCalculationUpdates, selectWsIsGameEnded } from '@/redux/features/wsDataSlice'
import { PlayerInGame } from '@/types/player'
import { RaceLeaderboardMobileComponent } from './RaceLeaderboardMobile.component'
import { selectUser } from '@/redux/features/user'
import { RACE_DISTANCE } from '@/constants/race'

export const RaceLeaderboardContainer: FC = () => {
  const { tgId: userTgId } = useAppSelector(selectUser)

  const wsCalculationUpdates = useAppSelector(selectWsCalculationUpdates)
  const wsIsGameEnded = useAppSelector(selectWsIsGameEnded)

  const { data: game } = useQuery_GetGameById_Game()

  const players = useMemo(() => {
    return wsCalculationUpdates?.length ? wsCalculationUpdates : game?.players || []
  }, [game, wsCalculationUpdates])

  const sortedPlayers = useMemo(() => {
    if (!players?.length) {
      return []
    }

    if ('totalDistance' in players[0]) {
      const playersInGame = players as PlayerInGame[]
      const user = playersInGame.find(
        (player) => player.id.toLowerCase() === userTgId?.toLowerCase()
      )

      if (Number(user?.totalDistance) >= RACE_DISTANCE) {
        return playersInGame.slice().sort((a, b) => Number(a.timeTaken) - Number(b.timeTaken))
      }

      return playersInGame.slice().sort((a, b) => Number(b.totalDistance) - Number(a.totalDistance))
    }

    return players
  }, [players])

  const distanceGap = useMemo(() => {
    if (!wsCalculationUpdates?.length) {
      return 0
    }

    const user = sortedPlayers.find(
      (player) => player.id.toLowerCase() === userTgId?.toLowerCase()
    ) as PlayerInGame

    const opponent = sortedPlayers.find(
      (player) => player.id.toLowerCase() !== userTgId?.toLowerCase()
    ) as PlayerInGame

    if (!user || !opponent) {
      return 0
    }

    const userDistance = parseFloat(user?.totalDistance || '0')
    const opponentDistance = parseFloat(opponent?.totalDistance || '0')

    return userDistance - opponentDistance
  }, [wsCalculationUpdates, userTgId, sortedPlayers])

  return (
    <RaceLeaderboardMobileComponent
      userTgId={userTgId}
      players={sortedPlayers}
      distanceGap={distanceGap}
      wsIsGameEnded={wsIsGameEnded}
    />
  )
}
