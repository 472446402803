'use client'

import { FC, useEffect } from 'react'
import { nitroOptions } from '@/constants/raceSteps'
import cx from 'classnames'
import { PairType } from '@/types/player'
import { ComingSoonIcon } from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  selectRacePreparationEntryPrice,
  selectRacePreparationPair,
  selectRacePreparationRaceType,
  setRacePreparationPair,
} from '@/redux/features/racePreparationSlice'
import { setIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { Transition } from '@/components/Transitions'
import { ENTRY_PRICE_VARIANTS } from '@/constants/race'
import { TonRaceType } from '@/types/race'

interface Props {}

export const TonRaceChooseNitro: FC<Props> = ({}) => {
  const dispatch = useAppDispatch()

  const pair = useAppSelector(selectRacePreparationPair)
  const raceType = useAppSelector(selectRacePreparationRaceType)
  const entryPrice = useAppSelector(selectRacePreparationEntryPrice)

  const handleClickedNitro = (pair: PairType) => {
    dispatch(setRacePreparationPair(pair))
  }

  useEffect(() => {
    dispatch(setIsAllRaceActivitiesCompleted(false))
  }, [])

  const entryPriceVariant = ENTRY_PRICE_VARIANTS[raceType as TonRaceType]

  return (
    <div className="flex flex-col items-center p-4 text-center">
      <div className="w-full rounded-2xl overflow-hidden p-2 mb-6 bg-blue-800 flex flex-col gap-2 justify-center items-center bg-[url('/backgrounds/race-header-bg.webp')] bg-no-repeat bg-center bg-contain">
        <p className="text-white text-lg font-medium">{raceType}</p>
        <p className="text-blue-500 text-xs font-semibold">
          Entry: {entryPrice} TON {'  '} Win:{' '}
          {entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].win}
          {!!entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second &&
            ` / ${entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second}`}{' '}
          TON
        </p>
      </div>
      <Transition animationType="leftToRight">
        <p className="text-white text-lg font-medium mb-4">Choose your Nitro:</p>
      </Transition>
      <div className="grid grid-cols-4 gap-2 w-full box-border">
        {nitroOptions.map((nitro, index) => (
          <div
            key={index}
            className={cx(
              'flex flex-col items-center justify-center bg-blue-800 rounded-medium px-2 py-4 text-white cursor-pointer box-border border-1 border-blue-800',
              {
                '!border-blue-600 shadow-blue': nitro.pair === pair,
              }
            )}
            onClick={() => handleClickedNitro(nitro.pair)}
          >
            <Transition animationType="scaleFromZero">
              <nitro.fromIcon className="w-11 min-w-11 h-10" />
            </Transition>
            <p className="text-xs font-normal mt-2">{nitro.from}</p>
          </div>
        ))}
        <div
          className={cx(
            'flex flex-col items-center justify-center bg-blue-800 rounded-medium p-2 box-border text-default'
          )}
        >
          <ComingSoonIcon className="w-11 min-w-11 h-10" />
          <p className="font-normal mt-2 uppercase text-[6px]">coming soon</p>
        </div>
      </div>
    </div>
  )
}
