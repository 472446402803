'use client'

import { DirectionPanelComponent } from './DirectionPanel.component'
import { FC, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { GameDirection, GameVote } from '@/types/game'
import { submitGameVote } from '@/services/api/game'
import { useAppSelector } from '@/redux/hooks'
import {
  selectWsCalculationUpdates,
  selectWsIsGameEnded,
  selectWsLastCandlePrice,
} from '@/redux/features/wsDataSlice'
import { VoteState } from '@/types/player'
import { calculateNitroCoefficient, calculateSpeedPercentageChange } from '@/utils/race'
import { RACE_DISTANCE } from '@/constants/race'
import { selectRoundTripTime, selectTimeDifference } from '@/redux/features/time'
import { selectUser } from '@/redux/features/user'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'

export const DirectionPanelContainer: FC = () => {
  const [isLoadingGeneral, setIsLoadingGeneral] = useState(false)
  const [speed, setSpeed] = useState(0)
  const [prevDistance, setPrevDistance] = useState(0)
  const [temporaryNitroCoefficient, setTemporaryNitroCoefficient] = useState(0)
  const [isWaitingWsMsg, setIsWaitingWsMsg] = useState(false)

  const timeDifference = useAppSelector(selectTimeDifference)
  const roundTripTime = useAppSelector(selectRoundTripTime)
  const { tgId: userTgId } = useAppSelector(selectUser)

  const { data: game, isLoading: isLoadingActiveGame = true } = useQuery_GetGameById_Game()

  const gameUuid = useMemo(() => {
    return game?.uuid || ''
  }, [game])

  const wsLastCandlePrice = useAppSelector(selectWsLastCandlePrice)
  const wsCalculationUpdates = useAppSelector(selectWsCalculationUpdates)
  const wsIsGameEnded = useAppSelector(selectWsIsGameEnded)

  const userInGameWs = useMemo(() => {
    return wsCalculationUpdates?.find((game) => game?.id?.toLowerCase() === userTgId?.toLowerCase())
  }, [wsCalculationUpdates, userTgId])

  useEffect(() => {
    if (!userInGameWs || !userInGameWs.totalDistance) return

    const currentDistance = parseFloat(userInGameWs.totalDistance)

    const speedInMetersPerSecond = currentDistance - prevDistance
    const speedInKilometersPerHour = speedInMetersPerSecond * 3.6

    setSpeed(speedInKilometersPerHour)
    setPrevDistance(currentDistance)

    if (currentDistance >= RACE_DISTANCE) {
      setSpeed(0)
    }
  }, [userInGameWs])

  const openedAtPrice = useMemo(() => {
    if (!userInGameWs) return undefined

    if (userInGameWs?.vote?.state === VoteState.Opened) {
      return parseFloat(userInGameWs?.vote?.openedAtPrice)
    }

    return undefined
  }, [userInGameWs])

  const speedPercentageChange = useMemo(() => {
    const close = wsLastCandlePrice || 0

    return calculateSpeedPercentageChange(close, openedAtPrice, userInGameWs?.vote?.predictionLevel)
  }, [openedAtPrice, wsLastCandlePrice, userInGameWs?.vote?.predictionLevel])

  const userBetPercent = useMemo(() => {
    if (temporaryNitroCoefficient && isLoadingGeneral) return temporaryNitroCoefficient

    if (!userInGameWs || !speedPercentageChange) return 0

    if (userInGameWs?.vote?.state === VoteState.Opened) {
      return calculateNitroCoefficient(speedPercentageChange)
    }

    return 0
  }, [
    userInGameWs,
    speedPercentageChange,
    temporaryNitroCoefficient,
    isLoadingGeneral,
    userInGameWs?.vote?.state,
  ])

  const vote = useMemo(() => {
    if (!userInGameWs) return GameDirection.CLOSE

    if (userInGameWs?.vote?.state === VoteState.Opened) {
      return userInGameWs?.vote?.predictionLevel
    }

    return GameDirection.CLOSE
  }, [userInGameWs])

  const adjustmentEndAt = useMemo(() => {
    if (!userInGameWs) return null

    if (
      userInGameWs?.vote?.state === VoteState.ApplyingDisconfirmed ||
      userInGameWs?.vote?.state === VoteState.ApplyingConfirmed
    ) {
      return userInGameWs?.vote?.adjustmentEndAt
    }

    return null
  }, [userInGameWs])

  const isDisabled =
    !!userInGameWs?.totalDistance && parseFloat(userInGameWs?.totalDistance) >= RACE_DISTANCE

  const isStartRace = !!userInGameWs?.totalDistance && parseFloat(userInGameWs?.totalDistance) > 0

  const { mutateAsync: mutateAsyncSendGameVote, isPending: isPendingSendGameVote } = useMutation({
    mutationFn: async (data: GameVote) => {
      let betPercent = userBetPercent

      if (data.direction === GameDirection.CLOSE) {
        const speedPercentageChange = calculateSpeedPercentageChange(
          Number(data.price),
          openedAtPrice,
          userInGameWs?.vote?.predictionLevel
        )

        betPercent = calculateNitroCoefficient(speedPercentageChange)
      }

      setTemporaryNitroCoefficient(betPercent)
      setIsLoadingGeneral(true)

      const result = await submitGameVote(data)

      setIsWaitingWsMsg(true)

      return result
    },
    onError: (e: any) => {
      setIsLoadingGeneral(false)
      setTemporaryNitroCoefficient(0)
    },
  })

  const requestVote = async (direction: GameDirection) => {
    await mutateAsyncSendGameVote({
      gameID: gameUuid,
      direction,
      price: wsLastCandlePrice.toString(),
      playerID: userTgId,
    })
  }

  useEffect(() => {
    setTemporaryNitroCoefficient(0)
    setIsLoadingGeneral(false)
  }, [userInGameWs?.vote])

  useEffect(() => {
    if (isWaitingWsMsg && !userInGameWs?.vote) {
      setIsWaitingWsMsg(false)
    }
  }, [isWaitingWsMsg, userInGameWs?.vote])

  useEffect(() => {
    if (isWaitingWsMsg) {
      setTimeout(() => {
        setIsWaitingWsMsg(false)
      }, 1000)
    }
  }, [isWaitingWsMsg, userInGameWs?.vote])

  return (
    <DirectionPanelComponent
      onUpButtonClick={async () => {
        await requestVote(GameDirection.UP)
      }}
      onDownButtonClick={async () => {
        await requestVote(GameDirection.DOWN)
      }}
      onCloseButtonClick={async () => {
        await requestVote(GameDirection.CLOSE)
      }}
      isDisabled={isDisabled || !userInGameWs || wsIsGameEnded || isLoadingActiveGame}
      isLoading={isLoadingGeneral || isWaitingWsMsg || isPendingSendGameVote}
      vote={vote}
      userBetPercent={userBetPercent}
      isStartRace={isStartRace}
      adjustmentEndAt={adjustmentEndAt}
      speed={speed}
      timeDifference={timeDifference}
      roundTripTime={roundTripTime}
    />
  )
}
