'use client'

import { FC } from 'react'
import { CouponBoxIcon } from '@/assets/icons'
import { Transition } from '@/components/Transitions'
import { BoostCardLayout } from './BoostCardLayout'
import { UserBoostType } from '@/types/user'
import { BoosterUpgradeLayoutModal } from '@/app/boosters/components/Boosters/BoosterUpgradeLayoutModal'
import { useBoostersCards } from '@/app/boosters/components/Boosters/hooks'

interface Props {}

export const BoosterStorageLimitCard: FC<Props> = () => {
  const {
    user: { storageLimitLevel, tokens },
    userLevelData: { storageLimitPriceAndBonus },
    handleOpenModal,
    handleCloseModal,
    isOpenModal,
    mutateAsync,
    isPending,
  } = useBoostersCards()

  const isNotEnoughTokens = tokens < storageLimitPriceAndBonus.price

  return (
    <>
      <BoostCardLayout
        isDone={storageLimitPriceAndBonus?.isLastLevel}
        tokens={storageLimitPriceAndBonus.price}
        onClick={handleOpenModal}
        isNotEnoughTokens={isNotEnoughTokens}
      >
        <Transition animationType="scaleFromZero">
          <CouponBoxIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <div className="flex flex-col">
          <p className="text-sm font-normal text-white">Storage limit</p>
          <p className="text-xs font-light text-gray-300">{storageLimitLevel} level</p>
        </div>
      </BoostCardLayout>
      <BoosterUpgradeLayoutModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        level={storageLimitLevel + 1}
        title="Storage limit"
        price={storageLimitPriceAndBonus.price}
        onRequestUpgrade={() => {
          mutateAsync({ boostType: UserBoostType.STORAGE_LIMIT_LEVEL })
        }}
        isLoading={isPending}
        Icon={CouponBoxIcon}
        isNotEnoughTokens={isNotEnoughTokens}
        subtitle={`Upgrade coupons storage limit +${storageLimitPriceAndBonus.nextLevelBonus}`}
      />
    </>
  )
}
