'use client'

import { FC } from 'react'
import { AnimatedDots } from '@/components/ui/AnimatedDots'
import { TonOutlineIcon } from '@/assets/icons'

export const TonRaceConfirmingStatus: FC = () => {
  return (
    <div className="w-full layer-default layer-gradient-blue z-[201] absolute bottom-0 left-0 flex flex-col items-center justify-center gap-4 text-white bg-dark-bg-radial-gradient">
      <p className="text-lg font-bold">
        Confirming
        <AnimatedDots />
      </p>
      <TonOutlineIcon className="w-11 h-11" />
    </div>
  )
}
