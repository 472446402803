import { useTelegram } from '@/app/providers/TelegramProvider'
import { QUERY_PARAM_KEYS } from '@/constants/queryParamKey'

export const useInviteFriend = () => {
  const { user, webApp } = useTelegram()

  const handleInviteFriendClick = () => {
    webApp?.HapticFeedback.impactOccurred('light')

    const url = `https://t.me/share/url?url=${encodeURIComponent(`${process.env.NEXT_PUBLIC_TELEGRAM_BOT_URL}/app?${QUERY_PARAM_KEYS.startapp}=refId_${user?.id}`)}&text=${encodeURIComponent(`Racing Game based on real-time crypto charts in Telegram mini app. Tap to get race coupons to enter races and win $NICK tokens. Airdrop incoming!
    
🎟️ 200 Coupons for 2 races welcome bonus
💸 100 $NICK tokens after the first race and extra 100 for Premium`)}`
    window.open(url, '_blank')
  }

  return {
    handleInviteFriendClick,
  }
}
