'use client'

import { FC } from 'react'
import { RaceType } from '@/types/race'
import {
  RacerLBlueIllustration,
  RacerRVioletIllustration,
  RacersRIllustration,
} from '@/assets/illustrations'
import { HandSelectIcon, InfoIcon } from '@/assets/icons'
import cx from 'classnames'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { Transition } from '@/components/Transitions'

interface Props {
  usersOnline: string
  isConnected: boolean
  onConnectBtnClick: () => void
  onGoBtnClick: (raceType: RaceType) => void
}

export const TonRaceRaceTypeComponent: FC<Props> = ({
  usersOnline = '0',
  isConnected,
  onConnectBtnClick,
  onGoBtnClick,
}) => {
  return (
    <>
      <div className="flex flex-col items-center p-4 text-center">
        <p className="text-lg font-medium text-white text-center mb-2">PvP: TON Nitro Racing</p>
        <p className="text-sm font-normal text-gray-300 text-center mb-8">
          Race on live crypto charts with other players to win $TON.{' '}
        </p>
        <div className="flex items-center justify-center text-sm font-normal text-blue-600 gap-1 mb-4">
          <div className="w-[6px] h-[6px] bg-blue-600 rounded-full"></div>
          <p>Online:</p>
          <p className="font-semibold text-gray-300">{usersOnline}</p>
        </div>
        <div
          className="flex relative w-full h-[146px] bg-blue-800 overflow-hidden rounded-2xl mb-4 bg-[url('/backgrounds/race-type-bg-1.webp')] bg-no-repeat bg-center bg-contain justify-center items-center"
          onClick={() => onGoBtnClick(RaceType.OneVsOne)}
        >
          <p className="text-[40px] font-bold text-white">{RaceType.OneVsOne}</p>
          {isConnected && (
            <p className="absolute bottom-2 text-gray-300 text-sm font-normal">Press to start</p>
          )}
          <RacerLBlueIllustration className="absolute bottom-0 left-4 w-[100px] h-[135px]" />
          <RacerRVioletIllustration className="absolute bottom-0 right-4 w-[100px] h-[135px]" />
        </div>
        <div
          className="flex relative w-full h-[146px] bg-blue-800 overflow-hidden rounded-2xl mb-4 bg-[url('/backgrounds/race-type-bg-2.webp')] bg-no-repeat bg-center bg-contain justify-center items-center"
          onClick={() => onGoBtnClick(RaceType.OneVsThree)}
        >
          <p className="text-[40px] font-bold text-white">{RaceType.OneVsThree}</p>
          {isConnected && (
            <p className="absolute bottom-2 text-gray-300 text-sm font-normal">Press to start</p>
          )}
          <RacerLBlueIllustration className="absolute bottom-0 left-4 w-[100px] h-[135px]" />
          <RacersRIllustration className="absolute bottom-0 right-0 w-[120px] h-[146px]" />
        </div>
        {isConnected ? (
          <div className="flex items-center justify-center gap-1 text-white">
            <HandSelectIcon className="w-7 h-7" />
            <p className="font-normal text-sm">Choose the race type</p>
          </div>
        ) : (
          <div className="flex items-center justify-center gap-1 text-white">
            <InfoIcon className="w-7 h-7" />
            <p className="font-normal text-sm">Connect TON wallet to start</p>
          </div>
        )}
      </div>
      {!isConnected && (
        <div className="w-full px-4 fixed left-0 bottom-[92px]">
          <CustomButton variant="secondary" onClick={onConnectBtnClick}>
            connect wallet
          </CustomButton>
        </div>
      )}
    </>
  )
}
