import { RACE_DISTANCE } from '@/constants/race'

export const RACE_TRACK_CONFIG = {
  MOBILE: {
    MAX_CAR_POSITION: 240,
    START_GAME_LAYOUT_POSITION: 40,
    FINISH_GAME_LAYOUT_POSITION: 240 - RACE_DISTANCE,
    CAR_HEIGHT: 40,
    START_ARROW_POSITION: -30,
  },
  MOBILE_SMALL: {
    MAX_CAR_POSITION: 140,
    START_GAME_LAYOUT_POSITION: 20,
    FINISH_GAME_LAYOUT_POSITION: 140 - RACE_DISTANCE,
    CAR_HEIGHT: 20,
    START_ARROW_POSITION: -20,
  },
}
