'use client'

import { FC, useEffect } from 'react'
import { nitroOptions } from '@/constants/raceSteps'
import cx from 'classnames'
import { PairType } from '@/types/player'
import { ComingSoonIcon } from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  selectRacePreparationPair,
  setRacePreparationPair,
} from '@/redux/features/racePreparationSlice'
import { setIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { Transition } from '@/components/Transitions'

interface Props {}

export const ChooseNitro: FC<Props> = ({}) => {
  const dispatch = useAppDispatch()

  const pair = useAppSelector(selectRacePreparationPair)

  const handleClickedNitro = (pair: PairType) => {
    dispatch(setRacePreparationPair(pair))
  }

  useEffect(() => {
    dispatch(setIsAllRaceActivitiesCompleted(false))
  }, [])

  return (
    <div className="flex flex-col items-center p-4 pt-10 text-center">
      <Transition animationType="leftToRight">
        <p className="text-white text-lg font-medium mb-4">Choose your Nitro:</p>
      </Transition>
      <div className="grid grid-cols-4 gap-2 w-full box-border">
        {nitroOptions.map((nitro, index) => (
          <div
            key={index}
            className={cx(
              'flex flex-col items-center justify-center bg-purple-900 rounded-medium px-2 py-4 text-white cursor-pointer box-border border-1 border-purple-900',
              {
                '!border-yellow-500 shadow-yellowLight': nitro.pair === pair,
              }
            )}
            onClick={() => handleClickedNitro(nitro.pair)}
          >
            <Transition animationType="scaleFromZero">
              <nitro.fromIcon className="w-11 min-w-11 h-10" />
            </Transition>
            <p className="text-xs font-normal mt-2">{nitro.from}</p>
          </div>
        ))}
        <div
          className={cx(
            'flex flex-col items-center justify-center bg-purple-900 rounded-medium p-2 box-border text-default'
          )}
        >
          <ComingSoonIcon className="w-11 min-w-11 h-10" />
          <p className="font-normal mt-2 uppercase text-[6px]">coming soon</p>
        </div>
      </div>
    </div>
  )
}
