import { RewardsSection } from './components/RewardsSection'
import { TasksSection } from './components/TasksSection'

export default function RewardsPage() {
  return (
    <div className="layer-gradient">
      <RewardsSection />
      <TasksSection />
    </div>
  )
}
