import { FC, useMemo } from 'react'
import cx from 'classnames'
import { CheckeredFlagSmallIcon } from '@/assets/icons'
import { PlayerInGame } from '@/types/player'
import { getPercentageNumberOfNumber } from '@/utils/mathUtils'
import { RACE_DISTANCE } from '@/constants/race'

interface Props {
  className?: string
  wsCalculationUpdates?: Array<PlayerInGame>
  userTgId?: string
  isPvpMode?: boolean
}

export const RaceIndicatorComponent: FC<Props> = ({
  className,
  wsCalculationUpdates = [],
  userTgId,
  isPvpMode,
}) => {
  const userDistance = useMemo(() => {
    if (!userTgId || !wsCalculationUpdates?.length) return 0

    const user = wsCalculationUpdates.find(
      (player) => player.id?.toLowerCase() === userTgId?.toLowerCase()
    )

    const distance = parseFloat(user?.totalDistance || '0')

    return getPercentageNumberOfNumber(distance, RACE_DISTANCE)
  }, [wsCalculationUpdates, userTgId])

  // const opponentDistance = useMemo(() => {
  //   if (!userTgId || !wsCalculationUpdates?.length) return 0
  //
  //   const opponent = wsCalculationUpdates.find(
  //     (player) => player.id?.toLowerCase() !== userTgId?.toLowerCase()
  //   )
  //
  //   const distance = parseFloat(opponent?.totalDistance || '0')
  //
  //   return getPercentageNumberOfNumber(distance, RACE_DISTANCE)
  // }, [wsCalculationUpdates, userTgId])

  return (
    <div
      className={cx(
        'h-full w-3 rounded-medium bg-midnight-race-900 relative overflow-hidden pt-0.5 pb-1',
        className
      )}
    >
      <CheckeredFlagSmallIcon className="absolute w-4 h-4 top-0 left-0 z-0" />
      <div className="w-full h-full relative">
        <div
          className={cx(
            'w-[6px] min-h-[8px] absolute rounded-medium bottom-0 transform -translate-x-1/2 left-1/2 z-[2]',
            {
              'bg-yellow-race-200/[0.5]': !isPvpMode,
              'bg-blue-700': isPvpMode,
            }
          )}
          style={{
            height: `${userDistance}%`,
            transition: 'height 1000ms linear',
            willChange: 'height',
          }}
        >
          <div className="relative w-full h-full">
            <div
              className={cx(
                'w-2 h-2 rounded-full absolute top-0 transform -translate-x-1/2 left-1/2 shadow-blue',
                {
                  'bg-yellow-race-200': !isPvpMode,
                  'bg-blue-600': isPvpMode,
                }
              )}
            />
          </div>
        </div>
      </div>
      {/*<div*/}
      {/*  className="w-[6px] min-h-[8px] absolute rounded-medium bg-gray-race-100/[0.5] bottom-1 trasform -translate-x-1/2 left-1/2 z-[1]"*/}
      {/*  style={{*/}
      {/*    height: `calc(${opponentDistance}% + 8px - 12px)`,*/}
      {/*    transition: 'all 1000ms linear',*/}
      {/*    willChange: 'bottom',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className="relative w-full h-full">*/}
      {/*    <div className="w-2 h-2 rounded-full bg-gray-race-100 absolute top-0 trasform -translate-x-1/2 left-1/2" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
