'use client'

import { FC } from 'react'
import { TonEntryPrice } from '@/types/game'
import { ArrowRightIcon, CheckeredFlagBlueIcon, CouponBlueWideIcon } from '@/assets/icons'
import cx from 'classnames'

interface Props {
  entry: TonEntryPrice
  win: number | string
  second: number | string
  onCouponClick: (entry: TonEntryPrice) => void
  className?: string
  usd?: number | string
}

export const TonRaceEntryListItem: FC<Props> = ({
  entry = '0.6',
  onCouponClick,
  second,
  win,
  className,
  usd,
}) => {
  return (
    <div
      className={cx(
        'w-full h-[56px] max-w-[361px] relative flex items-center justify-end text-white',
        className
      )}
      onClick={() => onCouponClick(entry)}
    >
      <CouponBlueWideIcon className="w-full h-full absolute left-0 top-0" />
      <div className="flex items-center relative z-[2] text-sm font-semibold gap-2 mr-3">
        <p>win</p>
        <p className="text-3xl italic font-extrabold">{win}</p>
        {!!second && (
          <>
            <p>/</p>
            <p className="text-3xl italic font-extrabold">{second}</p>
          </>
        )}
        <p>TON</p>
        {!!usd && <p>(${usd})</p>}
      </div>
      <div className="flex items-center justify-center relative gap-2 z-[2] mr-6 max-w-[116px] w-full">
        <div className="flex flex-col justify-center items-center">
          <p className="text-xs font-semibold">Entry</p>
          <p className="text-xl font-extrabold italic">{entry} TON</p>
        </div>
        <ArrowRightIcon className="w-[7px] h-[12px] min-w-[7px] text-white absolute right-0" />
      </div>
      <CheckeredFlagBlueIcon className="w-[217px] h-[23px] left-0 bottom-[2px] absolute z-[1]" />
    </div>
  )
}
