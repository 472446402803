export enum Tokens {
  BTC = 'BTC',
  ETH = 'ETH',
  MATIC = 'MATIC',
  DOGE = 'DOGE',
  XRP = 'XRP',
  USDT = 'USDT',
  BNB = 'BNB',
  SOL = 'SOL',
  TON = 'TON',
  TRX = 'TRX',
  NOT_TOKEN = 'NOT',
  PEPE = 'PEPE',
  SHIB = 'SHIB',
}
