'use client'

import { FC, memo, ReactNode } from 'react'
import cx from 'classnames'

interface Props {
  color: 'default' | 'green' | 'red' | 'blue' | 'turquoise' | 'yellow'
  className?: string
  title?: string
  children: ReactNode
}

const LabelLight: FC<Props> = ({ color = 'default', className, title, children }) => {
  return (
    <div className={`flex flex-col items-center justify-center box-border ${className}`}>
      {title && <p className="text-xs mb-0.5 text-gray-race-700">{title}</p>}
      <div
        className={cx(
          'w-full overflow-hidden box-border min-w-[87px] rounded-medium h-[36px] flex items-center justify-center relative bg-midnight-race-900 border-1 border-b-0 text-xl font-extrabold',
          {
            'border-gray-race-500 text-gray-race-500': color === 'default',
            'border-green-race-900 text-green-race-500': color === 'green',
            'border-red-race-900 text-red-race-500': color === 'red',
            'border-turquoise-race-900 text-turquoise-race-500': color === 'turquoise',
            'border-blue-800 text-blue-600': color === 'blue',
            'border-turquoise-race-800 text-yellow-race-100': color === 'yellow',
          }
        )}
      >
        <div className={'relative z-10 text-base'}>{children}</div>
        <div
          className={cx(
            'absolute -bottom-[15px] w-[30px] h-[30px] left-1/2 transform -translate-x-1/2 blur-[5px] opacity-50 rounded-full z-1',
            {
              'bg-midnight-race-500': color === 'default',
              'bg-green-race-500': color === 'green',
              'bg-red-race-500': color === 'red',
              'bg-turquoise-race-500': color === 'turquoise',
              'bg-blue-600': color === 'blue',
              'bg-yellow-race-100': color === 'yellow',
            }
          )}
        />
        <div
          className={cx(
            'absolute -bottom-[4px] w-[8px] h-[8px] left-1/2 transform -translate-x-1/2 rounded-full z-2',
            {
              'bg-midnight-race-500': color === 'default',
              'bg-green-race-500': color === 'green',
              'bg-red-race-500': color === 'red',
              'bg-turquoise-race-500': color === 'turquoise',
              'bg-blue-600': color === 'blue',
              'bg-yellow-race-100': color === 'yellow',
            }
          )}
        />
      </div>
    </div>
  )
}

export const LabelLightComponent = memo(LabelLight)
