import { useEffect } from 'react'

function use100vh(): string {
  useEffect(() => {
    function updateHeight() {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    }

    window.addEventListener('resize', updateHeight)

    updateHeight()

    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  return `calc(var(--vh, 1vh) * 100)`
}

export default use100vh
