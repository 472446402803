import React, { FC } from 'react'
import styles from './AnimatedDots.module.css'
import cx from 'classnames'

interface Props {
  className?: string
}

export const AnimatedDots: FC<Props> = ({ className }) => {
  return (
    <span className={cx(className)}>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
      <span className={styles.dot}>.</span>
    </span>
  )
}
