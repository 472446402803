'use client'

export const GameRulesSection = () => {
  return (
    <div className="flex flex-col p-4 text-white overflow-y-scroll pb-20">
      <p className="text-sm mb-6">
        Welcome to Click Nitro Taxi, a thrilling web3 racing game powered by live cryptocurrency
        charts. Tap, Race and win $NICK tokens!
      </p>
      <p className="text-base mb-2 font-bold">Tap genre & Racing & Crypto trading combined</p>
      <p className="text-sm mb-6">
        Tap to get race coupons to enter races. Racing gameplay is based on trading, so you
        literally make virtual trades on live crypto charts in order to race.
      </p>
      <p className="text-base mb-2 font-bold">Choose your Nitro</p>
      <p className="text-sm mb-6">
        Select what crypto chart will be used for the Nitro before the race. You can choose any of
        the cryptocurrency for your Nitro. Nitro acceleration is tied to your virtual trades on the
        real-time chart during the race. The better you trade - the faster your car goes.
      </p>
      <p className="text-base mb-2 font-bold">Race and win $NICK</p>
      <p className="text-sm mb-6 flex flex-col gap-2">
        <span>
          Race by virtually trading on a live chart of the chosen cryptocurrency. Accelerate your
          car by making successful predictions of the price movements.
        </span>
        <span className="flex flex-col pl-4">
          <span>🟢 Long - you get positive Nitro if price goes up,</span>
          <span>🔴 Short - you get positive Nitro if price goes down</span>
          <span>Press Close to activate the current Nitro</span>
        </span>
      </p>
      <p className="text-sm mb-2">
        <span className="font-bold text-base">PvE mode 1 vs 3:</span> race against 3 AI cars and get
        $NICK if you reach the podium.
      </p>
      <p className="text-sm mb-6">
        <span className="font-bold text-base">PvP mode:</span> coming soon…
      </p>
      <p className="text-base mb-2 font-bold">Friends</p>
      <p className="text-sm mb-6 flex flex-col gap-2">
        <span>Invite your friends and get bonus race coupons and tokens:</span>
        <span className="flex flex-col pl-4">
          <span>• 100 race coupons for every invited friend</span>
          <span>• 100 $NICK tokens after the first race and extra 100 for Premium</span>
        </span>
      </p>
      <p className="text-sm mb-6 flex flex-col gap-2">
        <span>💰 Earn $NICK tokens every time your friends and their friends win races: </span>
        <span className="flex flex-col pl-4">
          <span>• 7% from your direct friends,</span>
          <span>• 3% from their friends.</span>
        </span>
      </p>
      <p className="text-sm mb-6 flex flex-col gap-2">
        <span>🎁 Welcome bonus for your friends:</span>
        <span className="flex flex-col pl-4">
          <span>+ 200 Race Coupons</span>
          <span>+ 100 $NICK tokens after the first race</span>
        </span>
      </p>
      <p className="text-sm mb-6">Let’s get rolling!</p>
      <p className="text-base mb-2 font-bold">Roadmap</p>
      <p className="text-sm">Nitro.taxi web3 application ✅</p>
      <p className="text-sm">Click Nitro Taxi Telegram mini app ✅</p>
      <p className="text-sm">Boosters ✅</p>
      <p className="text-sm">Tasks update</p>
      <p className="text-sm">PvP mode</p>
      <p className="text-sm">Championship mode</p>
      <p className="text-sm">Garage shop</p>
      <p className="text-sm mb-6">Token airdrop</p>

      <p className="text-sm mb-2">© Click Nitro Taxi</p>
      <p className="text-sm">
        Brought to you by the creators of{' '}
        <a href="https://www.nitro.taxi/" target="_blank" rel="noreferrer" className="underline">
          nitro.taxi
        </a>
      </p>
    </div>
  )
}
