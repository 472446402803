'use client'

import { FC, useState } from 'react'
import { CoinIcon, TelegramIcon } from '@/assets/icons'
import { CardLayout } from '@/app/rewards/components/CardLayout'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { ModalCustom } from '@/components/ui/ModalCustom'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { Transition } from '@/components/Transitions'
import { useQuery_FetchUserTasks_User } from '@/services/useApi/user/useQuery'
import { useMutation_ClaimUserDailyTask_User } from '@/services/useApi/user/useMutation'
import { UserTaskStatus, UserTaskType } from '@/types/user'

interface Props {}

export const InviteChannelCard: FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { webApp } = useTelegram()

  const { data: tasks } = useQuery_FetchUserTasks_User()

  const { mutateAsync: mutateAsyncInvite } = useMutation_ClaimUserDailyTask_User()
  const { mutateAsync, isPending } = useMutation_ClaimUserDailyTask_User(() => {
    setIsOpen(false)
  })

  const joinTgChannel = tasks?.joinTgChannel || ''

  const handleOpen = () => {
    setIsOpen(true)
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  const onJoinTgChannelBtnClick = async () => {
    window.open('https://t.me/nitrotaxi', '_blank')

    if (joinTgChannel === UserTaskStatus.ACTIVE) {
      await mutateAsyncInvite({
        task: UserTaskType.JOIN_TG_CHANNEL,
      })
    }
  }

  return (
    <>
      <CardLayout
        isDone={joinTgChannel === UserTaskStatus.DONE}
        onClick={handleOpen}
        tokens="800"
        withCheckmark
      >
        <Transition animationType="scaleFromZero">
          <TelegramIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <p className="text-sm font-normal">Join our Telegram Channel</p>
      </CardLayout>
      <ModalCustom
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        classNameContent="flex flex-col justify-between items-center gap-2"
      >
        <div className="text-white flex flex-col justify-between items-center">
          <TelegramIcon className="w-11 h-11 mb-2" />
          <p className="text-lg font-normal mb-1">Telegram Channel</p>
          <p className="text-xs font-normal text-gray-300 mb-4">Connect to our Telegram Channel</p>
          <CustomButton className="h-[40px] max-w-32 w-full" onTouchEnd={onJoinTgChannelBtnClick}>
            Join
          </CustomButton>
        </div>
        <div className="w-full flex flex-col justify-center items-center gap-4">
          <div className="flex items-center text-yellow-500">
            <p className="text-4xl text-[32px] font-bold">+800</p>
            <CoinIcon className="w-[40px] h-[40px]" />
          </div>
          <CustomButton
            isDisabledDark={joinTgChannel !== UserTaskStatus.READY_TO_CLAIM}
            onTouchEnd={async () => {
              await mutateAsync({
                task: UserTaskType.JOIN_TG_CHANNEL,
              })
            }}
            isLoading={isPending}
          >
            claim
          </CustomButton>
        </div>
      </ModalCustom>
    </>
  )
}
