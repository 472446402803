'use client'

import { FC } from 'react'
import {
  RacerLBlueSmIllustration,
  RacerLGraySmIllustration,
  RacerLYellowSmIllustration,
  RacerRGraySmIllustration,
  RacerRGreenSmIllustration,
  RacerRRedSmIllustration,
} from '@/assets/illustrations'
import cx from 'classnames'
import { AnimatedDots } from '@/components/ui/AnimatedDots'
import { TonBlueIcon, TonIcon } from '@/assets/icons'

interface Props {
  userName?: string
  secondPlayer?: string
  thirdPlayer?: string
  fourthPlayer?: string
}

export const TonRaceStepWaiting1vs3: FC<Props> = ({
  userName,
  secondPlayer,
  thirdPlayer,
  fourthPlayer,
}) => {
  return (
    <>
      <div className="flex gap-2 w-full mb-10">
        <div
          className={cx(
            'flex w-full bg-gray-700 border-1 border-gray-700 rounded-2xl justify-center items-end h-[120px] relative',
            {
              'bg-blue-700 border-blue-500': !!userName,
            }
          )}
        >
          <TonBlueIcon className="w-8 h-8 absolute bottom-1 right-1" />
          <div className="relative">
            <RacerLBlueSmIllustration className="w-[85px] h-[120px]" />
            <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
              {userName}
            </p>
          </div>
        </div>
        <div
          className={cx(
            'flex w-full bg-gray-700 border-1 border-gray-700 rounded-2xl justify-center items-end h-[120px] relative',
            {
              'bg-blue-700 border-blue-500': !!secondPlayer,
            }
          )}
        >
          {secondPlayer ? (
            <TonBlueIcon className="w-8 h-8 absolute bottom-1 left-1" />
          ) : (
            <TonIcon className="w-8 h-8 absolute text-brown-800 bottom-1 left-1 " />
          )}
          <div className="relative">
            {secondPlayer ? (
              <RacerRRedSmIllustration className="w-[85px] h-[120px]" />
            ) : (
              <RacerRGraySmIllustration className="w-[85px] h-[120px]" />
            )}
            {secondPlayer ? (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                {secondPlayer}
              </p>
            ) : (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                Searching
                <AnimatedDots />
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-full">
        <div
          className={cx(
            'flex w-full bg-gray-700 border-1 border-gray-700 rounded-2xl justify-center items-end h-[120px] relative',
            {
              'bg-blue-700 border-blue-500': !!thirdPlayer,
            }
          )}
        >
          {thirdPlayer ? (
            <TonBlueIcon className="w-8 h-8 absolute top-1 right-1" />
          ) : (
            <TonIcon className="w-8 h-8 absolute text-brown-800 top-1 right-1" />
          )}
          <div className="relative">
            {thirdPlayer ? (
              <RacerLYellowSmIllustration className="w-[85px] h-[120px]" />
            ) : (
              <RacerLGraySmIllustration className="w-[85px] h-[120px]" />
            )}
            {thirdPlayer ? (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                {thirdPlayer}
              </p>
            ) : (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                Searching
                <AnimatedDots />
              </p>
            )}
          </div>
        </div>
        <div
          className={cx(
            'flex w-full bg-gray-700 border-1 border-gray-700 rounded-2xl justify-center items-end h-[120px] relative',
            {
              'bg-blue-700 border-blue-500': !!fourthPlayer,
            }
          )}
        >
          {fourthPlayer ? (
            <TonBlueIcon className="w-8 h-8 absolute top-1 left-1" />
          ) : (
            <TonIcon className="w-8 h-8 absolute text-brown-800 top-1 left-1" />
          )}
          <div className="relative">
            {fourthPlayer ? (
              <RacerRGreenSmIllustration className="w-[85px] h-[120px]" />
            ) : (
              <RacerRGraySmIllustration className="w-[85px] h-[120px]" />
            )}
            {fourthPlayer ? (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                {fourthPlayer}
              </p>
            ) : (
              <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
                Searching
                <AnimatedDots />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
