'use client'

import { FC } from 'react'
import cx from 'classnames'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { CopyIcon } from '@/assets/icons'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { QUERY_PARAM_KEYS } from '@/constants/queryParamKey'
import { useSnackbar } from 'notistack'
import { useInviteFriend } from '@/hooks/useInviteFriend'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { useMutation_CallRefLinkMessage_User } from '@/services/useApi/user/useMutation'

interface Props {
  className?: string
}

export const InviteButtons: FC<Props> = ({ className }) => {
  const { user, webApp } = useTelegram()
  const { refData, inviteLimit } = useAppSelector(selectUser)

  const { enqueueSnackbar } = useSnackbar()

  const { handleInviteFriendClick } = useInviteFriend()

  const { mutateAsync } = useMutation_CallRefLinkMessage_User()

  const handleCopyBtn = async () => {
    try {
      const url = `${process.env.NEXT_PUBLIC_TELEGRAM_BOT_URL}/app?${QUERY_PARAM_KEYS.startapp}=refId_${user?.id}`
      await navigator.clipboard.writeText(url)

      webApp?.HapticFeedback.impactOccurred('light')

      enqueueSnackbar('Link copied to clipboard', { variant: 'info', autoHideDuration: 1500 })

      await mutateAsync()
    } catch (err) {
      console.error('Error copied: ', err)
    }
  }

  const leftInvites = Number(inviteLimit) - refData?.length

  return (
    <div className={cx('fixed w-full p-4 flex gap-2 bottom-[76px]', className)}>
      <CustomButton onClick={handleInviteFriendClick}>invite friend</CustomButton>
      <CustomButton variant={'bordered'} onClick={handleCopyBtn} className="max-w-[72px] w-[72px]">
        <CopyIcon className="w-[30px] h-[30px] text-yellow-500" />
      </CustomButton>
    </div>
  )
}
