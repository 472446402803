import { useCallback, useEffect } from 'react'

export const useLockBodyScroll = (): [() => void, () => void] => {
  const lockScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth

      const originalStyle = window.getComputedStyle(document.body).overflow
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${scrollbarWidth}px`

      return () => {
        document.body.style.overflow = originalStyle
        document.body.style.paddingRight = ''
      }
    }
  }, [])

  const unlockScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = ''
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleTouchMove = (e: TouchEvent) => e.preventDefault()

      if (document.body.style.overflow === 'hidden') {
        document.body.addEventListener('touchmove', handleTouchMove, {
          passive: false,
        })
      }

      return () => {
        if (document.body.style.overflow === 'hidden') {
          document.body.removeEventListener('touchmove', handleTouchMove)
        }
      }
    }
  }, [])

  return [lockScroll, unlockScroll]
}
