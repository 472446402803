'use client'

import { FC } from 'react'
import { Transition } from '@/components/Transitions'
import { formatRacePosition } from '@/utils/labels'

interface Props {
  position: number
}

export const PodiumTitle: FC<Props> = ({ position }) => {
  return (
    <div className="flex flex-col items-center justify-center p-2 pt-10">
      <div className="flex flex-col items-center justify-center">
        <Transition animationType="leftToRight">
          <p className="text-white text-lg font-bold mb-4">
            {position === 4 ? 'Unfortunately' : 'Congratulations'}
          </p>
        </Transition>
        <Transition animationType="rightToLeft">
          <p className="text-xs font-medium text-gray-300 text-center mb-2">You are</p>
        </Transition>
        <Transition animationType="scaleFromZero">
          <p className="text-[40px] font-extrabold text-white text-center">
            {formatRacePosition(position - 1)}
          </p>
        </Transition>
      </div>
    </div>
  )
}
