'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  selectRacePreparationEntryPrice,
  selectRacePreparationPair,
  selectRacePreparationRaceType,
} from '@/redux/features/racePreparationSlice'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { beginCell, toNano } from '@ton/ton'
import { SendTransactionRequest, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react'
import { selectUser } from '@/redux/features/user'
import { RaceType } from '@/types/race'
import { TON_BANK_ADDRESS } from '@/constants/addresses'
import { useQuery_GetTonExtendedAddressInformation_TonCenter } from '@/services/useApi/tonCenter/useQuery'
import { InfoIcon } from '@/assets/icons'
import { simulateDelay } from '@/utils/simulateDelay'
import { checkTonTransactions } from '@/services/api/user'
import { setQueryOptionsUserReFetchInterval } from '@/redux/features/queryOptionsSlice'
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'
import { selectTimeDifference } from '@/redux/features/time'
import { UserRaceStatus } from '@/types/user'
import { PRICE_DIVIDER } from '@/constants/race'
import { TonRaceConfirmingStatus } from '@/app/ton-race-step-choose-nitro/components/TonRaceConfirmingStatus'
import { TonRaceErrorStatus } from '@/app/ton-race-step-choose-nitro/components/TonRaceErrorStatus'

interface Props {
  className?: string
}

export const TonRaceConfirmButton: FC<Props> = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorStatus, setIsErrorStatus] = useState(false)

  const dispatch = useAppDispatch()

  const { data } = useQuery_GetTonExtendedAddressInformation_TonCenter()

  const { webApp } = useTelegram()
  const [tonConnectUI] = useTonConnectUI()
  const userFriendlyAddress = useTonAddress()

  const { tgId, raceStatus } = useAppSelector(selectUser)
  const entryPrice = useAppSelector(selectRacePreparationEntryPrice)
  const pair = useAppSelector(selectRacePreparationPair)
  const raceType = useAppSelector(selectRacePreparationRaceType)
  const timeDifference = useAppSelector(selectTimeDifference)

  const isInsufficientFunds =
    !!entryPrice && !!data?.balance && Number(data?.balance) < Number(entryPrice)

  const maxPlayers = useMemo(() => {
    return raceType === RaceType.OneVsOne ? 2 : 4
  }, [raceType])

  const handleNextStep = async () => {
    webApp?.HapticFeedback?.impactOccurred('medium')

    if (!entryPrice || !pair) return

    try {
      setIsLoading(true)

      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(
          `${tgId} ${toNano((Number(entryPrice) / PRICE_DIVIDER).toString()).toString()} ${maxPlayers} ${pair}`
        )
        .endCell()

      const transaction: SendTransactionRequest = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: TON_BANK_ADDRESS,
            amount: toNano((Number(entryPrice) / PRICE_DIVIDER).toString()).toString(),
            payload: body.toBoc().toString('base64'),
          },
        ],
      }

      await tonConnectUI.sendTransaction(transaction)
      await simulateDelay()
      await checkTonTransactions()

      dispatch(setQueryOptionsUserReFetchInterval(1000))

      const nowDate = new Date(timeDifference + Date.now())

      nowDate.setMinutes(nowDate.getMinutes() + 2)

      window.localStorage.setItem(LOCAL_STORAGE_KEYS.matchingRaceStartTime, nowDate.toISOString())

      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    if (raceStatus === UserRaceStatus.TON_WAITING_FOR_TRANSACTION_ERROR) {
      setIsErrorStatus(true)
    }
  }, [raceStatus])

  useEffect(() => {
    return () => {
      dispatch(setQueryOptionsUserReFetchInterval(false))
    }
  }, [])

  return (
    <>
      {isInsufficientFunds && (
        <p className="fixed left-0 bottom-[148px] w-full flex justify-center text-red-500 gap-2 items-center">
          <InfoIcon className="w-[22px] h-[22px]" />
          <span>Insufficient funds, top up your wallet</span>
        </p>
      )}
      <div className={cx('w-full px-4', className)}>
        <CustomButton
          withCheckeredFlag
          isDisabledDark={!pair || isInsufficientFunds}
          onClick={handleNextStep}
          isLoading={isLoading || raceStatus === UserRaceStatus.TON_WAITING_FOR_TRANSACTION}
          variant="secondary"
          className={cx({
            'text-base': !!pair,
          })}
        >
          Confirm {!!pair && `${entryPrice} TON`}
        </CustomButton>
      </div>
      {(isLoading || raceStatus === UserRaceStatus.TON_WAITING_FOR_TRANSACTION) && (
        <TonRaceConfirmingStatus />
      )}
      {isErrorStatus && (
        <TonRaceErrorStatus
          onClose={() => {
            setIsLoading(false)
            setIsErrorStatus(false)
            dispatch(setQueryOptionsUserReFetchInterval(false))
          }}
        />
      )}
    </>
  )
}
