'use client'

import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { RaceStartTimer } from '../RaceStartTimer'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { clearWsData } from '@/redux/features/wsDataSlice'
import { GamePlaceMobileComponent } from './GamePlaceMobile.component'
import { clearRace, selectIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { Loader } from '@/components/ui/Loader'
import { selectUser } from '@/redux/features/user'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { queryClient } from '@/app/providers'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { getCurrentRaceType } from '@/utils/race'
import { RaceType } from '@/types/race'
import { TonRaceStartTimer } from '@/app/race/components/TonRaceStartTimer'

export const GamePlaceContainer = () => {
  const dispatch = useAppDispatch()

  const [isFakeLoading, setIsFakeLoading] = useState(true)

  const isLoadingAuth = useAppSelector(selectIsLoadingAuth)

  const isAllRaceActivitiesCompleted = useAppSelector(selectIsAllRaceActivitiesCompleted)
  const { tgId: userTgId, waitingForTonGameData, raceStatus } = useAppSelector(selectUser)

  const { data: game, isLoading: isLoadingActiveGame = true } = useQuery_GetGameById_Game()

  const currentRaceType = getCurrentRaceType(waitingForTonGameData, raceStatus)

  useEffect(() => {
    return () => {
      dispatch(clearWsData())
      dispatch(clearRace())
    }
  }, [])

  useEffect(() => {
    if (isAllRaceActivitiesCompleted && !!userTgId) {
      ;(async () => {
        await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userData] })
        dispatch(setRoute(RoutePaths.racePodium))
      })()
    }
  }, [isAllRaceActivitiesCompleted, userTgId])

  const isLoading =
    isLoadingAuth || isLoadingActiveGame || (!game && !isAllRaceActivitiesCompleted) || !game

  useEffect(() => {
    if (isLoading || !isFakeLoading) return

    setTimeout(() => {
      setIsFakeLoading(false)
    }, 2000)
  }, [isLoading, isFakeLoading])

  return (
    <>
      {(isLoading || isFakeLoading) && <Loader />}
      {currentRaceType === RaceType.PlayWithPlatform && (
        <RaceStartTimer isLoadingGeneral={isLoading || isFakeLoading} />
      )}
      {currentRaceType !== RaceType.PlayWithPlatform && (
        <TonRaceStartTimer isLoadingGeneral={isLoading || isFakeLoading} />
      )}
      <GamePlaceMobileComponent />
    </>
  )
}
