export const usePreventSwipeClose = () => {
  const unlockScroll = () => {
    document.body.style.overflowY = 'auto'
    document.body.style.marginTop = '0'
    document.body.style.height = '100vh'
    document.body.style.paddingBottom = '0'
  }

  const lockScroll = () => {
    document.body.style.overflowY = 'hidden'
    document.body.style.marginTop = '100px'
    document.body.style.height = window.innerHeight + 100 + 'px'
    document.body.style.paddingBottom = '100px'
    window.scrollTo(0, 100)
  }

  return {
    unlockScroll,
    lockScroll,
  }
}
