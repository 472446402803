'use client'

import { FC, useMemo } from 'react'
import cx from 'classnames'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { useMutation_ClaimUserDailyTask_User } from '@/services/useApi/user/useMutation'
import { useDailyRewards } from '@/hooks/useDailyRewards'
import { UserTaskType } from '@/types/user'

interface Props {
  className?: string
}

export const DailyRewardsButton: FC<Props> = ({ className }) => {
  const { mutateAsync, isPending } = useMutation_ClaimUserDailyTask_User()
  const { isDone } = useDailyRewards()

  return (
    <div className={cx('w-full', className)}>
      <CustomButton
        onClick={async () => {
          await mutateAsync({
            task: UserTaskType.DAILY_REWARDS,
          })
        }}
        isLoading={isPending}
        isDisabledDark={isDone}
      >
        {isDone ? 'come back tomorrow' : 'claim'}
      </CustomButton>
    </div>
  )
}
