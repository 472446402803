'use client'

import { FC, useMemo } from 'react'
import cx from 'classnames'
import { Transition } from '@/components/Transitions'
import { CircleDesignedCheckeredIcon, CoinIcon } from '@/assets/icons'
import { getDaysSinceEpoch } from '@/utils/time'
import { UserDailyTask } from '@/types/user'
import { ModalCustom } from '@/components/ui/ModalCustom'
import { DailyRewardsButton } from './DailyRewardsButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  dailyRewards: UserDailyTask[]
  isDone: boolean
}

export const DailyRewardsModal: FC<Props> = ({ isOpen, onClose, dailyRewards, isDone }) => {
  const idxActiveDay = useMemo(() => {
    const lastIndex = dailyRewards.findLastIndex((item) => item.claimed && item.claimDate)

    if (lastIndex === -1) return 0

    const daysSinceEpoch = getDaysSinceEpoch()

    if (dailyRewards[lastIndex].claimDate === daysSinceEpoch) return lastIndex

    return !!dailyRewards[lastIndex] ? lastIndex + 1 : null
  }, [dailyRewards])

  return (
    <ModalCustom isOpen={isOpen} onClose={onClose} classNameContent="flex justify-between">
      <div className="w-full">
        <p className="text-base font-normal text-white mb-4 ">Daily reward:</p>
        <div className="grid grid-cols-4 gap-2 w-full">
          {dailyRewards.map((item, index) => (
            <div
              key={item.day}
              className={cx(
                'flex flex-col items-center justify-center gap-1 bg-purple-900 rounded-medium p-2 box-border border-1 border-purple-900 text-yellow-500',
                {
                  'text-yellow-700 bg-purple-800': item.claimed,
                  'border-yellow-500 shadow-yellowLight': index === idxActiveDay && !isDone,
                }
              )}
            >
              <p
                className={cx('text-xs font-normal text-white', {
                  '!text-gray-300': item.claimed,
                })}
              >
                Day {item.day}
              </p>
              <Transition animationType="scaleFromZero">
                {item?.claimed ? (
                  <CircleDesignedCheckeredIcon className="w-10 min-w-10 h-10" />
                ) : (
                  <CoinIcon className="w-10 min-w-10 h-10" />
                )}
              </Transition>
              <p className="text-xs font-normal">+{item.amount}</p>
            </div>
          ))}
        </div>
      </div>
      <DailyRewardsButton />
    </ModalCustom>
  )
}
