'use client'

import { FC } from 'react'
import cx from 'classnames'

interface Props {
  height: number
  bottom: number
  finish: number
}

export const RaceGameLayout: FC<Props> = ({ height, bottom, finish }) => {
  const lines = []

  for (let i = 0; i <= height; i += 100) {
    const isMajorLine = i % 200 === 0
    const isStart = i === 0
    const isFinish = i === finish
    const isAfterFinish = i > finish

    lines.push(
      <div key={i} className="w-full h-[1px] absolute" style={{ bottom: `${i}px` }}>
        <div className="w-full h-[1px] relative bottom-0 left-0 flex items-center flex-row">
          {isStart && (
            <>
              <p className={`text-green-race-500 text-xs min-w-[40px] text-right`}>START</p>
              <div className="line-green" />
            </>
          )}
          {isFinish && (
            <>
              <p
                className={`text-gray-race-100 text-xs min-w-[40px] text-right relative -bottom-[5px]`}
              >
                FINISH
              </p>
              <div className="w-full h-[8px] bg-[url('/illustrations/finish-line.svg')] bg-left-top relative -bottom-[5px]" />
            </>
          )}
          {!isStart && !isFinish && (
            <>
              {isMajorLine && !isAfterFinish ? (
                <>
                  <p className={`left-0 text-gray-race-800 -top-2 text-xs min-w-[40px] text-right`}>
                    {i}
                  </p>
                  <div className="line" />
                </>
              ) : (
                <div className={`line`} />
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx(`w-full left-0 absolute`)}
      style={{
        bottom: `${bottom}px`,
        height: `${height}px`,
        transition: 'bottom 1000ms linear',
        willChange: 'bottom',
      }}
    >
      <div className="relative w-full h-full">{lines}</div>
    </div>
  )
}
