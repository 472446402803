'use client'

import { FC } from 'react'
import { Transition } from '@/components/Transitions'
import { InviteFriendCard } from '@/app/rewards/components/InviteFriendCard'
import { InvitePremiumFriendCard } from '@/app/rewards/components/InvitePremiumFriendCard'
import { InviteChannelCard } from '@/app/rewards/components/InviteChannelCard'
import { InviteChatCard } from '@/app/rewards/components/InviteChatCard'

interface Props {}

export const TasksSection: FC<Props> = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-24">
      <div className="w-full">
        <Transition animationType="leftToRight">
          <p className="text-xs font-normal text-gray-300 pb-4 w-full text-left">Tasks list:</p>
        </Transition>
      </div>
      <div className="w-full flex flex-col gap-2">
        <InviteFriendCard />
        <InvitePremiumFriendCard />
        <InviteChannelCard />
        <InviteChatCard />
      </div>
    </div>
  )
}
