'use client'

import { FC } from 'react'
import cx from 'classnames'
import { CheckeredFlagIcon } from '@/assets/icons'
import { AnimatedDots } from '@/components/ui/AnimatedDots'
import { useTelegram } from '@/app/providers/TelegramProvider'

interface Props {
  onClick?: () => void
  isDisabled?: boolean | null
  isDisabledDark?: boolean | null
  withCheckeredFlag?: boolean
  className?: string
  children?: React.ReactNode
  variant?: 'default' | 'bordered' | 'secondary' | 'secondaryBordered'
  isLoading?: boolean
  onTouchEnd?: (event: React.TouchEvent<HTMLDivElement>) => void
}

export const CustomButton: FC<Props> = ({
  onClick,
  isDisabled = false,
  isDisabledDark = false,
  withCheckeredFlag = false,
  className = '',
  children,
  variant,
  isLoading,
  onTouchEnd,
}) => {
  const { webApp } = useTelegram()

  const handleClick = () => {
    if (!onClick || isDisabled || isLoading || isDisabledDark) return

    onClick()
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!onTouchEnd || isDisabled || isLoading || isDisabledDark) return

    onTouchEnd(event)
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  return (
    <div
      className={cx(
        'text-gray-700 text-lg italic font-bold rounded-medium bg-yellow-radial-gradient shadow-yellowLight w-full h-[50px] flex items-center cursor-pointer px-2 py-2.5 uppercase justify-center box-border',
        className,
        {
          '!shadow-noneCustom bg-img-inherit bg-olive-900 border-yellow-500 border-1 !text-yellow-500':
            variant === 'bordered',
          '!shadow-noneCustom !bg-blue-radial text-white': variant === 'secondary',
          '!text-gray-300': variant === 'secondary' && (isDisabledDark || isDisabled),
          '!shadow-noneCustom bg-img-inherit bg-blue-700 text-white !border-blue-500 border-1':
            variant === 'secondaryBordered',
          '!text-gray-300 !border-blue-700':
            variant === 'secondaryBordered' && (isDisabledDark || isDisabled),
          'pointer-events-none !shadow-noneCustom !bg-img-inherit  !text-gray-700/50 !bg-yellow-700':
            isDisabled || (isDisabled && isLoading),
          'pointer-events-none !shadow-noneCustom !bg-img-inherit !text-purple-700 !bg-midnight-race-900':
            isDisabledDark || (isDisabledDark && isLoading),
          'justify-between ': withCheckeredFlag,
        }
      )}
      onClick={handleClick}
      onTouchEnd={handleTouchEnd}
    >
      {withCheckeredFlag && <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px]" />}
      {isLoading ? (
        <AnimatedDots className="text-4xl flex items-center pb-4" />
      ) : (
        <span>{children}</span>
      )}
      {withCheckeredFlag && (
        <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px] rotate-180" />
      )}
    </div>
  )
}
