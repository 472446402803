'use client'

import { FC, useEffect, useMemo } from 'react'
import CountUp from 'react-countup'
import { LabelLight } from '@/components/Labels/LabelLight'
import cx from 'classnames'
import { useStopwatch, useTimer } from 'react-timer-hook'
import { formatTimer } from '@/utils/formatValue'
import { PlayerInGame } from '@/types/player'
import { RACE_DISTANCE } from '@/constants/race'

interface Props {
  startAt: string
  wsCalculationUpdates?: Array<PlayerInGame>
  userTgId?: string
  className?: string
  timeDifference: number
  isPvpMode?: boolean
}

export const RaceStopwatchAndDistanceComponent: FC<Props> = ({
  startAt,
  wsCalculationUpdates,
  userTgId,
  className,
  timeDifference,
  isPvpMode,
}) => {
  const { isRunning = true } = useTimer({
    expiryTimestamp: new Date(new Date(startAt).getTime() - timeDifference),
  })

  const stopwatch = useStopwatch({
    autoStart: false,
    offsetTimestamp: new Date(new Date(startAt).getTime() - timeDifference),
  })

  const distance = useMemo(() => {
    if (!wsCalculationUpdates?.length) {
      return 0
    }

    const user = wsCalculationUpdates.find(
      (player) => player?.id?.toLowerCase() === userTgId?.toLowerCase()
    )

    if (!user) {
      return 0
    }

    const distance = parseFloat(user.totalDistance)

    if (distance >= RACE_DISTANCE) {
      return RACE_DISTANCE
    }

    return distance
  }, [wsCalculationUpdates, userTgId])

  useEffect(() => {
    if (!isRunning) {
      stopwatch.reset(new Date(new Date(startAt).getTime() - timeDifference), true)
    }
  }, [isRunning])

  return (
    <LabelLight
      color={isPvpMode ? 'blue' : 'yellow'}
      className={cx('max-w-[87px] w-full absolute top-2 sm:top-4 right-0 z-20', className)}
    >
      <div className="flex flex-col justify-center items-center  font-normal">
        <p className="text-sm !leading-none text-white">
          {isRunning ? '00:00:00' : formatTimer(stopwatch)}
        </p>
        <p className="text-base !leading-none font-bold">
          <CountUp end={distance} preserveValue duration={1} />m
        </p>
      </div>
    </LabelLight>
  )
}
