'use client'

import cx from 'classnames'
import { BOTTOM_MENU_ITEMS } from '@/components/BottomTabMenu/constants'
import { BorderIcon } from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectRoute, setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useTelegram } from '@/app/providers/TelegramProvider'

export const BottomTabMenu = () => {
  const dispatch = useAppDispatch()
  const activeRoute = useAppSelector(selectRoute)
  const { webApp } = useTelegram()

  const handleRoute = (route: RoutePaths) => {
    dispatch(setRoute(route))
    webApp?.HapticFeedback?.impactOccurred('light')
  }

  return (
    <section className="w-full fixed left-0 z-[200] bottom-0 pb-4 px-4 no-select">
      <div className="h-[60px] px-2.5 py-2.5 flex items-center justify-between w-full rounded-medium bg-blue-800">
        {BOTTOM_MENU_ITEMS.map((item) => {
          return (
            <div
              key={item.link}
              className={cx(
                'w-[60px] min-w-[60px] h-10 rounded-[12px] flex-col flex items-center justify-center relative gap-0.5 text-yellow-700 overflow-hidden',
                {
                  'bg-olive-900':
                    item.link === activeRoute && item.link !== RoutePaths.tonRaceStepRaceType,
                  'bg-gray-600':
                    item.link === activeRoute && item.link === RoutePaths.tonRaceStepRaceType,
                }
              )}
              onClick={() => {
                if (item.link) handleRoute(item.link)
              }}
            >
              {item.link === RoutePaths.tonRaceStepRaceType && (
                <>
                  <item.icon
                    className={cx('w-[24px] h-[24px] text-blue-400', {
                      '!text-blue-300': item.link === activeRoute,
                    })}
                  />
                  <p
                    className={cx('text-[10px] leading-none text-blue-400', {
                      '!text-blue-300': item.link === activeRoute,
                    })}
                  >
                    {item.text}
                  </p>
                </>
              )}
              {item.link !== RoutePaths.tonRaceStepRaceType && (
                <>
                  {item.link === activeRoute && (
                    <item.icon className={cx('w-[24px] h-[24px] gradient-icon-active')} />
                  )}
                  {item.link !== activeRoute && (
                    <item.inActiveIcon
                      className={cx('w-[24px] h-[24px] gradient-icon-inactive text-yellow-700')}
                    />
                  )}
                  <p
                    className={cx('text-[10px] leading-none', {
                      '!text-yellow-500': item.link === activeRoute,
                    })}
                  >
                    {item.text}
                  </p>
                </>
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}
