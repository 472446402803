'use client'

import { Transition } from '@/components/Transitions'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { Loader } from '@/components/ui/Loader'
import { PodiumButtons } from './components/PodiumButtons'
import { PodiumTitle } from '@/app/race-podium/components/PodiumTitle'
import { Podium1stPosition } from '@/app/race-podium/components/Podium1stPosition'
import { Podium2ndPosition } from '@/app/race-podium/components/Podium2ndPosition'
import { Podium3rdPosition } from '@/app/race-podium/components/Podium3rdPosition'
import { Podium4thPosition } from '@/app/race-podium/components/Podium4thPosition'
import { setIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { setQueryOptionsUserReFetchInterval } from '@/redux/features/queryOptionsSlice'
import { TON_RACE_STATUSES } from '@/constants/race'
import { UserRaceStatus } from '@/types/user'
import { RaceType } from '@/types/race'
import { TonBgIllustration } from '@/assets/illustrations'
import { TonEntryPrice } from '@/types/game'
import { TonPodiumTitle } from '@/app/race-podium/ton-components/TonPodiumTitle'
import { TonPodium1stPosition } from '@/app/race-podium/ton-components/TonPodium1stPosition'
import { TonPodium2ndPosition } from '@/app/race-podium/ton-components/TonPodium2ndPosition'
import { TonPodiumFlags } from '@/app/race-podium/ton-components/TonPodiumFlags'
import { TonPodiumButtons } from '@/app/race-podium/ton-components/TonPodiumButtons'

export default function RacePodiumPage() {
  const dispatch = useAppDispatch()
  const { tgId, raceStatus } = useAppSelector(selectUser)

  const { data, isPending } = useQuery_GetGameById_Game()

  const userPosition = useMemo(() => {
    if (!data?.resultScore) return 4

    const idx = data.resultScore.findIndex((item) => {
      return item.id.toLowerCase() === tgId.toLowerCase()
    })

    return idx + 1
  }, [data, tgId])

  const isTonRace = useMemo(() => {
    return TON_RACE_STATUSES.includes(raceStatus as UserRaceStatus)
  }, [raceStatus])

  const raceType = useMemo(() => {
    if (!isTonRace || !data?.resultScore) return RaceType.PlayWithPlatform

    if (data.resultScore.length === 2) {
      return RaceType.OneVsOne
    } else {
      return RaceType.OneVsThree
    }
  }, [isTonRace, data])

  useEffect(() => {
    dispatch(setIsAllRaceActivitiesCompleted(false))
    dispatch(setQueryOptionsUserReFetchInterval(false))
  }, [])

  return (
    <>
      {isTonRace && (
        <div className="layer-gradient layer-gradient-blue flex flex-col justify-between relative overflow-hidden">
          {!!data?.resultScore && (
            <>
              <TonPodiumTitle position={userPosition} raceType={raceType} />
              {userPosition === 1 && raceType === RaceType.OneVsThree && (
                <TonPodium1stPosition
                  raceType={RaceType.OneVsThree}
                  entryPrice={(data?.price as TonEntryPrice) || undefined}
                />
              )}
              {userPosition === 1 && raceType === RaceType.OneVsOne && (
                <TonPodium1stPosition
                  raceType={RaceType.OneVsOne}
                  entryPrice={(data?.price as TonEntryPrice) || undefined}
                />
              )}
              {userPosition === 2 && raceType === RaceType.OneVsThree && (
                <TonPodium2ndPosition entryPrice={(data?.price as TonEntryPrice) || undefined} />
              )}
              {userPosition === 2 && raceType === RaceType.OneVsOne && (
                <TonPodiumFlags text="Try again" />
              )}
              {userPosition === 3 && (
                <TonPodiumFlags text={`You are not the last, <br /> but it is not the podium`} />
              )}
              {userPosition === 4 && <TonPodiumFlags text="Good luck next time" />}
              <div className="h-[104px] w-full"></div>
            </>
          )}
          <TonBgIllustration className="absolute top-8 left-1/2 transform -translate-x-1/2 -z-10" />
          <Transition animationType="bottomToTop">
            <TonPodiumButtons className="fixed left-0 bottom-4 z-10" />
          </Transition>
        </div>
      )}
      {!isTonRace && (
        <div className="layer-gradient flex flex-col justify-between">
          {!!data?.resultScore && (
            <>
              <PodiumTitle position={userPosition} />
              {userPosition === 1 && <Podium1stPosition />}
              {userPosition === 2 && <Podium2ndPosition />}
              {userPosition === 3 && <Podium3rdPosition />}
              {userPosition === 4 && <Podium4thPosition />}
              <div className="h-[104px] w-full"></div>
            </>
          )}
          {userPosition !== 4 && <div className="confetti" />}
          <Transition animationType="bottomToTop">
            <PodiumButtons className="fixed left-0 bottom-4 z-10" />
          </Transition>
        </div>
      )}
      {(isPending || !data?.resultScore) && <Loader />}
    </>
  )
}
