import { addMinutes, format } from 'date-fns'
import { StopwatchResult, TimerResult } from 'react-timer-hook'

export const formatterCurrency = new Intl.NumberFormat('en-US')

export const toFixedSafe = (value: number, count?: number): number | string => {
  try {
    return Number((value + Number.EPSILON).toFixed(count || 6)).toString()
  } catch (e) {
    return value
  }
}

export function formatDate(
  dateProp?: string | Date | number | undefined | null,
  formatDate = 'MM/dd/yyyy'
) {
  if (!dateProp) {
    return ''
  }

  const date = new Date(dateProp)
  return format(addMinutes(date, date.getTimezoneOffset()), formatDate)
}

export const formatTradingPair = (
  pairString: string | null | undefined,
  isShort = false
): string => {
  if (!pairString) return ''

  const [baseCurrency, quoteCurrency] = pairString.toUpperCase().split('USDT')

  if (isShort) {
    return `${baseCurrency}/USDT`
  }

  return `${baseCurrency} / USDT`
}

export function formatTimer(timer: TimerResult | StopwatchResult): string {
  const { seconds, minutes, hours, days } = timer

  const hoursFormatted = hours < 10 ? '0' + hours : hours
  const minutesFormatted = minutes < 10 ? '0' + minutes : minutes
  const secondsFormatted = seconds < 10 ? '0' + seconds : seconds

  if (!days) {
    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`
  }

  return `${days} days`
}
