'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { RealtimeChartComponent } from './RealtimeChart.component'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectWsOptionsReadyState, setWsOptionsMessage } from '@/redux/features/wsOptionsSlice'
import { ReadyState } from 'react-use-websocket'
import {
  selectWsCalculationUpdates,
  selectWsCandlePriceList,
  selectWsIsGameEnded,
  selectWsLastCandlePrice,
  setWsCandlePriceList,
} from '@/redux/features/wsDataSlice'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { GameDirection } from '@/types/game'
import { VoteState } from '@/types/player'
import { WS_CHANNELS } from '@/constants/ws'
import { selectTimeDifference } from '@/redux/features/time'
import { selectUser } from '@/redux/features/user'

export const RealtimeChartContainer: FC = () => {
  const dispatch = useAppDispatch()

  const { tgId: userTgId } = useAppSelector(selectUser)

  const [isStart, setIsStart] = useState<boolean>(false)

  const { data: game, isLoading: isLoadingActiveGame = true } = useQuery_GetGameById_Game()

  const timeDifference = useAppSelector(selectTimeDifference)
  const wsOptionsReadyState = useAppSelector(selectWsOptionsReadyState)
  const wsCalculationUpdates = useAppSelector(selectWsCalculationUpdates)
  const wsIsGameEnded = useAppSelector(selectWsIsGameEnded)
  const wsLastCandlePrice = useAppSelector(selectWsLastCandlePrice)
  const wsCandlePriceList = useAppSelector(selectWsCandlePriceList)

  const userInActiveGame = useMemo(() => {
    return game?.players?.find((player) => player?.id?.toLowerCase() === userTgId?.toLowerCase())
  }, [game, userTgId])

  const pair = useMemo(() => {
    return userInActiveGame?.pair
  }, [userInActiveGame])

  const userInGameWs = useMemo(() => {
    return wsCalculationUpdates?.find((game) => game?.id?.toLowerCase() === userTgId?.toLowerCase())
  }, [wsCalculationUpdates, userTgId])

  const userUpBet = useMemo(() => {
    if (!userInGameWs) return undefined

    if (
      userInGameWs?.vote?.state === VoteState.Opened &&
      userInGameWs?.vote?.predictionLevel === GameDirection.UP
    ) {
      return parseFloat(userInGameWs?.vote?.openedAtPrice)
    }

    return undefined
  }, [userInGameWs])

  const userDownBet = useMemo(() => {
    if (!userInGameWs) return undefined

    if (
      userInGameWs?.vote?.state === VoteState.Opened &&
      userInGameWs?.vote?.predictionLevel === GameDirection.DOWN
    ) {
      return parseFloat(userInGameWs?.vote?.openedAtPrice)
    }

    return undefined
  }, [userInGameWs])

  useEffect(() => {
    if (!!pair && !isStart && wsOptionsReadyState === ReadyState.OPEN) {
      dispatch(
        setWsOptionsMessage(
          JSON.stringify({
            event: 'subscribe',
            channels: [`${WS_CHANNELS.candleUpdate}-${pair}`],
          })
        )
      )

      setIsStart(true)
    }
  }, [isStart, pair, wsOptionsReadyState])

  useEffect(() => {
    if (!!pair && !!wsCandlePriceList && wsOptionsReadyState === ReadyState.OPEN) {
      dispatch(
        setWsOptionsMessage(
          JSON.stringify({
            event: 'unsubscribe',
            channels: [`${WS_CHANNELS.candleUpdate}-${pair}`],
          })
        )
      )

      dispatch(
        setWsOptionsMessage(
          JSON.stringify({
            event: 'subscribe',
            channels: [`${WS_CHANNELS.priceUpdate}-${pair}`],
          })
        )
      )

      dispatch(
        setWsCandlePriceList({
          candlePriceList: null,
          timeDifference: 0,
        })
      )
    }
  }, [wsCandlePriceList, pair, wsOptionsReadyState])

  useEffect(() => {
    if (wsIsGameEnded && isStart) {
      dispatch(
        setWsOptionsMessage(
          JSON.stringify({
            event: 'unsubscribe',
            channels: [`${WS_CHANNELS.priceUpdate}-${pair}`],
          })
        )
      )
    }
  }, [wsIsGameEnded])

  useEffect(() => {
    return () => {
      dispatch(
        setWsOptionsMessage(
          JSON.stringify({
            event: 'unsubscribe',
            channels: [`${WS_CHANNELS.priceUpdate}-${pair}`],
          })
        )
      )
    }
  }, [])

  return (
    <RealtimeChartComponent
      priceList={wsCandlePriceList}
      upBetPoint={userUpBet}
      downBetPoint={userDownBet}
      pair={pair}
      isLoading={isLoadingActiveGame || !userTgId}
      lastPrice={wsLastCandlePrice}
      startAt={game?.startAt}
      timeDifference={timeDifference}
    />
  )
}
