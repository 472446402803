'use client'

import { GlobalInfoComponent } from './GlobalInfoComponent'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { useEffect } from 'react'
import { queryClient } from '@/app/providers'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { selectStat } from '@/redux/features/stat'

export const GlobalInfoContainer = () => {
  const dispatch = useAppDispatch()

  const { webApp } = useTelegram()

  const stat = useAppSelector(selectStat)

  useEffect(() => {
    ;(async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.stat] })
    })()
  }, [])

  const handleGoToGameRules = () => {
    webApp?.HapticFeedback?.impactOccurred('medium')

    dispatch(setRoute(RoutePaths.gameRules))
  }

  return <GlobalInfoComponent onGoToGameRulesBtnClick={handleGoToGameRules} statistic={stat} />
}
