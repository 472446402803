'use client'

import { TonRaceChooseNitro } from './components/TonRaceChooseNitro'
import { TonRaceConfirmButton } from './components/TonRaceConfirmButton'
import { useTonProtected } from '@/hooks/useTonProtected'

export default function TonRaceStepChooseNitroPage() {
  useTonProtected()

  return (
    <>
      <div className="layer-gradient layer-gradient-blue">
        <TonRaceChooseNitro />
      </div>
      <TonRaceConfirmButton className="fixed left-0 bottom-[92px]" />
    </>
  )
}
