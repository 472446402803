import { ProfileInfo } from './components/ProfileInfo'
import { FriendsList } from './components/FriendsList'
import { InviteButtons } from './components/InviteButtons'

export default function ProfilePage() {
  return (
    <div className="layer-gradient">
      <div className="flex flex-col overflow-y-scroll pb-32">
        <ProfileInfo />
        <FriendsList />
      </div>
      <InviteButtons />
    </div>
  )
}
