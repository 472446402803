'use client'

import { FC } from 'react'
import {
  RacerLBlueSmIllustration,
  RacerRGraySmIllustration,
  RacerRVioletSmIllustration,
} from '@/assets/illustrations'
import cx from 'classnames'
import { AnimatedDots } from '@/components/ui/AnimatedDots'

interface Props {
  userName?: string
  secondPlayer?: string
}

export const TonRaceStepWaiting1vs1: FC<Props> = ({ userName, secondPlayer }) => {
  return (
    <div
      className={cx(
        'flex w-full bg-gray-700 border-1 border-gray-700 rounded-2xl items-end justify-between h-[120px]',
        {
          'bg-blue-700 border-blue-500': !!userName && !!secondPlayer,
        }
      )}
    >
      <div className="ml-11 relative">
        <RacerLBlueSmIllustration className="w-[85px] h-[120px]" />
        <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
          {userName}
        </p>
      </div>
      <div className="mr-11 relative">
        {secondPlayer ? (
          <RacerRVioletSmIllustration className="w-[85px] h-[120px]" />
        ) : (
          <RacerRGraySmIllustration className="w-[85px] h-[120px]" />
        )}
        {secondPlayer ? (
          <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
            {secondPlayer}
          </p>
        ) : (
          <p className="font-normal text-sm text-gray-300 absolute text-center -bottom-6 w-[200%] -left-[50%]">
            Searching
            <AnimatedDots />
          </p>
        )}
      </div>
    </div>
  )
}
