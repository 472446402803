import { useMutation } from '@tanstack/react-query'
import {
  callRefLinkMessage,
  cancelTonTransaction,
  claimTonRefRewards,
  claimUserTask,
  updateUserBoost,
} from '@/services/api/user'
import { queryClient } from '@/app/providers'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { UserBoostType, UserTaskType } from '@/types/user'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'

export const useMutation_ClaimUserDailyTask_User = (successCallback?: () => void) => {
  return useMutation({
    mutationFn: async ({ task }: { task: UserTaskType }) => {
      return await claimUserTask(task)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userTasks] })
      successCallback && successCallback()
    },
  })
}

export const useMutation_UpdateUserBoost_User = (successCallback?: () => void) => {
  const { tgId } = useAppSelector(selectUser)

  return useMutation({
    mutationFn: async ({ boostType }: { boostType: UserBoostType }) => {
      return await updateUserBoost(tgId, boostType)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userData] })
      successCallback && successCallback()
    },
  })
}

export const useMutation_CallRefLinkMessage_User = (successCallback?: () => void) => {
  return useMutation({
    mutationFn: async () => {
      return await callRefLinkMessage()
    },
    onSuccess: async () => {
      successCallback && successCallback()
    },
  })
}

export const useMutation_CancelTonTransaction_User = (successCallback?: () => void) => {
  const { tgId } = useAppSelector(selectUser)

  return useMutation({
    mutationFn: async () => {
      return await cancelTonTransaction(tgId)
    },
    onSuccess: async () => {
      successCallback && successCallback()
    },
  })
}

export const useMutation_ClaimTonRefRewards_User = (successCallback?: () => void) => {
  const { tgId } = useAppSelector(selectUser)

  return useMutation({
    mutationFn: async ({ addressTo }: { addressTo: string }) => {
      return await claimTonRefRewards(tgId, addressTo)
    },
    onSuccess: async () => {
      successCallback && successCallback()
    },
  })
}
