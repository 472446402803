'use client'

import { FC } from 'react'
import { ArrowRightIcon, CoinIcon, CombatIcon, PaperIcon, TonIcon, UsersIcon } from '@/assets/icons'
import { IStatistic } from '@/types/general'
import { Transition } from '@/components/Transitions'
import Numeral from 'react-numeral'
import { toFixedSafe } from '@/utils/formatValue'

interface Props {
  onGoToGameRulesBtnClick: () => void
  statistic: IStatistic
}

export const GlobalInfoComponent: FC<Props> = ({ onGoToGameRulesBtnClick, statistic }) => {
  const {
    racesTotal,
    races24,
    tokensTotal,
    tokens24,
    usersTotal,
    users24,
    tonRacesTotal,
    tonRewardsTotal,
    tonRaces24,
    tonRewards24,
  } = statistic

  return (
    <div className="flex flex-col gap-6">
      <Transition animationType="leftToRight">
        <p className="text-lg font-medium text-center text-white">Global info & stats</p>
      </Transition>
      <div className="w-full px-4 py-4 bg-purple-900 rounded-medium flex items-center justify-center gap-2 text-white -mb-4">
        <UsersIcon className="w-[30px] h-[30px] min-w-[30px]" />
        <p className="font-normal text-base text-gray-300">Users:</p>
        <p className="text-lg font-bold leading-none">{usersTotal}</p>
        {!!Number(users24) && <p className="text-sm font-bold">+{users24}</p>}
      </div>
      <div className="w-full grid grid-cols-2 gap-2">
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CombatIcon className="w-[30px] h-[30px] min-w-[30px] text-blue-500" />
            <p className="font-normal text-base text-blue-500">TON Races</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">{tonRacesTotal}</p>
            {!!Number(tonRaces24) && (
              <p className="text-sm font-bold text-blue-500">+{tonRaces24}</p>
            )}
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CombatIcon className="w-[30px] h-[30px] min-w-[30px] text-yellow-500" />
            <p className="font-normal text-base text-yellow-700">NICK Races</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">{racesTotal}</p>
            {!!Number(races24) && <p className="text-sm font-bold text-yellow-500">+{races24}</p>}
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <TonIcon className="w-[30px] h-[30px] min-w-[30px] text-blue-500" />
            <p className="font-normal text-base text-blue-500">TON rewards</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">
              {tonRewardsTotal === '0' ? '0' : toFixedSafe(Number(tonRewardsTotal), 2)}
            </p>
            {!!Number(tonRewards24) && (
              <p className="text-sm font-bold text-blue-500">
                +{toFixedSafe(Number(tonRewards24), 2)}
              </p>
            )}
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CoinIcon className="w-[30px] h-[30px] min-w-[30px] text-yellow-500" />
            <p className="font-normal text-base text-yellow-700">NICKs rewards</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">
              <Numeral value={Number(tokensTotal)} format={'0.0a'} />
            </p>
            {!!Number(tokens24) && (
              <p className="text-sm font-bold text-yellow-500">
                +
                <Numeral value={Number(tokens24)} format={'0.0a'} />
              </p>
            )}
          </div>
        </div>
      </div>
      <div
        className="px-4 py-2.5 bg-purple-900 rounded-medium flex gap-2 justify-between items-center h-[60px] text-white"
        onClick={onGoToGameRulesBtnClick}
      >
        <div className="flex gap-2 items-center">
          <Transition animationType="scaleFromZero">
            <PaperIcon className="w-[26px] h-[26px] min-w-[26px]" />
          </Transition>
          <p className="font-normal text-sm">Game rules</p>
        </div>
        <ArrowRightIcon className="w-[7px] h-[12px] min-w-[7px]" />
      </div>
    </div>
  )
}
