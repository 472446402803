import CouponsStackIconGr from './coupons-stack-gr.svg'
import BorderIcon from './border.svg'
import UserIcon from './user.svg'
import UsersIcon from './users.svg'
import HelmetIcon from './helmet.svg'
import CombatGrIcon from './combat-gr.svg'
import ProfileGrIcon from './profile-gr.svg'
import RewardsIcon from './rewards.svg'
import RouteGrIcon from './route-gr.svg'
import StubPhotoIcon from './stub-photo.svg'
import EnergyIcon from './energy.svg'
import RocketGrIcon from './rocket-gr.svg'
import CouponBigIcon from './coupon-big.svg'
import WheelIcon from './wheel.svg'
import CoinIcon from './coin.svg'
import HandUpIcon from './hand-up.svg'
import HandUpLeftIcon from './hand-up-left.svg'
import CouponWideIcon from './coupon-wide.svg'
import CheckeredFlagOrangeIcon from './checkered-flag-orange.svg'
import CouponSmallGrIcon from './coupon-small-gr.svg'
import CombatIcon from './combat.svg'
import StarIcon from './star.svg'
import CheckeredFlagIcon from './checkered-flag.svg'
import ComingSoonIcon from './coming-soon.svg'
import CheckeredFlagSmallIcon from './checkered-flag-small.svg'
import CopyIcon from './copy.svg'
import CalendarIcon from './calendar.svg'
import ArrowRightIcon from './arrow-right.svg'
import CloseIcon from './close.svg'
import CheckIcon from './check.svg'
import ChatIcon from './chat.svg'
import StarInviteIcon from './star-invite.svg'
import TelegramIcon from './telegram.svg'
import UserInviteIcon from './user-invite.svg'
import CheckCircleIcon from './check-circle.svg'
import CouponBoxIcon from './coupon-box.svg'
import EnergyBottleIcon from './energy-bottle.svg'
import CoinOutlineIcon from './coin-outline.svg'
import CouponsStackIcon from './coupons-stack.svg'
import PaperIcon from './paper.svg'
import CircleDesignedIcon from './circle-designed.svg'
import CircleDesignedCheckeredIcon from './circle-designed-checkered.svg'
import StarburstIcon from './starburst.svg'
import CouponSmallIcon from './coupon-small.svg'
import WheelActiveIcon from './wheel-active.svg'
import Coin1stIcon from './coin-1st.svg'
import Coin2ndIcon from './coin-2nd.svg'
import Coin3rdIcon from './coin-3rd.svg'
import GestureIcon from './gesture.svg'
import WalletIcon from './wallet.svg'
import ExitIcon from './exit.svg'
import TonIcon from './ton.svg'
import TonBlueIcon from './ton-blue.svg'
import InfoIcon from './info.svg'
import HandSelectIcon from './hand-select.svg'
import CouponBlueWideIcon from './coupon-blue-wide.svg'
import CheckeredFlagBlueIcon from './checkered-flag-blue.svg'
import TonOutlineIcon from './ton-outline.svg'
import DangerIcon from './danger.svg'
import RacingFlagIcon from './racing-flag.svg'
import BrokenLinkIcon from './broken-link.svg'

export {
  CouponsStackIconGr,
  BorderIcon,
  UserIcon,
  HelmetIcon,
  CombatGrIcon,
  ProfileGrIcon,
  RewardsIcon,
  RouteGrIcon,
  StubPhotoIcon,
  EnergyIcon,
  RocketGrIcon,
  CouponBigIcon,
  WheelIcon,
  CoinIcon,
  HandUpIcon,
  HandUpLeftIcon,
  CouponWideIcon,
  CheckeredFlagOrangeIcon,
  CouponSmallGrIcon,
  CombatIcon,
  UsersIcon,
  StarIcon,
  CheckeredFlagIcon,
  ComingSoonIcon,
  CheckeredFlagSmallIcon,
  CopyIcon,
  CalendarIcon,
  ArrowRightIcon,
  CloseIcon,
  CheckIcon,
  ChatIcon,
  StarInviteIcon,
  TelegramIcon,
  UserInviteIcon,
  CheckCircleIcon,
  CouponBoxIcon,
  EnergyBottleIcon,
  CoinOutlineIcon,
  CouponsStackIcon,
  PaperIcon,
  CircleDesignedIcon,
  CircleDesignedCheckeredIcon,
  StarburstIcon,
  CouponSmallIcon,
  WheelActiveIcon,
  Coin1stIcon,
  Coin2ndIcon,
  Coin3rdIcon,
  GestureIcon,
  WalletIcon,
  ExitIcon,
  TonIcon,
  TonBlueIcon,
  InfoIcon,
  HandSelectIcon,
  CouponBlueWideIcon,
  CheckeredFlagBlueIcon,
  TonOutlineIcon,
  DangerIcon,
  RacingFlagIcon,
  BrokenLinkIcon,
}
