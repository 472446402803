'use client'

import { FC } from 'react'
import Image from 'next/image'

interface Props {}

export const Podium4thPosition: FC<Props> = () => {
  return (
    <div className="w-full flex items-center justify-between gap-1 text-center">
      <Image src="/illustrations/flag1.webp" priority alt="flag1" width="133" height="180" />
      <p className="text-white text-lg font-bold">Try again</p>
      <Image src="/illustrations/flag2.webp" priority alt="flag2" width="133" height="180" />
    </div>
  )
}
