'use client'

import { FC, useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { selectUser } from '@/redux/features/user'
import { Loader } from '@/components/ui/Loader'

export const InitLoaderComponent: FC = () => {
  const [showLoader, setShowLoader] = useState(true)

  const isLoading = useAppSelector(selectIsLoadingAuth)
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (!isLoading && !!user.tgId && showLoader) {
      setTimeout(() => {
        setShowLoader(false)
      }, 100)
    }
  }, [isLoading, user.tgId, showLoader])

  if (isLoading || !user.tgId || showLoader) return <Loader />

  return null
}
