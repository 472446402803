import { PairType, Player, PlayerResult } from '@/types/player'
import { ethString } from '@/types/general'

export interface GameVote {
  gameID: string
  direction: GameDirection
  price: string
  playerID: string
}

export enum GameDirection {
  UP = 'up',
  DOWN = 'down',
  CLOSE = 'close',
}

export type GameDistance = '10000'

export type EntryPrice = '100'
export type TonEntryPrice = '0.6' | '1' | '5' | '25' | '50'

export interface Game {
  numID: number
  uuid: string
  state: GameState
  players: Player[]
  resultScore: PlayerResult[] | null
  startAt: string | null
  price: EntryPrice | TonEntryPrice
  raceId: string
  distance: GameDistance
  claimSignature: ethString | null
  contractAddress: string
  createdAt: string
  updatedAt: string
}

export enum GameState {
  LobbyWaiting = 'lobby_waiting',
  ReadyToStart = 'ready_to_start',
  InProgress = 'in_progress',
  Finished = 'finished',
  ContractSending = 'contract_sending',
  ContractSent = 'contract_sent',
  Done = 'done',
  Failed = 'failed',
}

export const WAITING_GAME_STATES = [GameState.LobbyWaiting]

export interface CarConfig {
  color: string
  id: number
}

export interface CreateGameRequest {
  pair: PairType
}
