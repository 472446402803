import { FC, memo } from 'react'
import cx from 'classnames'

interface Props {
  percent: number
  className?: string
}

const DirectionIndicatorComponent: FC<Props> = ({ className, percent }) => {
  return (
    <div
      className={cx(
        'w-full h-5 rounded-large border-1 border-midnight-race-500 bg-midnight-race-900 relative overflow-hidden box-border px-0.5 sm:px-1',
        className
      )}
    >
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-1/2 transform -translate-x-1/2 opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[5%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[12%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[20%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[30%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[40%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[60%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[70%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[80%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[88%] opacity-50" />
      <div className="w-[1px] h-full bg-midnight-race-500 z-0 absolute top-0 left-[95%] opacity-50" />
      {percent === 0 && (
        <div className="rounded-full w-4 h-4 bg-violet-race-800 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20" />
      )}
      <div className="w-full relative h-full">
        <div
          className="rounded-r-large bg-gradient-to-r from-green-race-200 to-green-race-600 absolute top-1/2 left-1/2 -translate-y-1/2 z-10 h-3.5 overflow-hidden"
          style={{ width: `${percent > 0 ? percent / 2 : 0}%` }}
        >
          <div className="w-full h-full relative overflow-hidden">
            <div className="w-2 h-2 bg-green-race-200 rounded-full absolute top-1/2 transform -translate-y-1/2 right-1" />
          </div>
        </div>
        <div
          className="rounded-l-large bg-gradient-to-l from-red-race-200 to-red-race-600 absolute top-1/2 right-1/2 -translate-y-1/2 z-10 h-3.5"
          style={{ width: `${percent < 0 ? Math.abs(percent) / 2 : 0}%` }}
        >
          <div className="w-full h-full relative overflow-hidden">
            <div className="w-2 h-2 bg-red-race-200 rounded-full absolute top-1/2 transform -translate-y-1/2 left-1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const DirectionIndicator = memo(DirectionIndicatorComponent)
