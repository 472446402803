'use client'

import { FC } from 'react'
import { NitroLog } from './types'
import { NitroLogItemComponent } from './NitroLogItem.component'
import cx from 'classnames'
import { useViewportHeight } from '@/hooks/useViewportHeight'

interface Props {
  logList: Array<NitroLog>
  isPvpMode: boolean
}

export const NitroLogMobileComponent: FC<Props> = ({ logList = [], isPvpMode }) => {
  const { isViewportNarrow } = useViewportHeight()

  return (
    <div
      className={cx('flex flex-col max-h-12 overflow-hidden', {
        '!max-h-11': isViewportNarrow,
      })}
    >
      {logList.map((log, index) => (
        <NitroLogItemComponent
          key={index}
          data={log}
          isViewportNarrow={isViewportNarrow}
          isPvpMode={isPvpMode}
        />
      ))}
    </div>
  )
}
