'use client'

import { FC, memo } from 'react'
import { PlayerVote, VoteState } from '@/types/player'
import cx from 'classnames'
import { calculateNitroCoefficient } from '@/utils/race'
import CountUp from 'react-countup'
import { RACE_TRACK_CONFIG } from './constant'

interface Props {
  carHeight: number
  bottom: number
  index: number
  isUser?: boolean
  vote?: PlayerVote
  numberPlayers?: number
  name?: string
}

const RaceTrackCarComponent: FC<Props> = ({
  carHeight,
  bottom,
  index,
  isUser,
  vote,
  numberPlayers = 4,
  name,
}) => {
  const isArrowBottomVisible =
    isUser &&
    ((RACE_TRACK_CONFIG.MOBILE.CAR_HEIGHT === carHeight &&
      bottom < RACE_TRACK_CONFIG.MOBILE.START_ARROW_POSITION) ||
      (RACE_TRACK_CONFIG.MOBILE_SMALL.CAR_HEIGHT === carHeight &&
        bottom < RACE_TRACK_CONFIG.MOBILE_SMALL.START_ARROW_POSITION))

  if (isArrowBottomVisible) {
    return (
      <div
        className={`flex items-center justify-center z-20 absolute w-5`}
        style={{
          height: `20px`,
          bottom: `0px`,
          left: `${(index + 1) * (numberPlayers === 2 ? 33 : 20)}%`,
        }}
      >
        <div className="w-full h-full relative">
          <div
            className="absolute left-0 top-0 w-full h-full bg-contain bg-no-repeat bg-center z-10"
            style={{
              backgroundImage: `url('/cars/arrows/${isUser ? 'user' : 'opponent'}.svg')`,
            }}
          />
          {(vote?.state === VoteState.ApplyingDisconfirmed ||
            vote?.state === VoteState.ApplyingConfirmed) && (
            <p
              className={cx(
                'absolute -top-6 left-1/2 transform -translate-x-1/2 z-[12] text-xs font-extrabold',
                {
                  'text-red-race-500': vote?.state === VoteState.ApplyingDisconfirmed,
                  'text-green-race-500': vote?.state === VoteState.ApplyingConfirmed,
                }
              )}
            >
              x
              <CountUp
                start={0}
                decimals={2}
                end={Math.abs(calculateNitroCoefficient(vote?.speedPercentageChange))}
                preserveValue
                duration={1}
              />
            </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={`
      flex items-center justify-center z-20 absolute w-5`}
      style={{
        height: `${carHeight}px`,
        bottom: `${bottom}px`,
        left: `${(index + 1) * (numberPlayers === 2 ? 33 : 20)}%`,
        transition: 'bottom 1000ms linear',
        willChange: 'bottom',
      }}
    >
      <div className="w-full h-full relative">
        <div
          className="absolute left-0 top-0 w-full h-full bg-contain bg-no-repeat bg-center z-10"
          style={{
            backgroundImage: `url('/cars/${isUser ? 'user' : 'opponent'}.webp')`,
          }}
        />
        {name && (
          <p className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 text-xs text-[10px] font-normal text-gray-race-600 whitespace-nowrap">
            {name.length > 8 ? `${name.slice(0, 8)}...` : name}
          </p>
        )}
        <div
          className={cx('absolute h-full bg-contain bg-no-repeat bg-center z-[11]', {
            'left-[2px] -top-[14px] w-[80%]':
              RACE_TRACK_CONFIG.MOBILE_SMALL.CAR_HEIGHT === carHeight,
          })}
          style={{
            backgroundImage: `url('/cars/light/${isUser ? 'user' : 'opponent'}.svg')`,
          }}
        />
        {!isUser &&
          (vote?.state === VoteState.ApplyingDisconfirmed ||
            vote?.state === VoteState.ApplyingConfirmed) && (
            <p
              className={cx(
                'absolute -top-6 left-1/2 transform -translate-x-1/2 z-[12] text-xs font-extrabold',
                {
                  'text-red-race-500': vote?.state === VoteState.ApplyingDisconfirmed,
                  'text-green-race-500': vote?.state === VoteState.ApplyingConfirmed,
                }
              )}
            >
              x
              <CountUp
                start={0}
                decimals={2}
                end={Math.abs(calculateNitroCoefficient(vote?.speedPercentageChange))}
                preserveValue
                duration={1}
              />
            </p>
          )}
        {isUser && (
          <>
            {vote?.state !== VoteState.ApplyingDisconfirmed &&
              vote?.state !== VoteState.ApplyingConfirmed && (
                <div className="absolute bottom-0 left-0 w-full h-full z-[5] bg-turquoise-race-500/[0.3] blur-[8px]" />
              )}
            {!isArrowBottomVisible && (
              <div
                className={cx(
                  "absolute w-[42px] h-[28px] bg-[url('/illustrations/popover.svg')] bg-no-repeat -top-8 left-1/2 transform -translate-x-1/2 z-[12] flex justify-center items-center pb-1.5"
                )}
              >
                <p className="text-white text-[10px] leading-none">
                  {vote?.state === VoteState.ApplyingDisconfirmed ||
                  vote?.state === VoteState.ApplyingConfirmed ? (
                    <span
                      className={cx('font-bold', {
                        'text-red-race-500': vote?.state === VoteState.ApplyingDisconfirmed,
                        'text-green-race-500': vote?.state === VoteState.ApplyingConfirmed,
                      })}
                    >
                      x
                      <CountUp
                        start={0}
                        decimals={2}
                        end={Math.abs(calculateNitroCoefficient(vote?.speedPercentageChange))}
                        preserveValue
                        duration={1}
                      />
                    </span>
                  ) : (
                    'YOU'
                  )}
                </p>
              </div>
            )}
          </>
        )}
        <div
          className={cx(
            'absolute bottom-0 left-0 w-full h-full z-[6] blur-[6px] transition-background duration-1000 ease-in-out',
            {
              'bg-red-race-500': vote?.state == VoteState.ApplyingDisconfirmed,
              'bg-green-race-500': vote?.state == VoteState.ApplyingConfirmed,
            }
          )}
        />
      </div>
    </div>
  )
}

export const RaceTrackCar = memo(RaceTrackCarComponent)
