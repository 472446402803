'use client'

import { FC } from 'react'
import { InfoIcon, TonBlueIcon, TonOutlineIcon } from '@/assets/icons'
import { ModalCustom } from '@/components/ui/ModalCustom'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  tonRefRewardToClaim: number
  onClaimBtnClick: () => void
  isDisabled?: boolean
  isConnected?: boolean
  onConnectBtnClick?: () => void
}

export const ReferralRewardModal: FC<Props> = ({
  isOpen,
  onClose,
  tonRefRewardToClaim,
  onClaimBtnClick,
  isDisabled,
  isConnected,
  onConnectBtnClick,
}) => {
  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={onClose}
      classNameContent="flex flex-col justify-between items-center gap-2 "
      isDark
    >
      <div className="flex flex-col justify-center items-center text-white pt-8 text-center">
        <TonOutlineIcon className="w-10 h-10 mb-5" />
        <p className="text-lg font-normal mb-2">Referral reward</p>
        <p className="text-xs font-normal text-gray-300 max-w-[160px]">
          You can claim referral rewards starting from 1 $TON
        </p>
      </div>
      <div className="w-full relative flex flex-col items-center">
        <div className="flex gap-0.5 items-center mb-2">
          <p className="text-3xl text-[32px] font-bold text-blue-600">
            {!!Number(tonRefRewardToClaim) ? `+${tonRefRewardToClaim}` : 0}
          </p>
          <TonBlueIcon className="w-10 h-10" />
        </div>
        <div className="flex gap-1 items-center mb-3">
          <InfoIcon className="w-[22px] h-[22px] text-gray-300" />
          <p className="text-sm font-normal text-gray-300">- 0.01 $TON tx fee</p>
        </div>
        {!isConnected && (
          <CustomButton variant="secondary" className={`w-full`} onClick={onConnectBtnClick}>
            connect wallet
          </CustomButton>
        )}
        {isConnected && (
          <CustomButton
            variant="secondary"
            className={`w-full`}
            onClick={onClaimBtnClick}
            isDisabledDark={isDisabled}
          >
            claim
          </CustomButton>
        )}
      </div>
    </ModalCustom>
  )
}
