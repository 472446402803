'use client'

import { TonRaceStepWaiting } from '@/app/ton-race-step-waiting/components/TonRaceStepWaiting'
import { useTonProtected } from '@/hooks/useTonProtected'

export default function TonRaceStepWaitingPage() {
  useTonProtected()

  return (
    <div className="layer-gradient layer-gradient-blue">
      <TonRaceStepWaiting />
    </div>
  )
}
