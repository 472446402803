import { useEffect } from 'react'
import { useAppDispatch } from '@/redux/hooks'
import { useTonWallet } from '@tonconnect/ui-react'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useIsValidChainId } from '@/hooks/useIsValidChainId'

export const useTonProtected = () => {
  const dispatch = useAppDispatch()
  const wallet = useTonWallet()

  const { isValidChainId } = useIsValidChainId()

  useEffect(() => {
    if (!wallet?.account || !isValidChainId) {
      dispatch(setRoute(RoutePaths.tonRaceStepRaceType))
    }
  }, [wallet, isValidChainId])
}
