'use client'

import { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  clearRacePreparation,
  setRacePreparationRaceType,
} from '@/redux/features/racePreparationSlice'
import { setIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { TonRaceRaceTypeComponent } from './TonRaceRaceTypeComponent'
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { RaceType } from '@/types/race'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { selectStat } from '@/redux/features/stat'

interface Props {}

export const TonRaceRaceTypeContainer: FC<Props> = ({}) => {
  const dispatch = useAppDispatch()

  const { webApp } = useTelegram()
  const { activeUsers } = useAppSelector(selectStat)

  const wallet = useTonWallet()
  const [tonConnectUI] = useTonConnectUI()

  const handleConnect = async () => {
    await tonConnectUI.openModal()
  }

  const handleClickedNitro = async (raceType: RaceType) => {
    dispatch(setRacePreparationRaceType(raceType))
    dispatch(setRoute(RoutePaths.tonRaceStepChooseEntry))
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  useEffect(() => {
    dispatch(clearRacePreparation())
    dispatch(setIsAllRaceActivitiesCompleted(false))
  }, [])

  return (
    <TonRaceRaceTypeComponent
      usersOnline={activeUsers}
      isConnected={!!wallet?.account}
      onConnectBtnClick={handleConnect}
      onGoBtnClick={handleClickedNitro}
    />
  )
}
