'use client'

import { BrokenLinkIcon, ExitIcon, StubPhotoIcon, WalletIcon } from '@/assets/icons'
import Image from 'next/image'
import { FC, useState } from 'react'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { getTelegramName } from '@/utils/user'
import { useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { transformTextToWithDots } from '@/utils/transformTextToWithDots'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { ModalCustom } from '@/components/ui/ModalCustom'

interface Props {}

export const Header: FC<Props> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { user } = useTelegram()
  const [tonConnectUI] = useTonConnectUI()
  const wallet = useTonWallet()
  const userFriendlyAddress = useTonAddress()

  const handleConnect = async () => {
    await tonConnectUI.openModal()
  }

  const handleDisconnect = async () => {
    await tonConnectUI.disconnect()
    setIsOpenModal(false)
  }

  return (
    <>
      <section className="w-full flex justify-between items-center p-4">
        <div className={'flex gap-2 items-center'}>
          {!!user?.photo_url ? (
            <Image
              src={user.photo_url}
              alt="user photo"
              width={40}
              height={40}
              className={'rounded-full'}
            />
          ) : (
            <StubPhotoIcon className="h-10 w-10" />
          )}
          <p className="text-lg font-normal">{getTelegramName(user)}</p>
        </div>
        {wallet?.account ? (
          <div
            className="flex items-center gap-2 text-gray-700"
            onClick={() => {
              setIsOpenModal(true)
            }}
          >
            <span className="text-sm font-normal">
              {transformTextToWithDots(userFriendlyAddress)}
            </span>
            <ExitIcon className="h-6 w-6" />
          </div>
        ) : (
          <div className="flex items-center gap-2 text-gray-700" onClick={handleConnect}>
            <span className="text-sm font-normal">Connect Wallet</span>
            <WalletIcon className="h-6 w-6" />
          </div>
        )}
      </section>
      <ModalCustom
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false)
        }}
        classNameContent="flex flex-col justify-between items-center gap-2 "
        isDark
      >
        <div className="flex flex-col justify-center items-center text-white pt-8 text-center">
          <BrokenLinkIcon className="w-10 h-10 mb-8" />
          <p className="text-xs font-normal mb-2">Are you sure you want to:</p>
          <p className="text-lg font-normal text-red-500">Disconnect your wallet?</p>
        </div>
        <div className="w-full relative">
          <CustomButton variant="secondaryBordered" onClick={handleDisconnect} className={`w-full`}>
            disconnect
          </CustomButton>
        </div>
      </ModalCustom>
    </>
  )
}
