import { GlobalInfo } from '@/app/info/components/GlobalInfo'
import { Roadmap } from '@/app/info/components/Roadmap'

export default function ProfilePage() {
  return (
    <div className="layer-gradient p-4 pb-20">
      <GlobalInfo />
      <Roadmap />
    </div>
  )
}
