import RacerLBlueIllustration from './racer-l-blue.svg'
import RacerLYellowIllustration from './racer-l-yellow.svg'
import RacerRVioletIllustration from './racer-r-violet.svg'
import RacersRIllustration from './racers-r.svg'
import RobotsRIllustration from './robots-r.svg'
import RacerLBlueSmIllustration from './racer-l-blue-sm.svg'
import RacerLGraySmIllustration from './racer-l-gray-sm.svg'
import RacerLYellowSmIllustration from './racer-l-yellow-sm.svg'
import RacerRGraySmIllustration from './racer-r-gray-sm.svg'
import RacerRGreenSmIllustration from './racer-r-green-sm.svg'
import RacerRRedSmIllustration from './racer-r-red-sm.svg'
import RacerRVioletSmIllustration from './racer-r-violet-sm.svg'
import TonBgIllustration from './ton-bg.svg'
import SuccessTrophyIllustration from './success-trophy.svg'
import SecondaryTrophyIllustration from './secondary-trophy.svg'
import LeftFlagIllustration from './left-flag.svg'
import RightFlagIllustration from './right-flag.svg'

export {
  RacerLBlueIllustration,
  RacerLYellowIllustration,
  RacerRVioletIllustration,
  RacersRIllustration,
  RobotsRIllustration,
  RacerLBlueSmIllustration,
  RacerLGraySmIllustration,
  RacerLYellowSmIllustration,
  RacerRGraySmIllustration,
  RacerRGreenSmIllustration,
  RacerRRedSmIllustration,
  RacerRVioletSmIllustration,
  TonBgIllustration,
  SuccessTrophyIllustration,
  SecondaryTrophyIllustration,
  LeftFlagIllustration,
  RightFlagIllustration,
}
