'use client'

import { FC, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { setRacePreparationRaceType } from '@/redux/features/racePreparationSlice'
import { RaceType } from '@/types/race'
import { selectUser } from '@/redux/features/user'
import { selectTimeDifference } from '@/redux/features/time'
import { useTimer } from 'react-timer-hook'
import { Transition } from '@/components/Transitions'

interface Props {
  className?: string
}

export const StartFreeRaceButton: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch()

  const { webApp } = useTelegram()
  const { nextFreeGameTimeMs } = useAppSelector(selectUser)
  const timeDifference = useAppSelector(selectTimeDifference)

  const handleNextStep = async () => {
    dispatch(setRacePreparationRaceType(RaceType.PlayWithPlatform))
    dispatch(setRoute(RoutePaths.raceStepChooseNitro))
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  const {
    hours,
    seconds,
    minutes,
    isRunning = false,
    restart,
  } = useTimer({
    expiryTimestamp: new Date(),
  })

  useEffect(() => {
    if (timeDifference !== 0 && nextFreeGameTimeMs) {
      restart(new Date(new Date(nextFreeGameTimeMs).getTime() - timeDifference))
    }
  }, [nextFreeGameTimeMs, timeDifference])

  const isExpiredTime = useMemo(() => {
    if (!nextFreeGameTimeMs || !timeDifference) return false

    return new Date(Date.now() + timeDifference) > new Date(nextFreeGameTimeMs)
  }, [nextFreeGameTimeMs, timeDifference])

  return (
    <div className={cx('w-full px-4 fixed left-0 bottom-[92px] z-[1]', className)}>
      <Transition animationType="bottomToTop">
        <CustomButton
          withCheckeredFlag
          onClick={handleNextStep}
          isDisabledDark={isRunning && !isExpiredTime}
        >
          {isRunning && !isExpiredTime && nextFreeGameTimeMs ? (
            <>
              <span>{hours < 10 ? `0${hours}` : hours}</span>
              <span>:</span>
              <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
              <span>:</span>
              <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            </>
          ) : (
            'Start Free Race'
          )}
        </CustomButton>
      </Transition>
    </div>
  )
}
