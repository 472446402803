'use client'

import { FC } from 'react'
import { TonOutlineIcon } from '@/assets/icons'
import { useIsValidChainId } from '@/hooks/useIsValidChainId'
import { useTonWallet } from '@tonconnect/ui-react'

export const TonRaceWrongNetwork: FC = () => {
  const { isValidChainId } = useIsValidChainId()
  const wallet = useTonWallet()

  if (isValidChainId || !wallet?.account) return null

  return (
    <div className="w-full layer-default layer-gradient-blue z-[199] absolute bottom-0 left-0 flex flex-col items-center justify-center gap-4 text-white bg-dark-bg-radial-gradient p-4 text-center">
      <p className="text-lg font-bold">Wrong network!</p>
      <TonOutlineIcon className="w-11 h-11" />
      <p className="text-xs font-normal text-gray-300">
        Please connect with <br />
        <span className="text-green-500">TON Mainnet</span> wallet
      </p>
    </div>
  )
}
