import { TonRaceEntryList } from './components/TonRaceEntryList'
import { useTonProtected } from '@/hooks/useTonProtected'

export default function TonRaceStepChooseEntry() {
  useTonProtected()

  return (
    <div className="layer-gradient layer-gradient-blue pb-14">
      <TonRaceEntryList />
    </div>
  )
}
