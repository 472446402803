import { GameDirection, TonEntryPrice } from '@/types/game'
import { ethString } from '@/types/general'
import { MOON_RACE_ADDRESS } from '@/constants/addresses'
import { formatterCurrency } from '@/utils/formatValue'
import { subtractPercentageFromStringNumber } from '@/utils/mathUtils'
import { PRICE_DIVIDER, PRICE_FEE, PRICE_FEE_NPC, TON_RACE_STATUSES } from '@/constants/race'
import { UserRaceStatus, WaitingForTonGameData } from '@/types/user'
import { RaceType } from '@/types/race'

export const calculatePriceChange = (
  currentPrice: string | null | undefined,
  positionOpenedAtPrice: string | null | undefined
): number => {
  if (!currentPrice || !positionOpenedAtPrice) {
    return 0
  }

  const numCurrentPrice = parseFloat(currentPrice)
  const numPositionOpenedAtPrice = parseFloat(positionOpenedAtPrice)

  if (isNaN(numCurrentPrice) || isNaN(numPositionOpenedAtPrice)) {
    return 0
  }

  return (numCurrentPrice - numPositionOpenedAtPrice) * 1000
}

export const calculateNitroCoefficient = (
  speedPercentageChange: string | null | undefined | number
) => {
  if (!speedPercentageChange) {
    return 0
  }

  const transformedPositionOpenedAtPrice =
    typeof speedPercentageChange === 'string'
      ? parseFloat(speedPercentageChange)
      : speedPercentageChange

  const numPositionOpenedAtPrice = transformedPositionOpenedAtPrice * 100

  if (numPositionOpenedAtPrice >= 10) {
    return 10
  }

  if (numPositionOpenedAtPrice <= -10) {
    return -10
  }

  return numPositionOpenedAtPrice
}

export const formatTimeTaken = (timeTaken: string | null | undefined): string => {
  if (!timeTaken) {
    return '0'
  }

  const parts = timeTaken.match(/(\d+)m(\d+\.\d+)s/)

  if (!parts) {
    return '0'
  }

  const minutes = parts[1]
  const seconds = parseFloat(parts[2]).toFixed(4)

  return `${minutes}m ${seconds}s`
}

export const calculateSpeedPercentageChange = (
  currentPrice: string | number | undefined,
  openedAtPrice: string | number | undefined,
  predictionLevel: GameDirection | undefined
): number => {
  if (!currentPrice || !openedAtPrice || !predictionLevel) {
    return 0
  }

  const transformedCurrentPrice =
    typeof currentPrice === 'string' ? parseFloat(currentPrice) : currentPrice
  const transformedOpenedAtPrice =
    typeof openedAtPrice === 'string' ? parseFloat(openedAtPrice) : openedAtPrice

  const speedPercentageChange =
    (Math.abs(transformedCurrentPrice - transformedOpenedAtPrice) / transformedOpenedAtPrice) * 100

  // If the prediction is UP, then the speed percentage change is positive if the current price is higher than the opened at price
  if (predictionLevel === GameDirection.UP) {
    if (currentPrice > openedAtPrice) {
      return speedPercentageChange
    } else {
      return -speedPercentageChange
    }
  }

  // If the prediction is DOWN, then the speed percentage change is positive if the current price is lower than the opened at price
  if (currentPrice < openedAtPrice) {
    return speedPercentageChange
  } else {
    return -speedPercentageChange
  }
}

export const isNPCOpponent = (
  opponentAddress: ethString | string | null | undefined = ''
): boolean => {
  return !!opponentAddress && opponentAddress.toLowerCase() === MOON_RACE_ADDRESS.toLowerCase()
}

export const getWinPrice = (price: number, isNpcOpponent: boolean): string => {
  return formatterCurrency.format(
    subtractPercentageFromStringNumber(price * 2, isNpcOpponent ? PRICE_FEE_NPC : PRICE_FEE)
  )
}

export const getCurrentRaceType = (
  waitingForTonGameData: WaitingForTonGameData,
  raceStatus: UserRaceStatus | string
): RaceType => {
  if (!waitingForTonGameData?.limit && !TON_RACE_STATUSES.includes(raceStatus as UserRaceStatus)) {
    return RaceType.PlayWithPlatform
  }

  if (waitingForTonGameData.limit === 2) {
    return RaceType.OneVsOne
  }

  return RaceType.OneVsThree
}

export const isPvp = (
  waitingForTonGameData: WaitingForTonGameData,
  raceStatus: UserRaceStatus | string
): boolean => {
  const raceType = getCurrentRaceType(waitingForTonGameData, raceStatus)

  return raceType !== RaceType.PlayWithPlatform
}

export const normalizeTonEntryPrice = (entryPrice: string | null | undefined): TonEntryPrice => {
  if (!entryPrice) {
    return '0.6'
  }

  return ((Number(entryPrice) / 1e9) * PRICE_DIVIDER).toString() as TonEntryPrice
}
