'use client'

import { FC } from 'react'
import { DailyRewardsCard } from './DailyRewards'
import { ReferralRewardCard } from '@/app/rewards/components/ReferralReward'

interface Props {}

export const RewardsSection: FC<Props> = () => {
  return (
    <div className="flex flex-col items-center justify-center p-4 pb-8 gap-2">
      <DailyRewardsCard />
      <ReferralRewardCard />
    </div>
  )
}
