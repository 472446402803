'use client'

import { FC } from 'react'
import { ArrowRightIcon, CheckIcon, CoinIcon, TonBlueIcon, TonIcon } from '@/assets/icons'
import cx from 'classnames'

interface Props {
  isDone?: boolean
  children: React.ReactNode
  tokens?: number | string
  onClick?: () => void
  withCheckmark?: boolean
  isTonTheme?: boolean
}

export const CardLayout: FC<Props> = ({
  isDone,
  tokens,
  children,
  onClick,
  withCheckmark,
  isTonTheme,
}) => {
  const isVisibleCheckmark = isDone && withCheckmark

  return (
    <div
      className={cx(
        'w-full flex justify-between items-center px-4 py-3 rounded-medium bg-purple-900 text-white',
        {
          '!text-gray-300 !bg-purple-800': isDone,
        }
      )}
      onClick={() => onClick && onClick()}
    >
      <div className="flex gap-2 items-center">{children}</div>
      <div
        className={cx('flex items-center gap-1', {
          'text-yellow-500': !isTonTheme,
          'text-blue-600': isTonTheme,
          '!text-gray-300': isDone,
        })}
      >
        <p className="text-lg font-bold">{tokens ? `+${tokens}` : 0}</p>

        {!isTonTheme && <CoinIcon className="w-[30px] h-[30px] min-w-[30px]" />}
        {isTonTheme && !isDone && <TonBlueIcon className="w-[30px] h-[30px] min-w-[30px]" />}
        {isTonTheme && isDone && <TonIcon className="w-[30px] h-[30px] min-w-[30px]" />}
        {!isVisibleCheckmark && <ArrowRightIcon className="w-[12px] h-[12px] min-w-[12px]" />}
        {isVisibleCheckmark && <CheckIcon className="w-[12px] h-[12px] min-w-[12px]" />}
      </div>
    </div>
  )
}
