'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { NitroLog } from './types'
import { useAppSelector } from '@/redux/hooks'
import { selectWsCalculationUpdates } from '@/redux/features/wsDataSlice'
import { calculateNitroCoefficient, isPvp } from '@/utils/race'
import { VoteState } from '@/types/player'
import { NitroLogMobileComponent } from './NitroLogMobile.component'
import { selectTimeDifference } from '@/redux/features/time'
import { selectUser } from '@/redux/features/user'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { getTelegramName } from '@/utils/user'

export const NitroLogContainer: FC = () => {
  const wsCalculationUpdates = useAppSelector(selectWsCalculationUpdates)
  const timeDifference = useAppSelector(selectTimeDifference)
  const { tgId, waitingForTonGameData, raceStatus } = useAppSelector(selectUser)
  const { user } = useTelegram()

  const [playersKeys, setPlayersKeys] = useState<string[][]>([])
  const [logList, setLogList] = useState<NitroLog[]>([])

  const isPvpMode = useMemo(() => {
    return isPvp(waitingForTonGameData, raceStatus)
  }, [waitingForTonGameData, raceStatus])

  useEffect(() => {
    wsCalculationUpdates?.forEach((game, index) => {
      const key = `${game.id}-${game.pair}-${game?.vote?.state}-${game?.vote?.openedAtPrice}-${game?.vote?.predictionLevel}`
      const flatPlayersKeys = playersKeys.flat()

      if (game?.vote && game?.vote?.state === VoteState.Opened && !flatPlayersKeys.includes(key)) {
        const updatedPlayersKeys = [...playersKeys]
        if (!updatedPlayersKeys[index]) {
          updatedPlayersKeys[index] = ['', '']
        }
        updatedPlayersKeys[index][0] = key
        updatedPlayersKeys[index][1] = ''
        setPlayersKeys(updatedPlayersKeys)

        setLogList((prev) => [
          {
            name: game.name,
            pair: game.pair,
            predictionLevel: game?.vote?.predictionLevel,
            speedPercentageChange: calculateNitroCoefficient(game?.vote?.speedPercentageChange),
            state: game?.vote?.state,
            openedAtPrice: game?.vote?.openedAtPrice,
            closePriceLevel: game?.vote?.closePriceLevel,
            closePrice: game?.vote?.closePrice,
            isUser: game?.id?.toLowerCase() === tgId?.toLowerCase(),
            username:
              game?.id?.toLowerCase() === tgId?.toLowerCase() ? getTelegramName(user) : null,
          },
          ...prev,
        ])
      }

      if (
        game?.vote &&
        (game?.vote?.state === VoteState.ApplyingConfirmed ||
          game?.vote?.state === VoteState.ApplyingDisconfirmed) &&
        new Date(game?.vote?.adjustmentEndAt) > new Date(Date.now() + timeDifference) &&
        !flatPlayersKeys.includes(key)
      ) {
        const updatedPlayersKeys = [...playersKeys]
        if (!updatedPlayersKeys[index]) {
          updatedPlayersKeys[index] = ['', '']
        }
        updatedPlayersKeys[index][0] = ''
        updatedPlayersKeys[index][1] = key
        setPlayersKeys(updatedPlayersKeys)

        setLogList((prev) => [
          {
            name: game.name,
            pair: game.pair,
            speedPercentageChange: calculateNitroCoefficient(game?.vote?.speedPercentageChange),
            predictionLevel: game?.vote?.predictionLevel,
            closePriceLevel: game?.vote?.closePriceLevel,
            state: game?.vote?.state,
            openedAtPrice: game?.vote?.openedAtPrice,
            closePrice: game?.vote?.closePrice,
            isUser: game?.id?.toLowerCase() === tgId?.toLowerCase(),
            username:
              game?.id?.toLowerCase() === tgId?.toLowerCase() ? getTelegramName(user) : null,
          },
          ...prev,
        ])
      }
    })
  }, [wsCalculationUpdates, timeDifference])

  return <NitroLogMobileComponent logList={logList} isPvpMode={isPvpMode} />
}
