'use client'

import { FC } from 'react'
import { StarburstIcon, TonIcon, TonOutlineIcon } from '@/assets/icons'
import { UserTonLastRaceData } from '@/types/user'
import { formatDistanceToNow } from 'date-fns'
import { normalizeTonEntryPrice } from '@/utils/race'
import { toFixedSafe } from '@/utils/formatValue'

interface Props {
  data: UserTonLastRaceData
}

export const TonLastRacesItem: FC<Props> = ({ data }) => {
  const { createdAt, userWon, usersLength, price, userPlace } = data

  const date = new Date(parseInt(createdAt))

  return (
    <div className="w-full flex items-center justify-between gap-2">
      <div className="w-[30px] min-w-[30px] h-[30px] rounded-full bg-blue-900 flex relative justify-center items-center">
        <p className="text-white text-xs font-bold text-center">{userPlace ? userPlace : 4}</p>
        {userPlace && (
          <StarburstIcon className="absolute left-0 top-0 w-full h-full z-[1] text-white" />
        )}
      </div>
      <p className="min-w-8 text-white text-sm font-normal">
        {usersLength === 2 ? '1vs1' : '1vs3'}
      </p>
      <div className="min-w-16 text-gray-300 text-xs font-normal flex items-center gap-1">
        <TonOutlineIcon className="w-[14px] h-[14px] min-w-[14px]" />
        <span>{normalizeTonEntryPrice(price)} TON</span>
      </div>
      <p className="text-sm font-normal text-gray-300 min-w-20">
        {formatDistanceToNow(date, { addSuffix: true }).toString().replace('about ', '')}
      </p>
      <div className="flex items-center justify-end gap-0.5 text-blue-600 min-w-20">
        <p className="text-lg font-bold">
          {!!Number(userWon) ? `+${toFixedSafe(Number(userWon), 2)}` : 0}
        </p>
        <TonIcon className="w-[30px] min-w-[30px] h-[30px]" />
      </div>
    </div>
  )
}
