'use client'

import { RoadmapItem } from './RoadmapItem'
import { Transition } from '@/components/Transitions'

export const Roadmap = () => {
  return (
    <div className="flex flex-col gap-4 mt-6">
      <Transition animationType="leftToRight">
        <p className="text-lg font-medium text-white text-center w-full">Roadmap:</p>
      </Transition>
      <div className="flex flex-col w-full">
        <RoadmapItem title={'Nitro.taxi web3 application'} isCompleted />
        <RoadmapItem title={'Click Nitro Taxi Telegram mini app'} isCompleted />
        <RoadmapItem title={'Boosters'} isCompleted />
        <RoadmapItem title={'Tasks update'} />
        <RoadmapItem title={'PvP mode'} />
        <RoadmapItem title={'Championship mode'} />
        <RoadmapItem title={'Garage shop'} />
        <RoadmapItem title={'Token airdrop'} isMarked />
      </div>
    </div>
  )
}
