'use client'

import { FC } from 'react'
import cx from 'classnames'
import { useTimer } from 'react-timer-hook'

interface Props {
  startAt: string
  timeDifference: number
  className?: string
  maxDistance: number
}

export const RaceStartTimerComponent: FC<Props> = ({
  startAt,
  className,
  timeDifference,
  maxDistance,
}) => {
  const { totalSeconds = 5, isRunning = true } = useTimer({
    expiryTimestamp: new Date(new Date(startAt).getTime() - timeDifference),
  })

  if (!isRunning && maxDistance === 0) {
    return (
      <div
        className={cx(
          'absolute rounded-full z-30 min-w-[280px] w-[280x] h-[280px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ',
          className
        )}
      >
        <div className="relative w-full h-full flex justify-center items-center">
          <div className="absolute w-full h-full left-0 top-0 rounded-full z-[1]  bg-midnight-race-900/[0.9] blur-[25px]" />
          <p className={'text-8xl font-extrabold text-yellow-race-200 relative z-[2]'}>GO!!!</p>
        </div>
      </div>
    )
  }

  if (!isRunning) {
    return null
  }

  return (
    <div
      className={cx(
        'absolute rounded-full z-30 min-w-[280px] w-[280x] h-[280px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ',
        className
      )}
    >
      <div className="relative w-full h-full flex justify-center items-center">
        <div className="absolute w-full h-full left-0 top-0 rounded-full z-[1]  bg-midnight-race-900/[0.9] blur-[25px]" />
        <p className={'text-8xl font-extrabold text-yellow-race-200 relative z-[2]'}>
          {totalSeconds === 4 && '3'}
          {totalSeconds === 3 && '2'}
          {totalSeconds === 2 && '1'}
          {totalSeconds === 1 && 'GO!!!'}
        </p>
      </div>
    </div>
  )
}
