'use client'

import { useAppSelector } from '@/redux/hooks'
import { selectRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import BoostersPage from '@/app/boosters'
import { ComponentType } from 'react'
import ProfilePage from '@/app/profile'
import RaceStepSelectPage from '@/app/race-step-select'
import RaceStepChooseNitroPage from '@/app/race-step-choose-nitro'
import RacePage from '@/app/race'
import RacePodiumPage from '@/app/race-podium'
import RewardsPage from '@/app/rewards'
import InfoPage from '@/app/info'
import { Header } from '@/components/Header'
import { BottomTabMenu } from '@/components/BottomTabMenu'
import { InitLoader } from '@/components/ui/InitLoader'
import GameRulesPage from '@/app/game-rules'
import TonRaceStepSelectPage from '@/app/ton-race-step-select'
import TonRaceStepChooseEntry from '@/app/ton-race-step-choose-entry'
import TonRaceStepChooseNitroPage from '@/app/ton-race-step-choose-nitro'
import TonRaceStepWaitingPage from '@/app/ton-race-step-waiting'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { selectUser } from '@/redux/features/user'

const routeComponents: Partial<Record<RoutePaths, ComponentType>> = {
  [RoutePaths.boosters]: BoostersPage,
  [RoutePaths.profile]: ProfilePage,
  [RoutePaths.raceStepSelect]: RaceStepSelectPage,
  [RoutePaths.raceStepChooseNitro]: RaceStepChooseNitroPage,
  [RoutePaths.racePodium]: RacePodiumPage,
  [RoutePaths.rewards]: RewardsPage,
  [RoutePaths.info]: InfoPage,
  [RoutePaths.gameRules]: GameRulesPage,
  [RoutePaths.race]: RacePage,
  [RoutePaths.tonRaceStepRaceType]: TonRaceStepSelectPage,
  [RoutePaths.tonRaceStepChooseEntry]: TonRaceStepChooseEntry,
  [RoutePaths.tonRaceStepChooseNitro]: TonRaceStepChooseNitroPage,
  [RoutePaths.tonRaceStepWaiting]: TonRaceStepWaitingPage,
  [RoutePaths.main]: TonRaceStepSelectPage,
}

const invisibleHeaderRoutes = [RoutePaths.race]
const invisibleBottomTabMenuRoutes = [
  RoutePaths.racePodium,
  RoutePaths.race,
  RoutePaths.tonRaceStepWaiting,
]

export default function TelegramRouter() {
  const route = useAppSelector(selectRoute)
  const isLoading = useAppSelector(selectIsLoadingAuth)
  const user = useAppSelector(selectUser)

  const shouldShowContent = !isLoading || !!user.tgId

  const Component = routeComponents[route]

  return (
    <>
      <InitLoader />
      {shouldShowContent && (
        <>
          {!invisibleHeaderRoutes.includes(route) && <Header />}
          {Component ? <Component /> : <div>404 Not Found</div>}
          {!invisibleBottomTabMenuRoutes.includes(route) && <BottomTabMenu />}
        </>
      )}
      {/*{!isValidPlatform && <QrCode />}*/}
    </>
  )
}
