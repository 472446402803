'use client'

import { FC, useEffect } from 'react'
import { AnimatedDots } from '@/components/ui/AnimatedDots'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'

export const LoaderComponent: FC = () => {
  const [lockScroll, unlockScroll] = useLockBodyScroll()

  useEffect(() => {
    lockScroll()
  }, [])

  useEffect(() => {
    return () => {
      unlockScroll()
    }
  }, [])

  return (
    <div className="fixed left-0 bottom-0 w-full h-[100vh] z-[1000] flex justify-center items-center bg-purple-radial-gradient">
      <p className="text-xl font-extrabold text-yellow-500">
        Loading <AnimatedDots />
      </p>
    </div>
  )
}
