'use client'

import { CoinIcon, CombatIcon, TonBlueIcon, TonIcon } from '@/assets/icons'
import { FC } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { Transition } from '@/components/Transitions'
import { toFixedSafe } from '@/utils/formatValue'
import { useQuery_GetTonExtendedAddressInformation_TonCenter } from '@/services/useApi/tonCenter/useQuery'

interface Props {}

export const ProfileInfo: FC<Props> = () => {
  const { tokens, totalRaces, racesLastDay, userTonRaceData, tokensEarned, tokensEarned24 } =
    useAppSelector(selectUser)

  const { data } = useQuery_GetTonExtendedAddressInformation_TonCenter()

  return (
    <div className="flex flex-col px-4 pt-4 pb-7">
      <Transition animationType="leftToRight">
        <p className="text-gray-300 text-sm font-normal mb-2.5 w-full text-center">Your balance:</p>
      </Transition>
      <div className="flex items-start justify-between w-full -mt-7 mb-6">
        <div className="w-full flex flex-col items-center justify-center gap-2 text-blue-600">
          <Transition animationType="scaleFromZero">
            <TonBlueIcon className="min-w-[56px] w-[56px] h-[56px] " />
          </Transition>
          <p className="text-4xl text-[40px] font-semibold">
            {toFixedSafe(Number(data?.balance) || 0, 2)}
          </p>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-2 text-yellow-500">
          <Transition animationType="scaleFromZero">
            <CoinIcon className="min-w-[56px] w-[56px] h-[56px] " />
          </Transition>
          <p className="text-4xl text-[40px] font-semibold">{toFixedSafe(tokens, 2)}</p>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-2">
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CombatIcon className="w-[30px] h-[30px] min-w-[30px] text-blue-500" />
            <p className="font-normal text-base text-blue-500">TON Races</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">
              {userTonRaceData.totalRaces}
            </p>
            <p className="text-sm font-bold text-blue-500">
              {!!Number(userTonRaceData.totalRaces24) &&
                ` +${toFixedSafe(Number(userTonRaceData.totalRaces24), 2)}`}
            </p>
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CombatIcon className="w-[30px] h-[30px] min-w-[30px] text-yellow-500" />
            <p className="font-normal text-base text-yellow-700">NICK Races</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">{totalRaces}</p>
            <p className="text-sm font-bold text-yellow-500">
              {!!Number(racesLastDay) && ` +${toFixedSafe(Number(racesLastDay), 2)}`}
            </p>
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <TonIcon className="w-[30px] h-[30px] min-w-[30px] text-blue-500" />
            <p className="font-normal text-base text-blue-500">TON Rewards</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">
              {toFixedSafe(Number(userTonRaceData.totalWon), 2)}
            </p>
            <p className="text-sm font-bold text-blue-500">
              {!!Number(userTonRaceData.totalWon24) &&
                ` +${toFixedSafe(Number(userTonRaceData.totalWon24), 2)}`}
            </p>
          </div>
        </div>
        <div className="px-4 py-2.5 bg-purple-900 rounded-medium flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <CoinIcon className="w-[30px] h-[30px] min-w-[30px] text-yellow-500" />
            <p className="font-normal text-base text-yellow-700">NICK Rewards</p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-lg font-bold text-white leading-none">
              {toFixedSafe(Number(tokensEarned), 2)}
            </p>
            <p className="text-sm font-bold text-yellow-500">
              {!!Number(tokensEarned24) && ` +${toFixedSafe(Number(tokensEarned24), 2)}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
