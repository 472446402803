import { FC, ReactNode } from 'react'
import cx from 'classnames'
import { Button } from '@nextui-org/react'

interface Props {
  className?: string
  children?: ReactNode
  onClick?: () => void
  color?: 'green' | 'red'
  isDisabled?: boolean
  isLoading?: boolean
}

export const DirectionButton: FC<Props> = ({
  className,
  children,
  onClick,
  color = 'green',
  isDisabled = false,
  isLoading = false,
}) => {
  return (
    <Button
      className={cx(
        'w-full h-10 text-sm rounded-medium box-border border-1 border-b-0',
        className,
        {
          'bg-green-race-500 border-green-race-300': color === 'green',
          'opacity-1 bg-green-race-800': color === 'green' && (isDisabled || isLoading),
          'bg-red-race-500 border-red-race-300': color === 'red',
          'opacity-1 bg-red-race-800': color === 'red' && (isDisabled || isLoading),
        }
      )}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {children}
    </Button>
  )
}
