'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { GameDirection } from '@/types/game'
import { useTimer } from 'react-timer-hook'
import { DirectionIndicator } from '@/components/ui/DirectionIndicator'
import { DirectionButton } from '@/components/ui/Buttons/DirectionButton'
import CountUp from 'react-countup'
import { LabelLightTop } from '@/components/Labels/LabelLightTop'
import Image from 'next/image'

interface Props {
  isLoading: boolean
  onUpButtonClick: () => void
  onDownButtonClick: () => void
  onCloseButtonClick: () => void
  vote?: GameDirection
  userBetPercent?: number | null
  isStartRace: boolean
  isDisabled?: boolean
  adjustmentEndAt?: string | null
  speed: number
  timeDifference: number
  roundTripTime: number
}

export const DirectionPanelComponent: FC<Props> = ({
  onUpButtonClick,
  onDownButtonClick,
  isLoading,
  onCloseButtonClick,
  vote = GameDirection.CLOSE,
  userBetPercent = 0,
  isStartRace = false,
  isDisabled,
  adjustmentEndAt,
  speed,
  timeDifference,
  roundTripTime,
}) => {
  const percent = !!userBetPercent ? userBetPercent * 10 : 0

  const [isStartWithDelay, setIsStartWithDelay] = useState(true)

  const {
    isRunning: isRunningSpeedAdjustmentEnd,
    restart,
    totalSeconds,
  } = useTimer({
    expiryTimestamp: new Date(),
  })

  useEffect(() => {
    if (isStartRace) {
      setTimeout(() => {
        setIsStartWithDelay(false)
      }, 0)
    }
  }, [isStartRace])

  useEffect(() => {
    if (
      !!adjustmentEndAt &&
      !isRunningSpeedAdjustmentEnd &&
      new Date(adjustmentEndAt) > new Date(Date.now() + timeDifference)
    ) {
      restart(new Date(new Date(adjustmentEndAt).getTime() - timeDifference + roundTripTime + 500))
    }
  }, [isRunningSpeedAdjustmentEnd, adjustmentEndAt, timeDifference, roundTripTime])

  const isDisabledActionButtons = useMemo(() => {
    return (
      isLoading ||
      !isStartRace ||
      isDisabled ||
      isRunningSpeedAdjustmentEnd ||
      isStartWithDelay ||
      vote === GameDirection.DOWN ||
      vote === GameDirection.UP
    )
  }, [isLoading, isStartRace, isDisabled, isRunningSpeedAdjustmentEnd, isStartWithDelay, vote])

  const isDisabledCloseButton = useMemo(() => {
    return (
      isLoading ||
      !isStartRace ||
      isDisabled ||
      isRunningSpeedAdjustmentEnd ||
      vote === GameDirection.CLOSE
    )
  }, [isLoading, isStartRace, isDisabled, isRunningSpeedAdjustmentEnd, vote])

  const nitroCoefficient = useMemo(() => {
    const value = Number(userBetPercent || 0)

    if (value === 0) {
      return '0.00'
    }

    if (value >= 10 || value <= -10) {
      return '10.0'
    }

    if (value !== 0 && value < 10) {
      return Math.abs(value).toFixed(2)
    }

    return '0.00'
  }, [userBetPercent])

  return (
    <div className="w-full px-4 pb-5 overflow-hidden">
      <div className="w-full flex justify-center">
        <LabelLightTop
          color={
            vote === GameDirection.CLOSE ? 'default' : vote === GameDirection.DOWN ? 'red' : 'green'
          }
          className="max-w-[90px] w-full"
        >
          <p className="min-w-[52px] text-center leading-none">
            {vote === GameDirection.CLOSE && <span>Non</span>}
            {vote === GameDirection.UP && <span>Long</span>}
            {vote === GameDirection.DOWN && <span>Short</span>}
          </p>
          <p className="text-xs text-gray-race-800 font-normal absolute -left-11 top-1/2 transform -translate-y-1/2">
            Position
          </p>
        </LabelLightTop>
        <LabelLightTop
          color={
            Number(userBetPercent || 0) === 0
              ? 'default'
              : Number(userBetPercent || 0) < 0
                ? 'red'
                : 'green'
          }
          className="max-w-[100px] w-full flex justify-center"
        >
          <p className="text-xl w-[64px] text-left relative z-2 leading-none">
            <span>x</span>
            <span>{nitroCoefficient}</span>
          </p>
          <div className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-1 w-[151px] h-[30px]">
            <Image src="/icons/nitro.svg" alt="nitro" width={151} height={30} />
          </div>
        </LabelLightTop>
        <LabelLightTop color={speed > 0 ? 'yellow' : 'default'} className="max-w-[90px] w-full">
          <p className="min-w-[36px] text-center leading-none">
            <CountUp end={speed} preserveValue duration={1} />
          </p>
          <p className="text-xs text-gray-race-800 font-normal absolute -right-[84px] top-1/2 transform -translate-y-1/2 w-[80px]">
            Speed, km/h
          </p>
        </LabelLightTop>
      </div>
      <DirectionIndicator percent={percent} className="mt-1" />
      <div className="flex mt-2 gap-2">
        {vote === GameDirection.CLOSE && (
          <>
            <DirectionButton
              color={'red'}
              onClick={onDownButtonClick}
              isDisabled={isDisabledActionButtons}
            >
              SHORT {isRunningSpeedAdjustmentEnd && `(${totalSeconds})`}
            </DirectionButton>
            <DirectionButton onClick={onUpButtonClick} isDisabled={isDisabledActionButtons}>
              LONG {isRunningSpeedAdjustmentEnd && `(${totalSeconds})`}
            </DirectionButton>
          </>
        )}
        {vote !== GameDirection.CLOSE && (
          <>
            <DirectionButton
              color={
                Number(userBetPercent || 0) === 0
                  ? 'red'
                  : Number(userBetPercent || 0) < 0
                    ? 'red'
                    : 'green'
              }
              onClick={onCloseButtonClick}
              isDisabled={isDisabledCloseButton}
              isLoading={isLoading}
            >
              CLOSE {isRunningSpeedAdjustmentEnd && `(${totalSeconds})`}
            </DirectionButton>
          </>
        )}
      </div>
    </div>
  )
}
