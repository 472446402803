import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Game } from '@/types/game'
import { useAppSelector } from '@/redux/hooks'
import { selectIsLoggedIn } from '@/redux/features/authSlice'
import {
  selectQueryOptionsActiveGamesReFetchInterval,
  selectQueryOptionsActiveGamesTriggerReFetch,
} from '@/redux/features/queryOptionsSlice'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { getGameById } from '@/services/api/game'
import { selectUser } from '@/redux/features/user'
import { userMapper } from '@/services/mappers/userMapper'

export const useQuery_GetGameById_Game = (options?: UseQueryOptions<Game>) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const queryOptionsActiveGamesTriggerReFetch = useAppSelector(
    selectQueryOptionsActiveGamesTriggerReFetch
  )
  const queryOptionsActiveGamesReFetchInterval = useAppSelector(
    selectQueryOptionsActiveGamesReFetchInterval
  )

  const { raceId } = useAppSelector(selectUser)

  return useQuery<Game>({
    queryKey: [CACHE_KEYS.game, isLoggedIn, raceId, queryOptionsActiveGamesTriggerReFetch],
    queryFn: async () => {
      return await getGameById(raceId)
    },
    refetchInterval: queryOptionsActiveGamesReFetchInterval,
    enabled: isLoggedIn && !!raceId,
    select: (data) => userMapper.sortPlayersInGameById(data),
    ...options,
  })
}
