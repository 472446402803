'use client'

import { FC } from 'react'
import Image from 'next/image'
import { TonOutlineIcon } from '@/assets/icons'
import { LeftFlagIllustration, RightFlagIllustration } from '@/assets/illustrations'

interface Props {
  text: string
}

export const TonPodiumFlags: FC<Props> = ({ text }) => {
  return (
    <>
      <div className="flex flex-col gap-4 text-white items-center -mb-20">
        <p
          className="text-lg font-bold text-center w-full"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <TonOutlineIcon className="w-11 h-11" />
      </div>
      <div className="w-full flex items-start justify-between gap-1 text-center relative z-10">
        <LeftFlagIllustration className="w-[145px] h-[203px]" />
        <RightFlagIllustration className="w-[145px] h-[203px]" />
      </div>
    </>
  )
}
