'use client'

import { FC } from 'react'
import { ModalCustom } from '@/components/ui/ModalCustom'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { CANCELLATION_FEE } from '@/constants/race'
import { RacingFlagIcon } from '@/assets/icons'

interface Props {
  isOpen: number | boolean
  onClose: (value: number | boolean) => void
  onConfirm: () => void
  isLoading: boolean
}

export const TopRaceStepWaitingCancelModal: FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  onConfirm,
}) => {
  return (
    <ModalCustom
      isOpen={!!isOpen}
      onClose={() => {
        if (isLoading) return

        onClose(false)
      }}
      classNameContent="flex flex-col justify-between items-center gap-2 "
      isDark
    >
      <div className="flex flex-col justify-center items-center gap-5 text-white pt-8">
        <RacingFlagIcon className="w-12 h-12" />
        <p className="text-lg font-normal">Please confirm the entry cancellation</p>
      </div>
      <div className="w-full relative">
        <p className="w-full text-center mb-4 text-gray-300 text-xs font-normal">
          Cancellation fee {CANCELLATION_FEE} $TON
        </p>
        <CustomButton
          variant="secondaryBordered"
          isLoading={isLoading}
          onClick={onConfirm}
          className={`w-full`}
        >
          confirm
        </CustomButton>
      </div>
    </ModalCustom>
  )
}
