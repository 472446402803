import { baseApiAxiosInstance, raceApiAxiosInstance } from '@/services/api/api'
import { Game, GameVote, CreateGameRequest } from '@/types/game'

export const submitGameVote = async (data: GameVote) => {
  const response = await raceApiAxiosInstance.post('/game/vote', data)
  return response.data
}

export const getGameById = async (numID: string): Promise<Game> => {
  const response = await baseApiAxiosInstance.get(`/race/${numID}`)
  return response.data
}

export const createNewGame = async (data: CreateGameRequest): Promise<Game> => {
  const response = await baseApiAxiosInstance.post('/race/new', data)
  return response.data
}
