export const formatRacePosition = (idx: number) => {
  if (-1 === idx) return 'DNF'

  const position = idx + 1
  if (position === 1) return '1st'
  if (position === 2) return '2nd'
  if (position === 3) return '3rd'
  return `${position}th`
}

export const getRaceSuffix = (idx: number) => {
  if (-1 === idx) return 'DNF'

  const position = idx + 1
  if (position === 1) return 'st'
  if (position === 2) return 'nd'
  if (position === 3) return 'rd'
  return `th`
}

export const formatLabelState = (state: string) => {
  return state
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
