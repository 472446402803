import { EntryPrice, TonEntryPrice } from '@/types/game'
import { RaceType, TonRaceType } from '@/types/race'
import { UserRaceStatus } from '@/types/user'

export const RACE_DISTANCE = 10000
export const FREE_RACE_DISTANCE = 5000

export const PRICE_FEE = 10

export const PRICE_FEE_NPC = 0

export const PRICE_DIVIDER = process.env.NEXT_PUBLIC_PRICE_DIVIDER
  ? Number(process.env.NEXT_PUBLIC_PRICE_DIVIDER)
  : 1

export const CANCELLATION_FEE = 0.01

export const TON_RACE_STATUSES = [
  UserRaceStatus.TON_PENDING,
  UserRaceStatus.TON_IN_GAME,
  UserRaceStatus.TON_DONE,
  UserRaceStatus.TON_WAITING_FOR_TRANSACTION,
]

type EntryPriceVariants = Record<
  TonRaceType,
  Record<
    TonEntryPrice,
    {
      entry: TonEntryPrice
      win: number | string
      second: number | string
      usd?: number | string
    }
  >
>

type EntryRewardVariants = Record<TonRaceType, Record<'economy' | 'standard' | 'premium', number>>

export const ENTRY_PRICE_VARIANTS: EntryPriceVariants = {
  [RaceType.OneVsOne]: {
    '0.6': {
      entry: '0.6',
      win: 1,
      second: 0,
      usd: 3,
    },
    '1': {
      entry: '1',
      win: 1.8,
      second: 0,
      usd: 5,
    },
    '5': {
      entry: '5',
      win: 9,
      second: 0,
      usd: 25,
    },
    '25': {
      entry: '25',
      win: 47,
      second: 0,
      usd: 125,
    },
    '50': {
      entry: '50',
      win: 95,
      second: 0,
      usd: 250,
    },
  },
  [RaceType.OneVsThree]: {
    '0.6': {
      entry: '0.6',
      win: 1.2,
      second: 0.8,
    },
    '1': {
      entry: '1',
      win: 2.2,
      second: 1.4,
    },
    '5': {
      entry: '5',
      win: 11,
      second: 8,
    },
    '25': {
      entry: '25',
      win: 60,
      second: 35,
    },
    '50': {
      entry: '50',
      win: 120,
      second: 70,
    },
  },
}

export const ENTRY_REWARD_VARIANTS: EntryRewardVariants = {
  [RaceType.OneVsOne]: {
    economy: 80,
    standard: 90,
    premium: 95,
  },
  [RaceType.OneVsThree]: {
    economy: 100,
    standard: 120,
    premium: 140,
  },
}
