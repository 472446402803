'use client'

import { ChooseNitro } from './components/ChooseNitro'
import { StartRaceButton } from './components/StartRaceButton'

export default function RaceStepChooseNitroPage() {
  return (
    <div className="layer-gradient">
      <ChooseNitro />
      <StartRaceButton className="fixed left-0 bottom-[92px]" />
    </div>
  )
}
