'use client'

import { FC } from 'react'
import { EnergyIcon } from '@/assets/icons'
import { Transition } from '@/components/Transitions'
import { BoostCardLayout } from './BoostCardLayout'
import { UserBoostType } from '@/types/user'
import { BoosterUpgradeLayoutModal } from '@/app/boosters/components/Boosters/BoosterUpgradeLayoutModal'
import { useBoostersCards } from '@/app/boosters/components/Boosters/hooks'

interface Props {}

export const BoosterEnergyMaxCard: FC<Props> = () => {
  const {
    user: { energyMaxLevel, tokens },
    userLevelData: { energyLimitMaxPriceAndBonus },
    isOpenModal,
    handleOpenModal,
    handleCloseModal,
    mutateAsync,
    isPending,
  } = useBoostersCards()

  const isNotEnoughTokens = tokens < energyLimitMaxPriceAndBonus.price

  return (
    <>
      <BoostCardLayout
        isDone={energyLimitMaxPriceAndBonus?.isLastLevel}
        tokens={energyLimitMaxPriceAndBonus.price}
        onClick={handleOpenModal}
        isNotEnoughTokens={isNotEnoughTokens}
      >
        <Transition animationType="scaleFromZero">
          <EnergyIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <div className="flex flex-col">
          <p className="text-sm font-normal text-white">Energy max</p>
          <p className="text-xs font-light text-gray-300">{energyMaxLevel} level</p>
        </div>
      </BoostCardLayout>
      <BoosterUpgradeLayoutModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        level={energyMaxLevel + 1}
        title="Energy max"
        price={energyLimitMaxPriceAndBonus.price}
        onRequestUpgrade={() => {
          mutateAsync({ boostType: UserBoostType.ENERGY_LIMIT_LEVEL })
        }}
        isLoading={isPending}
        Icon={EnergyIcon}
        isNotEnoughTokens={isNotEnoughTokens}
        subtitle={`Upgrade energy limit +${energyLimitMaxPriceAndBonus.nextLevelBonus}`}
      />
    </>
  )
}
