'use client'

import { FC, useMemo, useState } from 'react'
import { CalendarIcon, CoinIcon } from '@/assets/icons'
import { CardLayout } from '@/app/rewards/components/CardLayout'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { Transition } from '@/components/Transitions'
import { useQuery_FetchUserTasks_User } from '@/services/useApi/user/useQuery'
import { useDailyRewards } from '@/hooks/useDailyRewards'
import { DailyRewardsModal } from '@/app/rewards/components/DailyRewards/DailyRewardsModal'

interface Props {}

export const DailyRewardsCard: FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { data: tasks } = useQuery_FetchUserTasks_User()

  const dailyRewards = useMemo(() => {
    if (!tasks?.dailyRewards) return []

    return tasks.dailyRewards
  }, [tasks])

  const { webApp } = useTelegram()

  const { isDone } = useDailyRewards()

  const totalReward = useMemo(() => {
    return dailyRewards.filter((item) => item.claimed).reduce((acc, item) => acc + item.amount, 0)
  }, [dailyRewards])

  const tokens = useMemo(() => {
    if (dailyRewards[dailyRewards.length - 1]?.claimed) return 0

    const lastIndex = dailyRewards.findLastIndex((item) => item.claimed && item.claimDate)

    if (lastIndex === -1) return dailyRewards[0]?.amount

    return dailyRewards[lastIndex + 1]?.amount
  }, [dailyRewards])

  const handleClickBtn = () => {
    webApp?.HapticFeedback.impactOccurred('medium')
    setIsOpen(true)
  }

  return (
    <>
      <CardLayout isDone={isDone} onClick={handleClickBtn} tokens={tokens}>
        <Transition animationType="scaleFromZero">
          <CalendarIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <div className="flex flex-col">
          <p className="text-sm font-normal">Daily reward</p>
          <div className="flex gap-0.5 items-center">
            <p className="text-xs font-light text-gray-300 pr-0.5">Total reward:</p>
            <CoinIcon className="w-2.5 h-2.5 min-w-2.5 text-yellow-500" />
            <p className="text-xs font-normal text-yellow-500">{totalReward}</p>
          </div>
        </div>
      </CardLayout>
      <DailyRewardsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        dailyRewards={dailyRewards}
        isDone={isDone}
      />
    </>
  )
}
