'use client'

import { FC } from 'react'
import { CoinIcon, StarburstIcon } from '@/assets/icons'
import { UserRaceData } from '@/types/user'
import { formatDistanceToNow } from 'date-fns'

interface Props {
  raceData: UserRaceData
}

export const LastRacesItem: FC<Props> = ({ raceData }) => {
  const { place, createdAd, reward, id } = raceData

  const date = new Date(parseInt(createdAd))

  return (
    <div className="w-full flex items-center justify-between gap-2">
      <div className="w-[30px] min-w-[30px] h-[30px] rounded-full bg-blue-900 flex relative justify-center items-center">
        <p className="text-white text-xs font-bold text-center">{place}</p>
        {place <= 3 && (
          <StarburstIcon className="absolute left-0 top-0 w-full h-full z-[1] text-white" />
        )}
      </div>
      <p className="min-w-8 text-white text-sm font-normal">#{id}</p>
      <p className="text-xs font-normal text-gray-300 min-w-20">
        {formatDistanceToNow(date, { addSuffix: true }).toString().replace('about ', '')}
      </p>
      <div className="flex items-center justify-end gap-0.5 text-yellow-500 min-w-20">
        <p className="text-lg font-bold">{!!Number(reward) ? `+${reward}` : reward}</p>
        <CoinIcon className="w-[30px] min-w-[30px] h-[30px]" />
      </div>
    </div>
  )
}
