import { useMutation } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectRacePreparationPair } from '@/redux/features/racePreparationSlice'
import { createNewGame } from '@/services/api/game'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { setUserRaceId } from '@/redux/features/user'
import { queryClient } from '@/app/providers'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { AxiosError } from 'axios'
import { isJwtExpiredError } from '@/services/mappers/errors'
import { updateTokens } from '@/services/api/auth'
import { useTelegram } from '@/app/providers/TelegramProvider'

export const useMutation_CreateNewGame_Game = (successCallback?: () => void) => {
  const dispatch = useAppDispatch()

  const pair = useAppSelector(selectRacePreparationPair)

  const { webApp } = useTelegram()

  return useMutation({
    mutationFn: async () => {
      if (!pair) {
        throw new Error('Please select pair and price')
      }

      try {
        const data = await createNewGame({
          pair,
        })

        dispatch(setUserRaceId(data.numID.toString()))

        return data
      } catch (e: unknown) {
        if (e instanceof AxiosError && isJwtExpiredError(e)) {
          const telegramInitData = webApp?.initData as string

          await updateTokens(telegramInitData)

          const data = await createNewGame({
            pair,
          })

          dispatch(setUserRaceId(data.numID.toString()))
        } else {
          throw new Error('Error fetching user data')
        }
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userData] })
      dispatch(setRoute(RoutePaths.race))

      successCallback && successCallback()
    },
  })
}
