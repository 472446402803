'use client'

import { FC, useEffect, useMemo } from 'react'
import { TonRaceStartTimerComponent } from './TonRaceStartTimer.component'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { useTimer } from 'react-timer-hook'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { selectRoundTripTime, selectTimeDifference } from '@/redux/features/time'
import {
  selectRacePreparationEntryPrice,
  selectRacePreparationRaceType,
  setRacePreparationEntryPrice,
  setRacePreparationRaceType,
} from '@/redux/features/racePreparationSlice'
import { selectUser } from '@/redux/features/user'
import { PRICE_DIVIDER } from '@/constants/race'
import { TonEntryPrice } from '@/types/game'
import { RaceType } from '@/types/race'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { getTelegramName } from '@/utils/user'

interface Props {
  isLoadingGeneral?: boolean
}

export const TonRaceStartTimerContainer: FC<Props> = ({ isLoadingGeneral }) => {
  const dispatch = useAppDispatch()

  const { user: userTelegram } = useTelegram()
  const isLoadingAuth = useAppSelector(selectIsLoadingAuth)
  const { data: game, isLoading: isLoadingActiveGame = true } = useQuery_GetGameById_Game()

  const timeDifference = useAppSelector(selectTimeDifference)
  const roundTripTime = useAppSelector(selectRoundTripTime)
  const raceType = useAppSelector(selectRacePreparationRaceType)
  const entryPrice = useAppSelector(selectRacePreparationEntryPrice)

  const { waitingForTonGameData, tgId } = useAppSelector(selectUser)

  const playersData = useMemo(() => {
    return waitingForTonGameData?.playersData
      ? waitingForTonGameData?.playersData.filter((item) => item.tgId !== tgId)
      : []
  }, [waitingForTonGameData])

  useEffect(() => {
    if (waitingForTonGameData?.entryPrice) {
      const entryPrice = (
        (Number(waitingForTonGameData.entryPrice) / 1e9) *
        PRICE_DIVIDER
      ).toString() as TonEntryPrice

      dispatch(setRacePreparationEntryPrice(entryPrice))
    }

    if (waitingForTonGameData?.limit) {
      dispatch(
        setRacePreparationRaceType(
          waitingForTonGameData.limit === 2 ? RaceType.OneVsOne : RaceType.OneVsThree
        )
      )
    }
  }, [waitingForTonGameData])

  const startAt = useMemo(() => {
    return game?.startAt
  }, [game])

  const { seconds, minutes, isRunning, restart, totalSeconds } = useTimer({
    expiryTimestamp: new Date(),
  })

  const isExpiredTime = useMemo(() => {
    if (!startAt || !timeDifference) return false

    return new Date(Date.now() + timeDifference) > new Date(startAt)
  }, [startAt, timeDifference])

  useEffect(() => {
    if (timeDifference !== 0 && startAt) {
      restart(new Date(new Date(startAt).getTime() - timeDifference + roundTripTime))
    }
  }, [startAt, timeDifference, roundTripTime])

  const isExpired = useMemo(() => {
    return (
      isLoadingAuth ||
      isLoadingActiveGame ||
      !startAt ||
      !isRunning ||
      isExpiredTime ||
      (isRunning && totalSeconds < 5)
    )
  }, [isLoadingAuth, isLoadingActiveGame, startAt, isRunning, isExpiredTime, totalSeconds])

  if (isLoadingGeneral) {
    return null
  }

  if (isExpired) {
    return null
  }

  return (
    <TonRaceStartTimerComponent
      seconds={seconds}
      minutes={minutes}
      raceType={raceType}
      entryPrice={entryPrice}
      userName={getTelegramName(userTelegram)}
      secondPlayerName={playersData.length >= 1 ? playersData[0]?.username : ''}
      thirdPlayerName={playersData.length >= 2 ? playersData[1]?.username : ''}
      fourthPlayerName={playersData.length >= 3 ? playersData[2]?.username : ''}
    />
  )
}
