'use client'

import { FC } from 'react'
import { Transition } from '@/components/Transitions'
import { BoosterMultiTapCard } from './BoosterMultiTapCard'
import { BoosterEnergyPerMinCard } from './BoosterEnergyPerMinCard'
import { BoosterStorageLimitCard } from './BoosterStorageLimitCard'
import { BoosterEnergyMaxCard } from './BoosterEnergyMaxCard'

interface Props {}

export const Boosters: FC<Props> = () => {
  return (
    <section className="w-full p-4">
      <Transition animationType="leftToRight">
        <p className="pb-4 text-white font-medium text-sm">Boosters:</p>
      </Transition>
      <div className="flex flex-col gap-2 w-full">
        <BoosterMultiTapCard />
        <BoosterEnergyPerMinCard />
        <BoosterEnergyMaxCard />
        <BoosterStorageLimitCard />
      </div>
    </section>
  )
}
