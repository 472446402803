import { FC, useEffect, useRef } from 'react'
import cx from 'classnames'
import { motion } from 'framer-motion'
import { CloseIcon } from '@/assets/icons'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { usePreventSwipeClose } from '@/hooks/usePreventSwipeClose'
import { ModalPortal } from '@/components/ui/ModalPortal'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  classNameContent?: string
  isDark?: boolean
}

export const ModalCustom: FC<Props> = ({
  className,
  isOpen,
  onClose,
  children,
  classNameContent,
  isDark,
}) => {
  const variants = {
    open: { y: 0, opacity: 1, transition: { duration: 0.3 } },
    closed: { y: '100%', opacity: 0, transition: { duration: 0.2 } },
  }

  const { unlockScroll, lockScroll } = usePreventSwipeClose()

  useEffect(() => {
    if (isOpen) {
      lockScroll()
    } else {
      unlockScroll()
    }
  }, [])

  const { webApp } = useTelegram()

  const handleModalClose = () => {
    onClose()
    webApp?.HapticFeedback?.impactOccurred('light')
  }

  return (
    <ModalPortal selector="#modal-root">
      {isOpen && (
        <div className="fixed left-0 top-0 w-full h-full z-[49]" onClick={handleModalClose} />
      )}
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={variants}
        className={cx(
          'w-full min-h-[496px] h-1 rounded-t-large border-t-1 border-yellow-500 fixed px-4 pt-6 pb-4 bg-purple-radial-gradient bottom-0 left-0 z-[250]',
          className,
          {
            '!bg-dark-bg-radial-gradient !border-blue-600': isDark,
          }
        )}
        drag="y"
        dragConstraints={{ top: 0, bottom: 0 }}
        onDragEnd={(event, info) => {
          if (info.point.y > 150) {
            handleModalClose()
          }
        }}
      >
        <div className={cx('w-full relative h-full flex flex-col z-[60]', classNameContent)}>
          {children}
          <CloseIcon
            className="w-8 h-8 text-purple-700 absolute -top-2 right-0"
            onClick={handleModalClose}
          />
        </div>
      </motion.div>
    </ModalPortal>
  )
}
