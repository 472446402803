import { useQuery_FetchUserTasks_User } from '@/services/useApi/user/useQuery'
import { useMemo } from 'react'
import { getDaysSinceEpoch } from '@/utils/time'

export const useDailyRewards = () => {
  const { data: tasks } = useQuery_FetchUserTasks_User()

  const dailyRewards = useMemo(() => {
    if (!tasks?.dailyRewards) return []

    return tasks.dailyRewards
  }, [tasks])

  const isDone = useMemo(() => {
    const lastIndex = dailyRewards.findLastIndex((item) => item.claimed && item.claimDate)

    if (lastIndex === -1) return false

    const daysSinceEpoch = getDaysSinceEpoch()

    return dailyRewards[lastIndex].claimDate === daysSinceEpoch
  }, [dailyRewards])

  return {
    isDone,
  }
}
