export const getPercentageOfNumber = (
  percent?: number,
  value?: number
): number => {
  if (!percent || !value) {
    return 0
  }

  return (percent / 100) * value
}

export const getPercentageNumberOfNumber = (
  first?: number,
  second?: number
): number => {
  if (!first || !second) {
    return 0
  }

  return (first * 100) / second
}

export const remDiv = (a: number, b: number) => {
  return a / b - ~~(a / b)
}

export const subtractPercentageFromStringNumber = (
  strNumber: string | number | null | undefined,
  percentage: number
): number => {
  if (!strNumber) {
    return 0
  }

  const num = Number(strNumber)

  if (isNaN(num)) {
    throw new Error('Invalid number string')
  }

  return num - num * (percentage / 100)
}

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}
