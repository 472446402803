import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface ModalPortalProps {
  children: React.ReactNode
  selector: string
}

export const ModalPortal: FC<ModalPortalProps> = ({ children, selector }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return mounted ? createPortal(children, document.querySelector(selector)!) : null
}
