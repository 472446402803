import {
  CombatGrIcon,
  CouponsStackIcon,
  CouponsStackIconGr,
  ProfileGrIcon,
  RewardsIcon,
  RouteGrIcon,
  TonIcon,
} from '@/assets/icons'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { ElementType } from 'react'

export const BOTTOM_MENU_ITEMS: Array<{
  icon: ElementType
  inActiveIcon: ElementType
  link: RoutePaths | null
  text: string
}> = [
  {
    icon: TonIcon,
    inActiveIcon: TonIcon,
    link: RoutePaths.tonRaceStepRaceType,
    text: 'TON race',
  },
  {
    icon: CombatGrIcon,
    inActiveIcon: CombatGrIcon,
    link: RoutePaths.raceStepSelect,
    text: 'Free Race',
  },
  {
    icon: ProfileGrIcon,
    inActiveIcon: ProfileGrIcon,
    link: RoutePaths.profile,
    text: 'Profile',
  },
  {
    icon: RewardsIcon,
    inActiveIcon: RewardsIcon,
    link: RoutePaths.rewards,
    text: 'Earn',
  },
  {
    icon: RouteGrIcon,
    inActiveIcon: RouteGrIcon,
    link: RoutePaths.info,
    text: 'Info',
  },
]
