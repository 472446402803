'use client'

import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import { useAppSelector } from '@/redux/hooks'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { selectUser } from '@/redux/features/user'

const variants = {
  scale: {
    hidden: { opacity: 0, scale: 0.7 },
    enter: { opacity: 1, scale: [0.7, 1.1, 1.0] },
  },
  scaleFromZero: {
    hidden: { opacity: 0, scale: 0.1 },
    enter: { opacity: 1, scale: [0.1, 1.1, 1.0] },
  },
  leftToRight: {
    hidden: { opacity: 0, x: -80 },
    enter: { opacity: 1, x: [-80, 10, 0] },
  },
  rightToLeft: {
    hidden: { opacity: 0, x: 80 },
    enter: { opacity: 1, x: [80, -10, 0] },
  },
  bottomToTop: {
    hidden: { opacity: 0, y: 80 },
    enter: { opacity: 1, y: [80, -10, 0] },
  },
  topToBottom: {
    hidden: { opacity: 0, y: -80 },
    enter: { opacity: 1, y: [-80, 10, 0] },
  },
}

interface Props {
  children: ReactNode
  animationType?:
    | 'scale'
    | 'leftToRight'
    | 'rightToLeft'
    | 'bottomToTop'
    | 'topToBottom'
    | 'scaleFromZero'
}

export const Transition: FC<Props> = ({ children, animationType = 'scale' }) => {
  const isLoading = useAppSelector(selectIsLoadingAuth)
  const user = useAppSelector(selectUser)

  if (isLoading || !user.tgId) return null

  const selectedVariant = variants[animationType]

  return (
    <motion.div
      initial="hidden"
      animate="enter"
      variants={selectedVariant}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  )
}
