'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectWsCalculationUpdates } from '@/redux/features/wsDataSlice'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { RaceTrackMobileComponent } from './RaceTrackMobile.component'
import { RACE_DISTANCE } from '@/constants/race'
import { setIsAllRaceActivitiesCompleted } from '@/redux/features/race'
import { selectTimeDifference } from '@/redux/features/time'
import { selectUser } from '@/redux/features/user'
import { setQueryOptionsUserReFetchInterval } from '@/redux/features/queryOptionsSlice'
import { queryClient } from '@/app/providers'
import { CACHE_KEYS } from '@/constants/cacheKeys'
import { isPvp } from '@/utils/race'

export const RaceTrackContainer: FC = () => {
  const dispatch = useAppDispatch()

  const { tgId: userTgId, waitingForTonGameData, raceStatus } = useAppSelector(selectUser)

  const [timerSet, setTimerSet] = useState(false)

  const timeDifference = useAppSelector(selectTimeDifference)

  const wsCalculationUpdates = useAppSelector(selectWsCalculationUpdates)
  const { data: game } = useQuery_GetGameById_Game()

  const isFinishedFromCalculationUpdates = useMemo(() => {
    return (
      !!wsCalculationUpdates?.length &&
      wsCalculationUpdates?.every((player) => parseFloat(player?.totalDistance) >= RACE_DISTANCE)
    )
  }, [wsCalculationUpdates])

  const isPvpMode = useMemo(() => {
    return isPvp(waitingForTonGameData, raceStatus)
  }, [waitingForTonGameData, raceStatus])

  useEffect(() => {
    if (isFinishedFromCalculationUpdates && !timerSet) {
      setTimerSet(true)
      ;(async () => {
        await queryClient.invalidateQueries({ queryKey: [CACHE_KEYS.userData] })
      })()
      setTimeout(() => {
        dispatch(setIsAllRaceActivitiesCompleted(true))
      }, 2500)
    }
  }, [isFinishedFromCalculationUpdates, timerSet])

  return (
    <RaceTrackMobileComponent
      userTgId={userTgId}
      wsCalculationUpdates={wsCalculationUpdates}
      activeGame={game?.players || []}
      startAtGame={game?.startAt}
      timeDifference={timeDifference}
      isPvpMode={isPvpMode}
    />
  )
}
