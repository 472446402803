'use client'

import { FC, memo, useEffect } from 'react'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'
import { CheckeredFlagIcon } from '@/assets/icons'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { RaceType, TonRaceType } from '@/types/race'
import { ENTRY_PRICE_VARIANTS } from '@/constants/race'
import { EntryPrice, TonEntryPrice } from '@/types/game'
import {
  RacerLBlueSmIllustration,
  RacerLYellowSmIllustration,
  RacerRGreenSmIllustration,
  RacerRRedSmIllustration,
} from '@/assets/illustrations'

interface Props {
  seconds: number
  minutes: number
  raceType: RaceType
  entryPrice: EntryPrice | TonEntryPrice | null
  userName?: string
  secondPlayerName?: string
  thirdPlayerName?: string
  fourthPlayerName?: string
}

const RaceStartTimer: FC<Props> = ({
  seconds,
  minutes,
  raceType,
  entryPrice,
  userName,
  secondPlayerName,
  thirdPlayerName,
  fourthPlayerName,
}) => {
  const [lockScroll, unlockScroll] = useLockBodyScroll()

  const entryPriceVariant = ENTRY_PRICE_VARIANTS[raceType as TonRaceType]

  useEffect(() => {
    lockScroll()
  }, [])

  useEffect(() => {
    return () => {
      unlockScroll()
    }
  }, [])

  return (
    <div className="fixed left-0 bottom-0 w-full h-[100vh] z-50 flex justify-between flex-col items-center bg-dark-bg-radial-gradient gap-2">
      <div className="w-full p-4 pb-0">
        <div className="w-full rounded-2xl overflow-hidden p-2 mb-6 bg-blue-800 flex flex-col gap-2 justify-center items-center bg-[url('/backgrounds/race-header-bg.webp')] bg-no-repeat bg-center bg-contain">
          <p className="text-white text-lg font-medium">{raceType}</p>
          {entryPrice && (
            <p className="text-blue-500 text-xs font-semibold">
              Entry: {entryPrice} TON {'  '} Win:{' '}
              {entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].win}
              {!!entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second &&
                ` / ${entryPriceVariant[entryPrice as keyof typeof entryPriceVariant].second}`}{' '}
              TON
            </p>
          )}
        </div>
        <div className="mt-4 w-full flex flex-col">
          {!!userName && !!secondPlayerName && (
            <div className="flex w-full gap-2">
              <div className="w-1/2 flex justify-end items-center gap-2">
                <p className="text-gray-300 text-sm font-normal overflow-hidden text-ellipsis whitespace-nowrap">
                  {userName}
                </p>
                <div className="h-11 w-16 rounded-sm bg-blue-700 flex justify-center items-end">
                  <RacerLBlueSmIllustration className="w-11 h-11" />
                </div>
              </div>
              <div className="w-1/2 flex justify-start items-center gap-2">
                <div className="h-11 w-16 rounded-sm bg-blue-700 flex justify-center items-end">
                  <RacerRRedSmIllustration className="w-11 h-11" />
                </div>
                <p className="text-gray-300 text-sm font-normal overflow-hidden text-ellipsis whitespace-nowrap">
                  {secondPlayerName}
                </p>
              </div>
            </div>
          )}
          {!!thirdPlayerName && !!fourthPlayerName && (
            <div className="flex w-full gap-2 mt-2">
              <div className="w-1/2 flex justify-end items-center gap-2">
                <p className="text-gray-300 text-sm font-normal overflow-hidden text-ellipsis whitespace-nowrap">
                  {thirdPlayerName}
                </p>
                <div className="h-11 w-16 rounded-sm bg-blue-700 flex justify-center items-end">
                  <RacerLYellowSmIllustration className="w-11 h-11" />
                </div>
              </div>
              <div className="w-1/2 flex justify-start items-center gap-2">
                <div className="h-11 w-16 rounded-sm bg-blue-700 flex justify-center items-end">
                  <RacerRGreenSmIllustration className="w-11 h-11" />
                </div>
                <p className="text-gray-300 text-sm font-normal overflow-hidden text-ellipsis whitespace-nowrap">
                  {fourthPlayerName}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="flex items-center justify-center gap-4 text-white">
          <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px]" />
          <p className="text-lg uppercase font-bold italic">Race starts in:</p>
          <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px] rotate-180" />
        </div>
        <CustomButton variant="secondary" className="max-w-[148px] w-full">
          <span className="text-[32px] leading-none">
            <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
            <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
          </span>
        </CustomButton>
      </div>
      <div></div>
    </div>
  )
}

export const TonRaceStartTimerComponent = memo(RaceStartTimer)
