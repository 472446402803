'use client'

import {
  ArrowRightIcon,
  CheckeredFlagOrangeIcon,
  CoinIcon,
  CouponsStackIconGr,
  CouponWideIcon,
} from '@/assets/icons'
import { FC } from 'react'
import { EntryPrice } from '@/types/game'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setRacePreparationEntryPrice } from '@/redux/features/racePreparationSlice'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { selectUser } from '@/redux/features/user'
import { toFixedSafe } from '@/utils/formatValue'
import { Transition } from '@/components/Transitions'
import { RacerLYellowIllustration, RobotsRIllustration } from '@/assets/illustrations'

interface Props {}

export const CouponList: FC<Props> = () => {
  const dispatch = useAppDispatch()

  const { webApp } = useTelegram()

  const user = useAppSelector(selectUser)

  const handleCouponClick = (entryPrice: EntryPrice) => {
    dispatch(setRacePreparationEntryPrice(entryPrice))
    dispatch(setRoute(RoutePaths.raceStepValidate))
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  return (
    <div className="flex flex-col justify-center items-center p-4 pb-0">
      <Transition animationType="leftToRight">
        <span className="text-base font-normal text-white text-center w-full">
          PvE: Race for free and win $NICK
        </span>
      </Transition>
      <Transition animationType="rightToLeft">
        <p className="text-gray-300 text-sm font-normal w-full text-center mb-6">
          Free race is available every 1 hour
        </p>
      </Transition>
      <div className="flex justify-center items-center w-full relative rounded-2xl overflow-hidden bg-purple-900 h-[146px] text-yellow-500 mb-4">
        <RacerLYellowIllustration className="absolute left-4 bottom-0 w-[100px] h-[146px]" />
        <CoinIcon className="w-[99px] h-[99px]" />
        <RobotsRIllustration className="absolute right-0 bottom-0 w-[120px] h-[146px]" />
      </div>
      <div className="flex items-start justify-between gap-2 w-full px-4">
        <div className="flex flex-col justify-center items-center gap-1">
          <div className="flex items-center gap-0.5 text-yellow-500">
            <CoinIcon className="w-[36px] h-[36px]" />
            <p className="text-2xl font-semibold">100</p>
          </div>
          <p className="text-xs font-medium text-yellow-700">1st place</p>
        </div>
        <div className="flex flex-col justify-center items-center gap-1">
          <div className="flex items-center gap-0.5 text-yellow-500">
            <CoinIcon className="w-[36px] h-[36px]" />
            <p className="text-2xl font-semibold">50</p>
          </div>
          <p className="text-xs font-medium text-yellow-700">2nd place</p>
        </div>
        <div className="flex flex-col justify-center items-center gap-1">
          <div className="flex items-center gap-0.5 text-yellow-500">
            <CoinIcon className="w-[36px] h-[36px]" />
            <p className="text-2xl font-semibold">10</p>
          </div>
          <p className="text-xs font-medium text-yellow-700">3rd place</p>
        </div>
      </div>
    </div>
  )
}
