'use client'

import { FC, useEffect, useRef } from 'react'
import { ColorType, createChart } from 'lightweight-charts'
import { formatDate, formatTradingPair } from '@/utils/formatValue'
import { Spinner } from '@nextui-org/react'
import { ICandleData } from './types'
import { ChartBarCandlestick } from '@/types/chart'

interface Props {
  priceList: Array<ChartBarCandlestick> | null
  upBetPoint?: number | null
  downBetPoint?: number | null
  pair?: string
  isLoading?: boolean
  lastPrice: number
  startAt?: string | null
  timeDifference: number
}

const getHeightForChart = () => {
  return window.innerWidth > 984 ? 240 : 158
}

export const RealtimeChartComponent: FC<Props> = ({
  priceList = [],
  upBetPoint,
  downBetPoint,
  pair,
  isLoading,
  lastPrice,
  startAt,
  timeDifference,
}) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const candlestickSeriesRef = useRef<any | null>(null)
  const chartRef = useRef<any | null>(null)
  const priceLineRef = useRef<any | null>(null)

  const currentCandleRef = useRef<ICandleData | null>(null)
  const currentSecondRef = useRef<number | null>(null)

  useEffect(() => {
    if (!chartContainerRef.current) return

    const handleResize = () => {
      if (chartContainerRef.current) {
        chart.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: getHeightForChart(),
        })
      }
    }

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: '#0f121c' },
        textColor: '#6A8292',
      },
      grid: {
        vertLines: { color: '#13161d' },
        horzLines: { color: '#13161d' },
      },
      width: chartContainerRef.current.clientWidth,
      height: getHeightForChart(),
      timeScale: {
        visible: false,
      },
    })
    let candlestickSeries = chart.addCandlestickSeries({
      wickUpColor: '#22C332',
      upColor: '#22C332',
      wickDownColor: '#C32222',
      downColor: '#C32222',
      borderVisible: false,
      priceFormat: {
        type: 'custom',
        formatter: (price: number) => {
          const priceAsString = price.toString()
          const decimalPart = priceAsString.split('.')[1]
          if (decimalPart && decimalPart.length > 5) {
            return price.toFixed(5)
          }
          return price
        },
      },
    })

    chart.timeScale().applyOptions({
      barSpacing: 20,
      borderColor: '#13161d',
    })

    chartRef.current = chart
    candlestickSeriesRef.current = candlestickSeries

    chart.timeScale().fitContent()

    candlestickSeries.setData([])

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chart.remove()
    }
  }, [])

  useEffect(() => {
    if (
      lastPrice &&
      chartRef.current &&
      candlestickSeriesRef.current &&
      currentCandleRef.current &&
      currentSecondRef.current
    ) {
      const now = Math.floor((Date.now() + timeDifference) / 1000)

      if (currentSecondRef.current !== now) {
        candlestickSeriesRef.current.update(currentCandleRef.current)

        currentSecondRef.current = now

        const lastClose = currentCandleRef.current ? currentCandleRef.current.close : lastPrice

        currentCandleRef.current = {
          time: currentSecondRef.current,
          open: lastClose,
          high: lastPrice,
          low: lastPrice,
          close: lastPrice,
        }
      }

      currentCandleRef.current.high = Math.max(currentCandleRef.current.high, lastPrice)
      currentCandleRef.current.low = Math.min(currentCandleRef.current.low, lastPrice)
      currentCandleRef.current.close = lastPrice

      candlestickSeriesRef.current.update(currentCandleRef.current)

      chartRef.current.timeScale().scrollToRealTime()
    }
  }, [lastPrice])

  useEffect(() => {
    if (!lastPrice) return

    const intervalId = setInterval(() => {
      const now = Date.now() + timeDifference
      const lastUpdateTimestamp = currentCandleRef.current
        ? currentCandleRef.current.time * 1000
        : 0

      const threshold = 1300

      if (now - lastUpdateTimestamp > threshold) {
        const artificialCandle = {
          time: Math.floor(now / 1000),
          open: currentCandleRef.current?.close || lastPrice,
          high: lastPrice,
          low: lastPrice,
          close: lastPrice,
        }
        candlestickSeriesRef.current.update(artificialCandle)
        currentCandleRef.current = artificialCandle
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [lastPrice])

  useEffect(() => {
    if (lastPrice && currentCandleRef.current === null) {
      currentSecondRef.current = Math.floor((Date.now() + timeDifference) / 1000)
      currentCandleRef.current = {
        time: currentSecondRef.current,
        open: lastPrice,
        high: lastPrice,
        low: lastPrice,
        close: lastPrice,
      }
    }
  }, [lastPrice])

  useEffect(() => {
    if (!!priceList && chartRef.current && candlestickSeriesRef.current) {
      if (priceList.length > 1) {
        candlestickSeriesRef.current.setData(priceList)

        chartRef.current.timeScale().scrollToRealTime()
      }
    }
  }, [priceList])

  useEffect(() => {
    if (!upBetPoint && candlestickSeriesRef.current && priceLineRef.current) {
      candlestickSeriesRef.current.removePriceLine(priceLineRef.current)
    }

    if (upBetPoint && candlestickSeriesRef.current) {
      priceLineRef.current = candlestickSeriesRef.current.createPriceLine({
        price: upBetPoint,
        color: '#35FF49',
        lineWidth: 1,
        lineStyle: 0,
        axisLabelVisible: true,
        title: 'L',
      })
    }
  }, [upBetPoint])

  useEffect(() => {
    if (!downBetPoint && candlestickSeriesRef.current && priceLineRef.current) {
      candlestickSeriesRef.current.removePriceLine(priceLineRef.current)
    }

    if (downBetPoint && candlestickSeriesRef.current) {
      priceLineRef.current = candlestickSeriesRef.current.createPriceLine({
        price: downBetPoint,
        color: '#DB3B31',
        lineWidth: 1,
        lineStyle: 0,
        axisLabelVisible: true,
        title: 'S',
      })
    }
  }, [downBetPoint])

  const isLoadingGeneral = !candlestickSeriesRef?.current || isLoading || !lastPrice

  return (
    <div className="relative w-full min-h-[151px] h-[151px] overflow-hidden border-b-2 border-gray-race-900 rounded-large">
      {isLoadingGeneral && (
        <div className="absolute top-0 left-0 flex justify-center items-center h-full w-full bg-midnight-race-900 rounded-large overflow-hidden box-border z-[2]">
          <Spinner />
        </div>
      )}
      <p className="absolute text-sm font-normal left-4 top-1 z-10 flex items-center gap-2">
        <span className="text-gray-race-200 font-medium">{formatTradingPair(pair, true)}</span>
        <span className="text-gray-race-800 text-xs">
          Start:
          <span className="font-normal text-white ml-2">
            {formatDate(startAt, 'MM/dd/yyyy HH:mm')} UTC
          </span>
        </span>
      </p>
      <div ref={chartContainerRef} className="overflow-hidden box-border w-full relative z-[1]" />
    </div>
  )
}
