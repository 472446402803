'use client'

import { FC, useEffect, useMemo } from 'react'
import { RaceStartTimerComponent } from './RaceStartTimer.component'
import { useQuery_GetGameById_Game } from '@/services/useApi/game/useQuery'
import { useTimer } from 'react-timer-hook'
import { useAppSelector } from '@/redux/hooks'
import { selectIsLoadingAuth } from '@/redux/features/authSlice'
import { selectRoundTripTime, selectTimeDifference } from '@/redux/features/time'

interface Props {
  isLoadingGeneral?: boolean
}

export const RaceStartTimerContainer: FC<Props> = ({ isLoadingGeneral }) => {
  const isLoadingAuth = useAppSelector(selectIsLoadingAuth)
  const { data: game, isLoading: isLoadingActiveGame = true } = useQuery_GetGameById_Game()

  const timeDifference = useAppSelector(selectTimeDifference)
  const roundTripTime = useAppSelector(selectRoundTripTime)

  const startAt = useMemo(() => {
    return game?.startAt
  }, [game])

  const { seconds, minutes, isRunning, restart, totalSeconds } = useTimer({
    expiryTimestamp: new Date(),
  })

  const isExpiredTime = useMemo(() => {
    if (!startAt || !timeDifference) return false

    return new Date(Date.now() + timeDifference) > new Date(startAt)
  }, [startAt, timeDifference])

  useEffect(() => {
    if (timeDifference !== 0 && startAt) {
      restart(new Date(new Date(startAt).getTime() - timeDifference + roundTripTime))
    }
  }, [startAt, timeDifference, roundTripTime])

  const isExpired = useMemo(() => {
    return (
      isLoadingAuth ||
      isLoadingActiveGame ||
      !startAt ||
      !isRunning ||
      isExpiredTime ||
      (isRunning && totalSeconds < 5)
    )
  }, [isLoadingAuth, isLoadingActiveGame, startAt, isRunning, isExpiredTime, totalSeconds])

  if (isLoadingGeneral) {
    return null
  }

  if (isExpired) {
    return null
  }

  return <RaceStartTimerComponent seconds={seconds} minutes={minutes} />
}
