import BtcTokenIcon from './btc.svg'
import MaticTokenIcon from './matic.svg'
import EthTokenIcon from './eth.svg'
import XrpTokenIcon from './xrp.svg'

import BnbTokenIcon from './bnb.svg'
import SolTokenIcon from './sol.svg'
import DogeTokenIcon from './doge.svg'
import TonTokenIcon from './ton.svg'
import ShibTokenIcon from './shib.svg'
import PepeTokenIcon from './pepe.svg'

export {
  BtcTokenIcon,
  DogeTokenIcon,
  EthTokenIcon,
  MaticTokenIcon,
  XrpTokenIcon,
  SolTokenIcon,
  BnbTokenIcon,
  TonTokenIcon,
  ShibTokenIcon,
  PepeTokenIcon,
}
