'use client'

import { FC } from 'react'
import cx from 'classnames'
import { useAppDispatch } from '@/redux/hooks'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'

interface Props {
  className?: string
}

export const PodiumButtons: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch()

  const handleFinishRace = () => {
    dispatch(setRoute(RoutePaths.raceStepSelect))
  }

  return (
    <div className={cx('w-full px-4 flex gap-2', className)}>
      <CustomButton variant={'bordered'} onClick={handleFinishRace} className="w-full">
        Finish race
      </CustomButton>
    </div>
  )
}
