'use client'

import { FC, useMemo } from 'react'
import { Player, PlayerInGame } from '@/types/player'
import { getRaceSuffix } from '@/utils/labels'
import CountUp from 'react-countup'
import cx from 'classnames'
import { useViewportHeight } from '@/hooks/useViewportHeight'

interface Props {
  userTgId?: string
  players: PlayerInGame[] | Player[]
  distanceGap?: number
  wsIsGameEnded?: boolean
}

export const RaceLeaderboardMobileComponent: FC<Props> = ({ userTgId, players, distanceGap }) => {
  const { isViewportNarrow } = useViewportHeight()

  const userIndex = useMemo(() => {
    return players?.findIndex((player) => player.id?.toLowerCase() === userTgId?.toLowerCase())
  }, [players, userTgId])

  return (
    <p
      className={cx('text-lg font-black text-white', {
        '!text-xs': isViewportNarrow,
      })}
    >
      <CountUp start={0} end={userIndex + 1} preserveValue duration={1} />
      <span>{getRaceSuffix(userIndex)}</span>
      {!!distanceGap && (
        <span
          className={cx('text-sm font-normal text-red-race-500 ml-2', {
            '!text-green-race-500': distanceGap > 0,
            '!text-xs': isViewportNarrow,
          })}
        >
          <CountUp end={distanceGap || 0} duration={1} preserveValue />m
        </span>
      )}
    </p>
  )
}
