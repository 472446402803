'use client'

import { FC } from 'react'
import use100vh from '@/hooks/use100vh'
import { RaceLeaderboard } from '../RaceLeaderboard'
import { NitroLog } from '../NitroLog'
import { RaceTrack } from '../RaceTrack'
import { RealtimeChart } from '../RealtimeChart'
import { DirectionPanel } from '../DirectionPanel'

export const GamePlaceMobileComponent: FC = () => {
  const heightCSS = use100vh()

  return (
    <div
      className="fixed left-0 top-0 container h-full w-full p-0 m-0 flex flex-col justify-between items-center min-h-[550px] bg-midnight-race-700 z-[1]"
      style={{
        height: heightCSS,
      }}
    >
      <div className="w-full h-full relative">
        <div className="absolute top-1 left-4 z-10 flex flex-col gap-1">
          <RaceLeaderboard />
          <NitroLog />
        </div>
        <RaceTrack />
      </div>
      <div className="w-full border-2 border-midnight-race-500 border-b-0 pt-0 rounded-t-large bg-midnight-race-900">
        <RealtimeChart />
        <DirectionPanel />
      </div>
    </div>
  )
}
