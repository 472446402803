'use client'

import { FC } from 'react'
import cx from 'classnames'
import { CircleDesignedCheckeredIcon, CircleDesignedIcon } from '@/assets/icons'

interface Props {
  title: string
  isCompleted?: boolean
  isMarked?: boolean
}

export const RoadmapItem: FC<Props> = ({ title, isCompleted, isMarked }) => {
  return (
    <div
      className={cx('flex items-center gap-4 text-white w-full pb-3.5', {
        'text-yellow-500': isMarked,
      })}
    >
      <div
        className={cx('relative text-white', {
          '!text-green-500': isCompleted,
          'text-yellow-500': isMarked,
        })}
      >
        {isCompleted ? (
          <CircleDesignedCheckeredIcon className="w-[30px] min-w-[30px] h-[30px]" />
        ) : (
          <CircleDesignedIcon className="w-[30px] min-w-[30px] h-[30px]" />
        )}
        {!isMarked && (
          <div
            className={cx(
              'absolute w-[3px] min-w-[3px] h-2.5 bg-white rounded-medium -bottom-3 left-1/2 transform -translate-x-1/2',
              {
                '!bg-green-500': isCompleted,
              }
            )}
          />
        )}
      </div>
      <p className="text-base font-medium">{title}</p>
    </div>
  )
}
