'use client'

import { FC, memo, useEffect } from 'react'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'
import { CheckeredFlagIcon } from '@/assets/icons'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'

interface Props {
  seconds: number
  minutes: number
}

const RaceStartTimer: FC<Props> = ({ seconds, minutes }) => {
  const [lockScroll, unlockScroll] = useLockBodyScroll()

  useEffect(() => {
    lockScroll()
  }, [])

  useEffect(() => {
    return () => {
      unlockScroll()
    }
  }, [])

  return (
    <div className="fixed left-0 bottom-0 w-full h-[100vh] z-50 flex justify-center flex-col items-center bg-purple-radial-gradient gap-2">
      <div className="flex items-center justify-center gap-4 text-white">
        <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px]" />
        <p className="text-lg uppercase font-bold italic">Race starts in:</p>
        <CheckeredFlagIcon className="h-[32px] w-[81px] min-w-[81px] rotate-180" />
      </div>
      <CustomButton className="max-w-[148px] w-full">
        <span className="text-[32px] leading-none">
          <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </span>
      </CustomButton>
    </div>
  )
}

export const RaceStartTimerComponent = memo(RaceStartTimer)
