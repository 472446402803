'use client'

import { TonBlueIcon } from '@/assets/icons'
import { FC } from 'react'
import { TonEntryPrice } from '@/types/game'
import { TonRaceType } from '@/types/race'
import { ENTRY_PRICE_VARIANTS } from '@/constants/race'
import { normalizeTonEntryPrice } from '@/utils/race'
import { SuccessTrophyIllustration } from '@/assets/illustrations'

interface Props {
  entryPrice?: TonEntryPrice
  raceType: TonRaceType
}

export const TonPodium1stPosition: FC<Props> = ({ entryPrice, raceType }) => {
  const prize = !!entryPrice
    ? ENTRY_PRICE_VARIANTS[raceType][normalizeTonEntryPrice(entryPrice)].win
    : 0

  return (
    <div className="flex flex-col items-center justify-center text-center -mt-9">
      <div className="relative">
        <SuccessTrophyIllustration className="w-[329px] relative z-[2]" />
      </div>
      <p className="w-full text-center text-xs font-medium text-gray-300">
        The prize has been sent to your wallet
      </p>
      <div className="flex gap-1 items-center my-2">
        <TonBlueIcon className="w-[33px] h-[33px] min-w-[33px]" />
        <p className="text-blue-600 text-2xl font-semibold">{prize}</p>
      </div>
      <p className="w-full text-center text-lg font-bold text-white">Great job!</p>
    </div>
  )
}
