'use client'

import { FC } from 'react'
import { HandUpIcon } from '@/assets/icons'
import { Transition } from '@/components/Transitions'
import { BoostCardLayout } from './BoostCardLayout'
import { UserBoostType } from '@/types/user'
import { BoosterUpgradeLayoutModal } from './BoosterUpgradeLayoutModal'
import { useBoostersCards } from '@/app/boosters/components/Boosters/hooks'

interface Props {}

export const BoosterMultiTapCard: FC<Props> = () => {
  const {
    user: { multitapLevel, tokens },
    userLevelData: { multitapPriceAndBonus },
    handleOpenModal,
    handleCloseModal,
    isOpenModal,
    mutateAsync,
    isPending,
  } = useBoostersCards()

  const isNotEnoughTokens = tokens < multitapPriceAndBonus.price

  return (
    <>
      <BoostCardLayout
        isDone={multitapPriceAndBonus?.isLastLevel}
        tokens={multitapPriceAndBonus.price}
        onClick={handleOpenModal}
        isNotEnoughTokens={isNotEnoughTokens}
      >
        <Transition animationType="scaleFromZero">
          <HandUpIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <div className="flex flex-col">
          <p className="text-sm font-normal text-white">Multitap</p>
          <p className="text-xs font-light text-gray-300">{multitapLevel} level</p>
        </div>
      </BoostCardLayout>
      <BoosterUpgradeLayoutModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        level={multitapLevel + 1}
        title="Multitap"
        price={multitapPriceAndBonus.price}
        onRequestUpgrade={() => {
          mutateAsync({ boostType: UserBoostType.MULTI_TAP_LEVEL })
        }}
        isLoading={isPending}
        Icon={HandUpIcon}
        isNotEnoughTokens={isNotEnoughTokens}
        subtitle={`Upgrade clicker +${multitapPriceAndBonus.nextLevelBonus + 1} coupons per click`}
      />
    </>
  )
}
