'use client'

import { FC, useEffect, useMemo, useState } from 'react'
import { RaceIndicator } from '@/components/ui/RaceIndicator'
import { Player, PlayerInGame } from '@/types/player'
import { RaceGameLayout } from './RaceGameLayout'
import { RaceTrackCar } from './RaceTrackCar.component'
import { RACE_TRACK_CONFIG } from './constant'
import { RaceStopwatchAndDistanceComponent } from './RaceStopwatchAndDistance.component'
import { RaceStartTimerComponent } from './RaceStartTimer.component'
import { RACE_DISTANCE } from '@/constants/race'
import { useViewportHeight } from '@/hooks/useViewportHeight'
import { isPvp } from '@/utils/race'

interface Props {
  userTgId?: string
  wsCalculationUpdates?: Array<PlayerInGame>
  activeGame: Array<Player>
  startAtGame?: string | null
  timeDifference: number
  isPvpMode?: boolean
}

export const RaceTrackMobileComponent: FC<Props> = ({
  userTgId,
  wsCalculationUpdates,
  activeGame,
  startAtGame,
  timeDifference,
  isPvpMode,
}) => {
  const { isViewportNarrow } = useViewportHeight()

  const players = wsCalculationUpdates?.length ? wsCalculationUpdates : activeGame

  const [trackConfig, setTrackConfig] = useState(RACE_TRACK_CONFIG.MOBILE_SMALL)

  useEffect(() => {
    if (isViewportNarrow) {
      setTrackConfig(RACE_TRACK_CONFIG.MOBILE_SMALL)
    } else {
      setTrackConfig(RACE_TRACK_CONFIG.MOBILE)
    }
  }, [isViewportNarrow])

  const { FINISH_GAME_LAYOUT_POSITION, MAX_CAR_POSITION, START_GAME_LAYOUT_POSITION, CAR_HEIGHT } =
    trackConfig

  const [gameLayoutPosition, setGameLayoutPosition] = useState(START_GAME_LAYOUT_POSITION)

  const maxDistance = useMemo(() => {
    if (!wsCalculationUpdates?.length) {
      return 0
    }

    if ('totalDistance' in wsCalculationUpdates[0]) {
      const playersInGame = wsCalculationUpdates as PlayerInGame[]

      return Math.max(...playersInGame.map((p) => parseFloat(p.totalDistance)))
    }

    return 0
  }, [wsCalculationUpdates])

  useEffect(() => {
    if (maxDistance >= RACE_DISTANCE) {
      setGameLayoutPosition(FINISH_GAME_LAYOUT_POSITION)
      return
    }

    if (maxDistance >= MAX_CAR_POSITION) {
      setGameLayoutPosition(START_GAME_LAYOUT_POSITION - (maxDistance - MAX_CAR_POSITION))
    }
  }, [maxDistance, START_GAME_LAYOUT_POSITION, FINISH_GAME_LAYOUT_POSITION, MAX_CAR_POSITION])

  useEffect(() => {
    setGameLayoutPosition(START_GAME_LAYOUT_POSITION)
  }, [START_GAME_LAYOUT_POSITION])

  const getPositionPlayer = (currentDistance: number, maxDistancePlayers: number) => {
    if (currentDistance >= RACE_DISTANCE) {
      return MAX_CAR_POSITION
    }

    if (currentDistance >= MAX_CAR_POSITION && currentDistance === maxDistancePlayers) {
      return MAX_CAR_POSITION
    }

    if (currentDistance >= MAX_CAR_POSITION && currentDistance < maxDistancePlayers) {
      return MAX_CAR_POSITION - (maxDistancePlayers - currentDistance)
    }

    return currentDistance
  }

  return (
    <div className={`w-full h-full relative overflow-hidden lg:hidden`}>
      <div className="w-full h-full pr-8 relative pl-2">
        <div className="w-full h-full relative ">
          <RaceGameLayout
            height={RACE_DISTANCE + 1000}
            bottom={gameLayoutPosition}
            finish={RACE_DISTANCE}
          />
          {players.map((player, index) => {
            const isUser = player?.id?.toLowerCase() === userTgId?.toLowerCase()

            return (
              <RaceTrackCar
                key={index}
                bottom={
                  'totalDistance' in player
                    ? Math.ceil(
                        getPositionPlayer(parseFloat(player?.totalDistance as string), maxDistance)
                      )
                    : 0
                }
                index={index}
                isUser={isUser}
                // TODO: fix this
                // @ts-ignore
                vote={'vote' in player ? player?.vote : undefined}
                carHeight={CAR_HEIGHT}
                numberPlayers={players.length}
                {...(isPvpMode && { name: player?.name })}
              />
            )
          })}
          {!!startAtGame && (
            <>
              <RaceStopwatchAndDistanceComponent
                startAt={startAtGame}
                wsCalculationUpdates={wsCalculationUpdates}
                userTgId={userTgId}
                className="!top-1"
                timeDifference={timeDifference}
                isPvpMode={isPvpMode}
              />
              <RaceStartTimerComponent
                startAt={startAtGame}
                timeDifference={timeDifference}
                maxDistance={maxDistance}
              />
            </>
          )}
        </div>
      </div>
      <div className="py-2.5 absolute top-0 right-2 h-full">
        <RaceIndicator
          userTgId={userTgId}
          wsCalculationUpdates={wsCalculationUpdates}
          isPvpMode={isPvpMode}
        />
      </div>
    </div>
  )
}
