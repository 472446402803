'use client'

import { FC } from 'react'
import { FriendsListItem } from './FriendsListItem'
import { useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { UsersIcon } from '@/assets/icons'
import { toFixedSafe } from '@/utils/formatValue'

interface Props {}

export const FriendsList: FC<Props> = () => {
  const { refData, referralsLastDay } = useAppSelector(selectUser)

  return (
    <div className="flex flex-col px-4 pb-8">
      <div className="px-4 py-2 bg-purple-900 rounded-medium flex gap-2 items-center mb-2">
        <UsersIcon className="w-[30px] h-[30px] min-w-[30px] text-white" />
        <p className="font-normal text-base text-gray-300">Friends:</p>
        <div className="flex items-center gap-1">
          <p className="text-base font-normal text-gray-300">{refData.length}</p>
          <p className="text-base font-normal text-white">
            {!!Number(referralsLastDay) && ` +${toFixedSafe(Number(referralsLastDay), 2)}`}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {refData.map((item, index) => (
          <FriendsListItem key={index} refData={item} />
        ))}
      </div>
    </div>
  )
}
