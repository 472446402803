'use client'

import { TonBlueIcon } from '@/assets/icons'
import { FC } from 'react'
import { TonEntryPrice } from '@/types/game'
import { ENTRY_PRICE_VARIANTS } from '@/constants/race'
import { RaceType } from '@/types/race'
import { normalizeTonEntryPrice } from '@/utils/race'
import { SecondaryTrophyIllustration } from '@/assets/illustrations'

interface Props {
  entryPrice: TonEntryPrice
}

export const TonPodium2ndPosition: FC<Props> = ({ entryPrice }) => {
  const prize = !!entryPrice
    ? ENTRY_PRICE_VARIANTS[RaceType.OneVsThree][normalizeTonEntryPrice(entryPrice)].second
    : 0

  return (
    <div className="flex flex-col items-center justify-center text-center -mt-9">
      <div className="relative">
        <SecondaryTrophyIllustration className="w-[329px] relative z-[2]" />
      </div>
      <p className="w-full text-center text-xs font-medium text-gray-300">
        The prize has been sent to your wallet
      </p>
      <div className="flex gap-1 items-center my-2">
        <TonBlueIcon className="w-[33px] h-[33px] min-w-[33px]" />
        <p className="text-blue-600 text-2xl font-semibold">{prize}</p>
      </div>
      <p className="w-full text-center text-lg font-bold text-white">Great job!</p>
    </div>
  )
}
