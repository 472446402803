import { Tokens } from '@/constants/tokens'
import { PairType } from '@/types/player'
import {
  BnbTokenIcon,
  BtcTokenIcon,
  DogeTokenIcon,
  EthTokenIcon,
  SolTokenIcon,
  TonTokenIcon,
} from '@/assets/tokens'
import { RacePreparationNitro } from '@/types/race'

export const nitroOptions: Array<RacePreparationNitro> = [
  {
    from: Tokens.BTC,
    fromIcon: BtcTokenIcon,
    to: Tokens.USDT,
    pair: PairType.BTC_USDT,
  },
  {
    from: Tokens.ETH,
    fromIcon: EthTokenIcon,
    to: Tokens.USDT,
    pair: PairType.ETH_USDT,
  },
  // {
  //   from: Tokens.XRP,
  //   fromIcon: XrpTokenIcon,
  //   to: Tokens.USDT,
  //   pair: PairType.XRP_USDT,
  // },
  // {
  //   from: Tokens.XRP,
  //   fromIcon: XrpTokenIcon,
  //   to: Tokens.USDT,
  //   pair: PairType.XRP_USDT,
  // },
  // {
  //   from: Tokens.SHIB,
  //   fromIcon: ShibTokenIcon,
  //   to: Tokens.USDT,
  //   pair: PairType.SHIB_USDT,
  // },
  // {
  //   from: Tokens.PEPE,
  //   fromIcon: PepeTokenIcon,
  //   to: Tokens.USDT,
  //   pair: PairType.PEPE_USDT,
  // },
  {
    from: Tokens.DOGE,
    fromIcon: DogeTokenIcon,
    to: Tokens.USDT,
    pair: PairType.DOGE_USDT,
  },
  {
    from: Tokens.BNB,
    fromIcon: BnbTokenIcon,
    to: Tokens.USDT,
    pair: PairType.BNB_USDT,
  },
  {
    from: Tokens.SOL,
    fromIcon: SolTokenIcon,
    to: Tokens.USDT,
    pair: PairType.SOL_USDT,
  },
  {
    from: Tokens.TON,
    fromIcon: TonTokenIcon,
    to: Tokens.USDT,
    pair: PairType.TON_USDT,
  },
]
