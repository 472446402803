'use client'

import { FC } from 'react'
import { RaceType, TonRaceType } from '@/types/race'
import { TonEntryPrice } from '@/types/game'
import { TonRaceEntryListItem } from './TonRaceEntryListItem'
import { ENTRY_PRICE_VARIANTS, ENTRY_REWARD_VARIANTS } from '@/constants/race'

interface Props {
  raceType: TonRaceType
  onNextBtnClick: (entry: TonEntryPrice) => void
}

export const TonRaceEntryListComponent: FC<Props> = ({
  raceType = RaceType.OneVsOne,
  onNextBtnClick,
}) => {
  const entryPriceVariant = ENTRY_PRICE_VARIANTS[raceType]
  const entryRewardVariant = ENTRY_REWARD_VARIANTS[raceType]

  return (
    <div className="flex flex-col items-center p-4 text-center">
      <p className="text-lg font-medium text-white text-center mb-2">{raceType}</p>
      <p className="text-sm font-normal text-gray-300 text-center mb-6">
        Choose your entry for PvP
      </p>
      <p className="text-base font-normal text-gray-300 text-left mb-2 max-w-[361px] w-full">
        <span className="font-semibold">Economy:</span> reward +{entryRewardVariant['economy']}%
      </p>
      <TonRaceEntryListItem
        entry={entryPriceVariant['0.6'].entry}
        win={entryPriceVariant['0.6'].win}
        second={entryPriceVariant['0.6'].second}
        onCouponClick={onNextBtnClick}
        className={'mb-6'}
      />
      <p className="text-base font-normal text-gray-300 text-left mb-2 max-w-[361px] w-full">
        <span className="font-semibold">Standard:</span> reward +{entryRewardVariant['standard']}%
      </p>
      <TonRaceEntryListItem
        entry={entryPriceVariant['1'].entry}
        win={entryPriceVariant['1'].win}
        second={entryPriceVariant['1'].second}
        onCouponClick={onNextBtnClick}
        className={'mb-2'}
      />
      <TonRaceEntryListItem
        entry={entryPriceVariant['5'].entry}
        win={entryPriceVariant['5'].win}
        second={entryPriceVariant['5'].second}
        onCouponClick={onNextBtnClick}
        className={'mb-6'}
      />
      <p className="text-base font-normal text-gray-300 text-left mb-2 max-w-[361px] w-full">
        <span className="font-semibold">Premium:</span> reward +{entryRewardVariant['premium']}%
      </p>
      <TonRaceEntryListItem
        entry={entryPriceVariant['25'].entry}
        win={entryPriceVariant['25'].win}
        second={entryPriceVariant['25'].second}
        onCouponClick={onNextBtnClick}
        className={'mb-2'}
      />
      <TonRaceEntryListItem
        entry={entryPriceVariant['50'].entry}
        win={entryPriceVariant['50'].win}
        second={entryPriceVariant['50'].second}
        onCouponClick={onNextBtnClick}
        className={'mb-2'}
      />
    </div>
  )
}
