'use client'

import { FC, useState } from 'react'
import { TonBlueIcon, TonOutlineIcon } from '@/assets/icons'
import { CardLayout } from '@/app/rewards/components/CardLayout'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { Transition } from '@/components/Transitions'
import { ReferralRewardModal } from './ReferralRewardModal'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { selectUser } from '@/redux/features/user'
import { useMutation_ClaimTonRefRewards_User } from '@/services/useApi/user/useMutation'
import { setQueryOptionsUserTriggerReFetch } from '@/redux/features/queryOptionsSlice'
import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'

interface Props {}

export const ReferralRewardCard: FC<Props> = () => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { webApp } = useTelegram()

  const wallet = useTonWallet()

  const { tonRefRewardToClaim, tonRefRewardTotal } = useAppSelector(selectUser)

  const [tonConnectUI] = useTonConnectUI()

  const { mutateAsync, isPending } = useMutation_ClaimTonRefRewards_User(() => {
    setIsOpen(false)
    dispatch(setQueryOptionsUserTriggerReFetch())
  })

  const handleClickBtn = () => {
    webApp?.HapticFeedback.impactOccurred('medium')
    setIsOpen(true)
  }

  const handleClaimBtnClick = async () => {
    webApp?.HapticFeedback.impactOccurred('medium')
    await mutateAsync({ addressTo: wallet?.account.address || '' })
  }

  const handleConnect = async () => {
    await tonConnectUI.openModal()
  }

  const isDisabled = tonRefRewardToClaim - 0.01 < 1

  return (
    <>
      <CardLayout
        isDone={isDisabled}
        onClick={handleClickBtn}
        tokens={tonRefRewardToClaim}
        isTonTheme
      >
        <Transition animationType="scaleFromZero">
          <TonOutlineIcon className="w-8 h-8 min-w-8" />
        </Transition>
        <div className="flex flex-col">
          <p className="text-sm font-normal">Referral reward</p>
          <div className="flex gap-0.5 items-center">
            <p className="text-xs font-light text-gray-300 pr-0.5">Total reward:</p>
            <TonBlueIcon className="w-2.5 h-2.5 min-w-2.5" />
            <p className="text-xs font-normal text-blue-600">{tonRefRewardTotal}</p>
          </div>
        </div>
      </CardLayout>
      <ReferralRewardModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isDisabled={isPending || isDisabled}
        onClaimBtnClick={handleClaimBtnClick}
        tonRefRewardToClaim={tonRefRewardToClaim}
        isConnected={!!wallet?.account}
        onConnectBtnClick={handleConnect}
      />
    </>
  )
}
