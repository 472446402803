function parseTimeString(timeString: string): number {
  const regex = /(\d+)m(\d+\.\d+)s/
  const matches = timeString.match(regex)

  if (!matches) {
    throw new Error('Invalid time string')
  }

  const minutes = parseInt(matches[1], 10)
  const seconds = parseFloat(matches[2])

  return minutes * 60 * 1000 + seconds * 1000
}

export function calculateTimeDifferenceInSecondsForRace(time1: string, time2: string): number {
  const time1Milliseconds = parseTimeString(time1)
  const time2Milliseconds = parseTimeString(time2)

  return Math.abs(time1Milliseconds - time2Milliseconds) / 1000
}

export const adjustServerTime = (serverTime: string, timeDifference: number): string => {
  const serverTimeMillis = new Date(serverTime).getTime()

  const adjustedTimeMillis = serverTimeMillis + timeDifference

  return new Date(adjustedTimeMillis).toISOString()
}

export const getDaysSinceEpoch = () => Math.floor(Date.now() / (60 * 60 * 24 * 1000))
