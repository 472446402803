'use client'

import { Coin2ndIcon, CoinIcon } from '@/assets/icons'
import { FC } from 'react'
import Image from 'next/image'

interface Props {}

export const Podium2ndPosition: FC<Props> = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-1 text-center">
      <div className="flex gap-1 items-center mb-4">
        <CoinIcon className="w-[36px] h-[36px] min-w-[36px] text-yellow-500" />
        <p className="text-yellow-500 text-2xl font-semibold">50</p>
      </div>
      <div className="relative">
        <Coin2ndIcon className="w-[92px] relative z-[2]" />
        <Image
          src="/illustrations/coin-shadow.webp"
          priority
          alt="coin-shadow"
          width="155"
          height="28"
          className="absolute -bottom-1.5 min-w-[155px] w-[155px] min-h-[28px] right-0"
        />
      </div>
    </div>
  )
}
