'use client'

import { FC } from 'react'
import { DangerIcon } from '@/assets/icons'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'

interface Props {
  onClose: () => void
}

export const TonRaceErrorStatus: FC<Props> = ({ onClose }) => {
  return (
    <div className="w-full layer-default layer-gradient-blue z-[201] absolute bottom-0 left-0 flex flex-col items-center justify-between gap-1 text-white bg-dark-bg-radial-gradient pb-4 px-4">
      <div />
      <div className="flex flex-col w-full justify-center items-center gap-4">
        <p className="text-lg font-bold">Unable to detect your transaction</p>
        <DangerIcon className="w-11 h-11" />
        <p className="text-lg font-normal">Try again</p>
      </div>
      <CustomButton onClick={onClose} variant="secondaryBordered">
        okay =(
      </CustomButton>
    </div>
  )
}
