import { useAppSelector } from '@/redux/hooks'
import { selectUser, selectUserLevelData } from '@/redux/features/user'
import { useMutation_UpdateUserBoost_User } from '@/services/useApi/user/useMutation'
import { useState } from 'react'
import { useTelegram } from '@/app/providers/TelegramProvider'

export const useBoostersCards = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const { webApp } = useTelegram()

  const user = useAppSelector(selectUser)
  const userLevelData = useAppSelector(selectUserLevelData)

  const { mutateAsync, isPending } = useMutation_UpdateUserBoost_User(() => {
    setIsOpenModal(false)
  })

  const handleOpenModal = () => {
    setIsOpenModal(true)
    webApp?.HapticFeedback?.impactOccurred('medium')
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  return {
    user,
    userLevelData,
    mutateAsync,
    isPending,
    isOpenModal,
    handleOpenModal,
    handleCloseModal,
  }
}
