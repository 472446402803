'use client'

import { FC, FunctionComponent, SVGProps } from 'react'
import { ModalCustom } from '@/components/ui/ModalCustom'
import { CustomButton } from '@/components/ui/Buttons/CustomButton'
import { CoinIcon } from '@/assets/icons'
import cx from 'classnames'

interface Props {
  isOpen: number | boolean
  onClose: (value: number | boolean) => void
  title: string
  subtitle: string
  level: number
  price: number
  onRequestUpgrade: () => void
  isLoading: boolean
  isNotEnoughTokens: boolean
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const BoosterUpgradeLayoutModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  level,
  price,
  onRequestUpgrade,
  isLoading,
  Icon,
  isNotEnoughTokens,
}) => {
  return (
    <ModalCustom
      isOpen={!!isOpen}
      onClose={() => {
        if (isLoading) return

        onClose(false)
      }}
      classNameContent="flex flex-col justify-between items-center gap-2 "
    >
      <div className="text-white flex flex-col justify-between items-center">
        <p className="text-lg font-normal mb-1">
          {title} {level} level
        </p>
        <p className="text-xs font-normal text-gray-300 mb-4">{subtitle}</p>
      </div>
      <div className="flex justify-center items-center flex-col gap-4">
        <div
          className={cx(
            'w-[100px] h-[100px] rounded-full flex items-center justify-center bg-yellow-radial-gradient text-white',
            {
              'bg-blue-900 bg-img-inherit !text-purple-700': isNotEnoughTokens,
            }
          )}
        >
          <Icon className="w-[64px] h-[64px]" />
        </div>
        <div className="flex items-center text-yellow-500 gap-1">
          <p className="text-2xl text-[22px] font-bold">{price}</p>
          <CoinIcon className="w-[30px] h-[30px]" />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        {isNotEnoughTokens && (
          <p className="mb-2 text-xs font-normal text-red-500">
            Not enough NICKs to upgrade to next level
          </p>
        )}
        <div className="w-full relative">
          <CustomButton
            isLoading={isLoading}
            onClick={onRequestUpgrade}
            isDisabledDark={isNotEnoughTokens}
            className={`w-full`}
          >
            upgrade
          </CustomButton>
        </div>
      </div>
    </ModalCustom>
  )
}
